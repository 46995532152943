import React from "react" 
import styled from "styled-components" 

const Wrapper = styled.div`
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode
  loading: boolean 
}

export const Loading: React.FC<IProps> = ({ children, ref, loading, ...props }: IProps) => {
  return (
    <Wrapper {...props}>
      {children}
      {loading && (
        <Overlay>
          <p>Loading...</p>
        </Overlay>
      )}
    </Wrapper>
  ) 
} 
