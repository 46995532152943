// libraries
import parse from "html-react-parser";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// components & functions
import { apiClient } from "../../core/api_client";
import Breadcrumb from "../../components/Breadcrumb";

// assets
import captcha from "../../../assets/img/utils/captcha.svg";

const Contact1 = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const { t } = useTranslation();

  const breadcrumb = [
    {
      id: 1,
      link: "/elaqe/elaqe-vasiteleri",
      title: t("contact:contact"),
      isActive: true,
    },
    {
      id: 2,
      link: "/elaqe/metbuat-xidmeti",
      title: t("contact:press"),
      isActive: false,
    },
    {
      id: 3,
      link: "/elaqe/en-cox-verilen-suallar",
      title: t("contact:faq"),
      isActive: false,
    },
  ];

  const [isSending, setIsSending] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSending) return;
    setIsSending(true);
    const data = {
      name: name,
      surname: surname,
      patronymicName: patronymic,
      address: address,
      phone: phone,
      email: email,
      text: message,
    };
    await apiClient.post(`/sendEmail`, data);

    setIsSending(false);
    setName("");
    setSurname("");
    setPatronymic("");
    setAddress("");
    setPhone("");
    setEmail("");
    setMessage("");
  };

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="contact-page block-bg1 pt-lg-3 pb-3">
      <Breadcrumb data={breadcrumb} />

      <div className="container mt-4 mt-sm-5">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="my-3">
              <p className="mb-1">{t("contact:address_h")}</p>
              <p className="t-color5">{parse(t("contact:address"))}</p>
            </div>
            <div className="my-5">
              <p className="mb-1">“MİDA” Məhdud Məsuliyyətli Cəmiyyəti</p>
              <p style={{ color: "#5B687C" }}>{parse(t("contact:address"))}</p>
            </div>
          </div>
          <div className="col-sm-6 col-12">
            {isLoading && (
              <div
                className=" d-flex justify-content-center align-items-center"
                style={{ height: "20rem" }}
              >
                <div className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            {!isLoading && (
              <iframe
                className="w-100 rounded-3"
                title="Mənzil İnşaatı Dövlət Agentliyi"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d936.3338288353203!2d49.84368458935926!3d40.3828732716931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d7e055043af%3A0x2b264c5c5e80b41b!2zTcmZbnppbCDEsG7Fn2FhdMSxIETDtnZsyZl0IEFnZW50bGl5aQ!5e0!3m2!1sen!2saz!4v1688734424793!5m2!1sen!2saz"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}
          </div>
        </div>

        <h1 className="text text-center section-heading my-4">
          {t("contact:el_message")}
        </h1>

        <p className="text-justify t-color5" style={{ lineHeight: "29px" }}>
          {parse(t("contact:el_message_text"))}
        </p>

        <form className="my-4" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  {t("contact:name")}*
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  id="name"
                  placeholder={t("contact:name")}
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="surname" className="form-label">
                  {t("contact:surname")}*
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  id="surname"
                  placeholder={t("contact:surname")}
                  required
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="patronymic" className="form-label">
                  {t("contact:patronymic")}*
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  id="patronymic"
                  placeholder={t("contact:patronymic")}
                  required
                  value={patronymic}
                  onChange={(e) => setPatronymic(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  {t("contact:adres")}*
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  id="address"
                  placeholder={t("contact:adres")}
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  {t("contact:phone")}*
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  id="phone"
                  placeholder={t("contact:phone")}
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  {t("contact:email")}
                </label>
                <input
                  type="email"
                  className="form-control p-2"
                  id="email"
                  placeholder={t("contact:email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  {t("contact:letter_text")}*
                </label>
                <textarea
                  className="form-control p-2"
                  id="message"
                  rows={5}
                  maxlength="1500"
                  placeholder={t("contact:letter_text")}
                  style={{ resize: "none" }}
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-3 col-md-6 ms-auto">
            <div className="form-check mx-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                {t("contact:not_robot")}
              </label>
            </div>
            <img src={captcha} alt="captcha-logo" className="mx-4" />
            <button
              id="send-btn"
              type="submit"
              className="btn btn-custom py-2"
              style={{ cursor: isSending ? "not-allowed" : "pointer" }}
            >
              {t("contact:send")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact1;
