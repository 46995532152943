const Table10 = ({ apiData }) => {

    function search(nameKey, myArray) {
        for (let i = 0; i < myArray?.length; i++) {
            if (myArray[i].roomsCount === nameKey) {
                return myArray[i].value
            }
        }
    }

    return (
        <>
            {apiData && (
                <table id="table10" className='table table-bordered table-responsive mb-0'>
                    <thead>
                        <tr>
                            <th colSpan={6} className="table-category-header">Kateqoriya</th>
                            <th>1 otaqlı</th>
                            <th>2 otaqlı</th>
                            <th>3 otaqlı</th>
                            <th>4 otaqlı</th>
                            <th>Cəmi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiData?.rows?.map(row =>
                            <tr key={apiData.rows.indexOf(row)}>
                                <td colSpan={6} className={`text-${row.category === "TOTAL" ? "center" : "start"}`}>{row.category === "TOTAL" ? "CƏMİ" : row.category}</td>
                                <td>{search(1, row.rooms)}</td>
                                <td>{search(2, row.rooms)}</td>
                                <td>{search(3, row.rooms)}</td>
                                <td>{search(4, row.rooms)}</td>
                                <td colSpan={2}>{row.total}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default Table10

