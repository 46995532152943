// libraries
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// components & functions
import { apiClient } from "../../../core/api_client"
import { Routing } from '../../../core/routing'
import isNew from '../../../components/utils/isNew'
import l from '../../../core/localize'

// assets
import arrow_right from "../../../../assets/img/utils/arrow_right.svg"
import date from '../../../../assets/img/utils/date.svg'
import new_label_az from '../../../../assets/img/new_label_az.svg'

const NewsHome = () => {
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    const [news, setNews] = useState([])
    useEffect(() => {
        apiClient.get('/news/newsType/1')
            .then(function (response) {
                setNews(response.data)
            })
    }, [])

    return (
        <div id="news-home" className="mt-md-3 mb-3 row">
            {news.length === 0 && <h4 className="col-12 text-center py-4">{t('common:no_content')}</h4>}
            {news.slice(0, 4).map(item =>
                <Link to={"/xeberler-ve-media/bizim-xeberler/" + item.id} className='col-lg-3 col-md-6 col-11 my-4 mx-auto' style={{ padding: '0 1.5rem' }} key={item.id}>
                    <div className="news-item hover-img">
                        <div className='position-relative'>
                            <img src={Routing.generateFileRoute(l(item, "file", currentLang))} style={{ maxHeight: "205px" }} className="w-100" alt="..." />
                            <small className="news-date position-absolute bottom-0"><img src={date} alt="date-icon" className='me-2' />{dayjs(item.date).format('DD.MM.YYYY')}</small>
                            {isNew(item.is_new) && <img className='position-absolute' style={{ top: '7px', right: '-33px' }} src={new_label_az} alt={t('common:new')} />}
                        </div>
                        <div className="card-body py-2 text-start">
                            <p className="news-title">{l(item, "name", currentLang)}</p>
                        </div>
                    </div>
                </Link>
            )}
            <div className="btn-container d-flex justify-content-end mt-3">
                <Link to="/xeberler-ve-media/bizim-xeberler" className="more-info-btn">
                    <small>{t('common:all_news')}</small>
                    <img className="ms-2" src={arrow_right} alt="" />
                </Link>
            </div>
        </div>
    )
}

export default NewsHome 
