// libraries
import { Pagination, Navigation } from "swiper/modules"
import parse from 'html-react-parser'
import { Swiper, SwiperSlide } from "swiper/react"
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// functions and components
import { apiClient } from '../../core/api_client'
import Breadcrumb from '../../components/Breadcrumb'
import Tenders from './Tenders'

// assets
import arrow1 from '../../../assets/img/utils/lil-arrow-l.svg'
import arrow2 from '../../../assets/img/utils/lil-arrow-r.svg'
import captcha from '../../../assets/img/utils/captcha.svg'
import img from '../../../assets/img/hesabat_card_img.svg'
// import img from '../../../assets/img/utils/maliyye-icon.svg'
import logo_mmc from '../../../assets/img/logo/logo-mmc.svg'
// import tender from '../../../assets/img/tender.png' 
import pdf from '../../../assets/img/utils/pdf.svg'

import nizamname from '../../../assets/files/MMC_Nizamname.pdf'
import pdf1 from '../../../assets/files/mmc/2021.pdf'
import pdf2 from '../../../assets/files/mmc/2020.pdf'
import pdf3 from '../../../assets/files/mmc/2019.pdf'
import pdf4 from '../../../assets/files/mmc/2018.pdf'
import pdf5 from '../../../assets/files/mmc/2017.pdf'
import pdf6 from '../../../assets/files/mmc/2016.pdf'
import pdf7 from '../../../assets/files/mmc/2022.pdf'
import pdf8 from '../../../assets/files/mmc/2023.pdf'

// styles
import "swiper/css"
import "swiper/css/grid"
import "swiper/css/pagination"
import "swiper/css/navigation"

const MidaMMC = () => {
    // const [activeTab, setActiveTab] = useState(0) 
    const { t } = useTranslation()
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [patronymic, setPatronymic] = useState("")
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const handleSubmit = async e => {
        e.preventDefault()
        const data = {
            name: name,
            surname: surname,
            patronymicName: patronymic,
            address: address,
            phone: phone,
            email: email,
            text: message,
        }
        await apiClient.post(`/sendEmail`, data)

        setName("")
        setSurname("")
        setPatronymic("")
        setAddress("")
        setPhone("")
        setEmail("")
        setMessage("")
    }

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:about'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/nizamname',
            title: t('agentlik:order'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: false
        },
        {
            id: 4,
            link: '/agentlik/mida-mmc',
            title: '"MİDA" MMC',
            isActive: true
        },
        {
            id: 5,
            link: '/agentlik/beynelxalq-emekdasliq',
            title: t('agentlik:international'),
            isActive: false
        },
        {
            id: 6,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        }
    ]

    const audit_card = [
        {
            id: 8,
            title: "2023",
            file: pdf8,
            text: "2023-ci il üzrə “MİDA” MMC illik maliyyə hesabatlarının audit rəyi",
        },
        {
            id: 7,
            title: "2022",
            file: pdf7,
            text: "2022-ci il üzrə “MİDA” MMC illik maliyyə hesabatlarının audit rəyi",
        },
        {
            id: 6,
            title: "2021",
            file: pdf1,
            text: "2021-ci il üzrə “MİDA” MMC illik maliyyə hesabatlarının audit rəyi",
        },
        {
            id: 5,
            title: "2020",
            file: pdf2,
            text: "2020-ci il üzrə “MİDA” MMC illik maliyyə hesabatlarının audit rəyi",
        },
        {
            id: 4,
            title: "2019",
            file: pdf3,
            text: "2019-ci il üzrə “MİDA” MMC illik maliyyə hesabatlarının audit rəyi",
        },
        {
            id: 3,
            title: "2018",
            file: pdf4,
            text: "2018-ci il üzrə “MİDA” MMC illik maliyyə hesabatlarının audit rəyi",
        },
        {
            id: 2,
            title: "2017",
            file: pdf5,
            text: "2017-ci il üzrə “MİDA” MMC illik maliyyə hesabatlarının audit rəyi",
        },
        {
            id: 1,
            title: "2016",
            file: pdf6,
            text: "2016-ci il üzrə “MİDA” MMC illik maliyyə hesabatlarının audit rəyi",
        }
    ]

    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <div className="mida-mmc block-bg1 pt-lg-3 py-0">
            <Breadcrumb data={breadcrumb} />

            <div className='col-12 d-flex flex-wrap mt-4 mt-sm-5 mt-md-5'>
                <div className='col-md-7 col-12 d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-between align-items-center py-4 px-md-4 px-2' style={{ background: "#8CA2C2" }}>
                    <div className='d-flex align-items-center justify-content-center col-md-4 '>
                        <img src={logo_mmc} alt="MİDA MMC" className='w-80' />
                    </div>
                    <div className='col-md-8 px-2'>
                        <p className='t-color4 pt-4 text-justify'>
                            {parse(t('agentlik:mida_mmc_text'))}
                            <br /><br />
                            {parse(t('agentlik:mmc_text1'))}
                        </p>
                        <a href={nizamname} rel="noreferrer" target="_blank" className='t-color3 mt-1 pt-1 mb-0 d-flex justify-content-end align-items-center mmc_nizamname_link' style={{ fontSize: "18px" }}> {t('agentlik:mmc_text2')} <img src={pdf} className='ms-md-2' alt='PDF' /></a>
                    </div>
                </div>
                <div className='col-md-5 col-12' style={{ background: "#E3ECF8" }}>
                    <h5 className='d-flex justify-content-center text-center t-color3 fw-bolder my-md-2 my-4'>{t('contact:contact')}</h5>
                    <div className='col-12 d-flex flex-wrap px-3 mt-md-4'>
                        <div className='col-sm-6 col-12'>
                            {isLoading && <div className=' d-flex justify-content-center align-items-center' style={{ height: '10rem' }}><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
                            {!isLoading &&
                                <iframe style={{ border: "1px solid rgba(206, 207, 208 ,0.5)" }}
                                    className="w-100 rounded-3"
                                    title="Mənzil İnşaatı Dövlət Agentliyi"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7228.2919779757!2d49.84057336324306!3d40.38397961611488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d0ab9000001%3A0xee6bb06cd934407b!2s31%20Suleyman%20Rustam%20Rd%2C%20Baku%2C%20Azerbaijan!5e0!3m2!1sen!2sfr!4v1683643366766!5m2!1sen!2sfr"
                                    loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            }
                        </div>
                        <div className='col-sm-6 col-12 px-4'>
                            <small className='t-color2'>
                                {parse(t('agentlik:mmc_address'))}
                            </small>
                        </div>
                    </div>
                    <form className="mt-2 mb-4" onSubmit={handleSubmit}>
                        <div className="row w-100 m-0">
                            <div className="col-md-5 col-12 px-4">
                                <div className="mb-1">
                                    <label htmlFor="name" className="form-label">{t('contact:name')}*</label>
                                    <input type="text" className="form-control p-0" id="name" required value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="surname" className="form-label">{t('contact:surname')}*</label>
                                    <input type="text" className="form-control p-0" id="surname" required value={surname} onChange={(e) => setSurname(e.target.value)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="patronymic" className="form-label">{t('contact:patronymic')}*</label>
                                    <input type="text" className="form-control p-0" id="patronymic" required value={patronymic} onChange={(e) => setPatronymic(e.target.value)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="phone" className="form-label">{t('contact:phone')}*</label>
                                    <input type="text" className="form-control p-0" id="phone" required value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="email" className="form-label">{t('contact:email')}</label>
                                    <input type="email" className="form-control p-0" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-7 col-12 px-md-3 px-4">
                                <div className="mb-1">
                                    <label htmlFor="address" className="form-label">{t('contact:adres')}*</label>
                                    <input type="text" className="form-control p-0" id="address" required value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="message" className="form-label">{t('contact:letter_text')}*</label>
                                    <textarea className="form-control p-0" id="message" rows={5} style={{ "resize": "none" }} required value={message} onChange={(e) => setMessage(e.target.value)} />
                                </div>

                                <div className="d-flex align-items-end mt-3">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
                                        <label className="form-check-label w-100 t-color1 my-1" htmlFor="exampleCheck1" style={{ fontSize: "12px" }}>{t('contact:not_robot')}</label>
                                    </div>
                                    <img src={captcha} alt="captcha-logo" className='mx-1' width={40} height={40} />
                                    <button id="send-btn" type="submit" className="btn btn-custom px-4 py-0" style={{ marginTop: '10px', fontSize: "12px" }}>{t('contact:send')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Tenders paginate />
            <div className='bg-picture2 position-relative'>
                <div className='container'>
                    <h1 className="text text-center pt-4 t-color5">{t('agentlik:auditor_comments')}</h1>
                    <div className='row justify-content-between d-none d-md-flex pt-4 pb-120 px-5'>
                        <div className={"swiper-button-prev-n d-md-flex d-none ms-md-5 " + (audit_card.length > 5 ? "" : "d-none d-md-none")} style={{
                            width: "70px",
                            height: "100px"
                        }}>
                            <img src={arrow1} alt="" />
                        </div>
                        <Swiper
                            slidesPerView={5}
                            spaceBetween={20}
                            loop={false}
                            navigation={{
                                nextEl: '.swiper-button-next-n',
                                prevEl: '.swiper-button-prev-n',
                            }}
                            modules={[Pagination, Navigation]}
                            id="news-page"
                            className="mySwiper mt-3 mb-3 row gx-2 pb-3 d-none d-md-block">

                            {audit_card.map(item =>
                                <SwiperSlide key={item.id + Math.random()} className='audit-card rounded-4 my-4 cursor px-2'>
                                    <a href={item.file} rel="noreferrer" target="_blank" key={item.id} style={{ width: "24%" }}>
                                        <div className='audit-card_side audit-card_side_back px-2 py-5'>
                                            <div >
                                                <p className='fw-bold t-color5'>{item.text}</p>
                                                <div className='line mt-2'></div>
                                                {/* <button className='mt-4 px-4 py-1'>{t('common:download')}</button> */}
                                            </div>
                                            <img src={img} className="mt-3" alt="pdf" />
                                        </div>
                                        <div className='audit-card_side audit-card_side_front pb-120 pt-120 px-60'>
                                            <h2 className='my-auto'>{item.title}</h2>
                                        </div>
                                    </a>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className={"swiper-button-next-n d-md-flex d-none me-md-5 " + (audit_card.length > 5 ? "" : "d-md-none d-none")} style={{
                            width: "70px",
                            height: "100px",
                            right: 0
                        }}>
                            <img src={arrow2} alt="" />
                        </div>
                    </div>


                    {/* MOBILE */}
                    <div className='row justify-content-between px-5 d-md-none position-relative'>
                        <div className={"swiper-button-prev-n d-flex d-md-none ms-1 " + (audit_card.length > 2 ? "" : "d-none")} style={{
                            width: "70px",
                            height: "50px"
                        }}>
                            <img src={arrow1} alt="" />
                        </div>
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={10}
                            loop={false}
                            navigation={{
                                nextEl: '.swiper-button-next-n',
                                prevEl: '.swiper-button-prev-n',
                            }}
                            modules={[Pagination, Navigation]}
                            id="news-page"
                            className="mySwiper mt-3 mb-3 row gx-2 pb-3 d-block d-md-none">

                            {audit_card.map(item =>
                                <SwiperSlide key={item.id + Math.random()} className='audit-card rounded-4 my-4 cursor px-2' style={{ width: '48%' }}>
                                    <a href={item.file} rel="noreferrer" target="_blank" key={item.id} style={{ width: "22%" }}>
                                        <div className='audit-card_side audit-card_side_back px-2 py-5'>
                                            <div>
                                                <p className='fw-bold t-color5'>{item.text}</p>
                                                <div className='line mt-2'></div>
                                                {/* <button className='mt-4 px-4 py-1'>{t('common:download')}</button> */}
                                            </div>
                                            <img src={img} className="mt-3" alt="pdf" />
                                        </div>
                                        <div className='audit-card_side audit-card_side_front px-5'>
                                            <h2 className='my-auto'>{item.title}</h2>
                                        </div>
                                    </a>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className={"swiper-button-next-n d-flex d-md-none ps-4 pe-5 " + (audit_card.length > 2 ? "" : "d-none")} style={{
                            width: "70px",
                            height: "50px",
                            right: 0
                        }}>
                            <img src={arrow2} alt="" />
                        </div>
                    </div>
                    {/* MOBILE */}
                </div>
            </div>
        </div>
    )
}

export default MidaMMC 
