import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import arrows from '../../../../assets/img/guzestli menzil/arrows.svg'
import soc from '../../../../assets/img/guzestli menzil/soc.svg'
import pdf1 from '../../../../assets/files/Sistemin_esasnamesi.pdf'
import gm from "../../../../assets/img/gm.svg"
import pdf from '../../../../assets/img/guzestli menzil/pdf.svg'
import file from '../../../../assets/files/beynelxalq reyler/MIDA_SOC3_full report (with System description).pdf'

const GmsSharedBanner = () => {
    const { t } = useTranslation()
    // const currentLang = i18n.language.slice(0, 2)

    return (
        <>
            {/* DESKTOP VERSION */}
            <div className='gms-banner d-xxl-block d-none'>
                <div className='col-md-7 d-flex justify-content-end ms-md-auto p-5'>
                    <div className='pt-3 col-md-7 me-4'>
                        <p className='t-color5 text-justify px-4'>
                            {parse(t('gms:gms_main_text'))}
                        </p>
                        <a href={pdf1} rel="noreferrer" target="_blank" className='d-flex justify-content-end align-items-center px-4 mt-5 mb-2 hover-text-underline'><p className='mb-0 me-2 t-color3 fw-700'>Elektron sistemin Əsasnaməsi</p> <img src={pdf} alt='' /></a>
                        {/* <Link to="/elektron-xidmetler/beynelxalq-reyler" className='d-flex justify-content-end align-items-center px-4 my-2 hover-text-underline'><img src={soc} className="me-2" /> <p className='mb-0 me-2 t-color3 fw-700'>Beynəlxalq rəylər</p> <img src={arrows} /></Link> */}
                        <a href={file} className='d-flex justify-content-end align-items-center px-4 my-2 hover-text-underline'><img src={soc} className="me-2" alt='' /> <p className='mb-0 me-2 t-color3 fw-700'>Beynəlxalq rəylər</p> <img src={arrows} alt='' /></a>
                    </div>
                    <a href='https://e-gov.az/az/services/info/3709/' target='_blank' rel='noreferrer' className='col-md-4 p-2'><img src={gm} alt="Güzəştli mənzil sistemi" className='w-100' style={{ filter: "drop-shadow(0px 6px 10px #1A4660)" }} /></a>
                </div>
            </div>
            {/* DESKTOP VERSION */}


            {/* MOBILE VERSION */}
            <div className='d-xxl-none d-block mt-4 mt-sm-5'>
                <div className='gms-banner'>
                    <div className='d-flex col-12 justify-content-center align-items-center p-5'>
                        <img src={gm} alt="Güzəştli mənzil sistemi" className='col-10 col-md-5 p-2' />
                    </div>
                </div>
                <p className='t-color5 text-justify p-4'>
                    {parse(t('gms:gms_main_text'))}
                </p>
                <a href={pdf1} rel="noreferrer" target="_blank" className='d-flex align-items-center px-4 mt-3 mb-2 hover-text-underline'><p className='mb-0 me-2 t-color3 fw-700'>Elektron sistemin Əsasnaməsi</p> <img src={pdf} alt='' /></a>
                {/* <Link to="/elektron-xidmetler/beynelxalq-reyler" className='d-flex justify-content-end align-items-center px-4 my-2 hover-text-underline'><img src={soc} className="me-2" /> <p className='mb-0 me-2 t-color3 fw-700'>Beynəlxalq rəylər</p> <img src={arrows} /></Link> */}
                <a href={file} className='d-flex align-items-center px-4 my-3 hover-text-underline'><img src={soc} className="me-2" alt='' /> <p className='mb-0 me-2 t-color3 fw-700'>Beynəlxalq rəylər</p> <img src={arrows} alt='' /></a>
            </div>
            {/* MOBILE VERSION */}
        </>
    )
}

export default GmsSharedBanner 
