import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

export function BarChartHor1({ apiData, legend, color }) {
    const [screenSize, setScreenSize] = useState(window.clientWidth);
    const [labels, setLabels] = useState()
    const [percentage, setPercentage] = useState()
    const fontSize = screenSize < 400 ? 4 : screenSize < 490 ? 6 : screenSize < 784 ? 7 : screenSize < 992 ? 10 : screenSize < 1201 ? 11 : 15;


    function SetWindowSize() {
        setScreenSize(document.body.clientWidth);
    }

    function splitLabelForChart(label) {
        const words = label.split(' ');
        const lineLimit = screenSize < 400 ? 45 : screenSize < 785 ? 55 : screenSize < 1200 ? 60 : 60;
        const lines = [];

        let line = '';
        let currentWordIdx = 0;

        while (currentWordIdx < words.length) {
            if (line.length + words[currentWordIdx].length < lineLimit) {
                line += `${words[currentWordIdx]} `;
                currentWordIdx++;

                if (currentWordIdx === words.length) {
                    lines.push(line);
                }
            } else {
                if (line.length) {
                    lines.push(line);
                    line = '';
                }

                if (words[currentWordIdx].length >= lineLimit) {
                    lines.push([words.currentWord]);
                    currentWordIdx++;
                }
            }
        }

        return lines;
    }

    useEffect(() => {
        window.addEventListener("resize", SetWindowSize);
    }, [])

    useEffect(() => {
        setLabels(apiData && apiData.rows?.map(row => splitLabelForChart((row.name.concat(' (', row.count, ' nəfər)')))))
        setPercentage(apiData && apiData.rows?.map(row => row.percentage))
    }, [apiData])

    const data = {
        labels,
        datasets: [
            {
                data: percentage,
                backgroundColor: color,
            }
        ]
    }

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function(tooltipItem) {
                        const resultingTooltipTitle = tooltipItem[0].label
                        let splittedParts = resultingTooltipTitle.split(" ,");
                        let result = splittedParts.join(" ");

                        return result;
                    },

                    label: function (tooltipItem) {
                        return tooltipItem.formattedValue + '%';
                    }
                }
            }
        },
        indexAxis: 'y',
        barThickness: screenSize < 400 ? 10 : screenSize < 768 ? 15 : 30,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        scales: {
            x: {
                ticks: {
                    callback: (val) => {
                        return val + '%';
                    },
                    font: {
                        size: fontSize
                    }
                }
            },
            y: {
                min: 0,
                ticks: {
                    autoSkip: false,
                    font: {
                        size: fontSize
                    },
                }
            }
        }
    }

    return (
        <Bar height={180} options={options} data={data} />
    )
}
