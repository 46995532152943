import { BarChartHor1 } from '../charts/BarChartHor1'

const Template3 = ({ data, projectName }) => {
  return (
    <div className='row chart-container'>
      <div className='chart-header col-md-3'>
        <h6 className="text-center">{projectName ? projectName + "ndə " + data.title.toLowerCase() : data.title}</h6>
      </div>
      <div className='chart-content col-md-9'>
        <BarChartHor1 apiData={data} legend={false} color={'#223a5e'} />
      </div>
    </div>
  )
}

export default Template3 
