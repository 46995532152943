import React from "react"
import styled from "styled-components" 
import { theme } from "../../theme/variables" 
import range from "lodash/range" 
import BootstrapPagination, { PaginationProps } from "react-bootstrap/Pagination" 

const PaginationWrapper = styled(BootstrapPagination)`
  justify-content: flex-end;
  margin-top: 20px;
`

const PaginationItem = styled(BootstrapPagination.Item)`
  span {
    background-color: ${(props) => (props.active ? theme.colors.blue : "")} !important;
    border-color: ${(props) => (props.active ? theme.colors.blue : "")} !important;
  }
`

interface IProps extends PaginationProps {
  onPageChanged: (page: number) => void
  currentPage: number 
  totalPageCount: number 
}

interface IPage {
  value: number
  shouldShown: boolean 
}

function calcPages(pages: number[], currentPage: number): IPage[] {
  const { length } = pages 

  if (length < 8) {
    return pages.map((p) => ({
      value: p,
      shouldShown: true,
    })) 
  }

  return pages.map((p) => {
    let shouldShown = false 
    if (currentPage === 1) {
      shouldShown =
        p === length ||
        p === currentPage ||
        p === currentPage + 1 ||
        p === currentPage + 2 ||
        p === currentPage + 3 ||
        p === currentPage + 4 ||
        p === currentPage + 5 
    } else if (currentPage === 2) {
      shouldShown =
        p === length ||
        p === currentPage ||
        p === currentPage + 1 ||
        p === currentPage + 2 ||
        p === currentPage + 3 ||
        p === currentPage + 4 ||
        p === currentPage - 1 
    } else if (currentPage === 3) {
      shouldShown =
        p === length ||
        p === currentPage ||
        p === currentPage + 1 ||
        p === currentPage + 2 ||
        p === currentPage + 3 ||
        p === currentPage - 2 ||
        p === currentPage - 1 
    } else if (currentPage === length - 2) {
      shouldShown =
        p === length ||
        p === currentPage ||
        p === currentPage - 1 ||
        p === currentPage - 2 ||
        p === currentPage - 3 ||
        p === currentPage + 2 ||
        p === currentPage + 1 
    } else if (currentPage === length - 1) {
      shouldShown =
        p === length ||
        p === currentPage ||
        p === currentPage - 1 ||
        p === currentPage - 2 ||
        p === currentPage - 3 ||
        p === currentPage - 4 ||
        p === currentPage + 1 
    } else if (currentPage === length) {
      shouldShown =
        p === length ||
        p === currentPage ||
        p === currentPage - 1 ||
        p === currentPage - 2 ||
        p === currentPage - 3 ||
        p === currentPage - 4 ||
        p === currentPage - 5 
    } else {
      shouldShown =
        p === length ||
        p === currentPage ||
        p === currentPage - 1 ||
        p === currentPage - 2 ||
        p === currentPage + 2 ||
        p === currentPage + 1 
    }

    return { value: p, shouldShown } 
  }) 
}

function calcReal(pages: IPage[]): IPage[] {
  const array: IPage[] = [] 
  let foundDots = false 

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i]

    if (page.shouldShown) {
      array.push(page)
      foundDots = false 
    } else {
      if (!foundDots) {
        array.push(page) 
      }
      foundDots = true 
    }
  }

  return array 
}

export const Pagination: React.FC<IProps> = ({ onPageChanged, currentPage, totalPageCount, ...rest }: IProps) => {
  const pages = range(1, totalPageCount + 1) 

  return (
    <PaginationWrapper {...rest}>
      <BootstrapPagination.First
        disabled={currentPage === 1}
        onClick={() => {
          onPageChanged(1) 
        }}
      />
      <BootstrapPagination.Prev
        disabled={currentPage === 1}
        onClick={() => {
          onPageChanged(currentPage - 1) 
        }}
      />

      {calcReal(calcPages(pages, currentPage)).map((page) => {
        if (page.shouldShown) {
          return (
            <PaginationItem
              key={page.value}
              active={page.value === currentPage}
              onClick={() => {
                onPageChanged(page.value) 
              }}
            >
              {page.value}
            </PaginationItem>
          ) 
        } else {
          return <BootstrapPagination.Ellipsis key={page.value} disabled /> 
        }
      })}

      <BootstrapPagination.Next
        disabled={currentPage === totalPageCount}
        onClick={() => {
          onPageChanged(currentPage + 1) 
        }}
      />
      <BootstrapPagination.Last
        disabled={currentPage === totalPageCount}
        onClick={() => {
          onPageChanged(totalPageCount) 
        }}
      />
    </PaginationWrapper>
  ) 
} 
