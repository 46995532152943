import { ApiClient, apiClient } from "../../../../core/api_client";
import { IApartment, apartmentFromJson } from "./entities";
import { Failure } from "../../../../core/failure";

interface IApartmentRepo {
  getApartmentDetail: (id: string) => Promise<IApartment>;
}

const ApartmentRepoImplFactory = (apiClient: ApiClient): IApartmentRepo => {
  const r: IApartmentRepo = {
    getApartmentDetail: async (id) => {
      try {
        const res = await apiClient.get(`/getApartment/${id}`); 
        return apartmentFromJson(res.data);
      } catch (error: any) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const ApartmentRepoImpl = ApartmentRepoImplFactory(apiClient);
