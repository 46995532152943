import { useState } from 'react'
import Modal from "react-bootstrap/Modal"
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components"

// assets
import arrowl_1 from '../../assets/img/utils/slider-arrow-l-1.svg';
import arrowr_1 from '../../assets/img/utils/slider-arrow-r-1.svg';

// styles
import "swiper/css";
import "swiper/css/pagination";
import { Routing } from '../core/routing';

const ModalHeader = styled(Modal.Header)`
  background-color: #223A5E;
  color: white;
  border: none;
  display: flex;
  align-items: center;

  .btn-close{
    background-color: white;
    opacity: 1;
    margin-right: 1px;
  }
`

const ModalContent = styled(Modal.Body)`
  color: #424650;
  padding: 0;
  height: 70vh;
`

const ResizableImg = ({ src, className, gallery, alt, style }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    function openModal() {
        setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }

    return (
        <>
            <a onClick={openModal}>
                <img src={src} className={className} style={style} alt={alt}></img>
            </a>
            <Modal dialogClassName="modal-100w mx-auto" backdropClassName='show'
                show={isModalOpen}
                size='xl'
                onHide={closeModal}
            >
                <ModalHeader closeButton />
                <ModalContent>
                    {gallery ?
                        <div className="slider h-100">
                            <div className="swiper-button-prev-n swiper-slide-btn d-md-flex d-none"
                                style={{
                                    height: '100%',
                                    transform: "unset",
                                    top: "0",
                                    zIndex: 2
                                }}>
                                <img src={arrowl_1} style={{ height: "100px", margin: "auto", userSelect: 'none' }} alt="" />
                            </div>
                            <Swiper pagination={{ clickable: true }}
                                modules={[Pagination, Navigation]}
                                loop={true}
                                navigation={{
                                    nextEl: '.swiper-button-next-n',
                                    prevEl: '.swiper-button-prev-n',
                                }}
                                className="mySwiper h-100 w-100">
                                {gallery.map(item =>
                                    <SwiperSlide key={item.id} className='h-100 w-100'>
                                        <img src={Routing.generateFileRoute(item.file)} className={className} style={{ objectFit: 'cover' }} alt={alt}></img>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                            <div className="swiper-button-next-n swiper-slide-btn d-md-flex d-none"
                                style={{
                                    height: '100%',
                                    transform: "unset",
                                    top: "0",
                                    zIndex: 2
                                }}>
                                <img src={arrowr_1} style={{ height: "100px", margin: "auto", userSelect: 'none' }} alt="" />
                            </div>
                        </div>
                        : <img src={src} className={className} style={style} alt={alt}></img>
                    }
                </ModalContent>
            </Modal>
        </>
    )
}

export default ResizableImg
