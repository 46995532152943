import * as React from "react";

export function CalculatorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="25px" height="32px" viewBox="0 0 25 32" {...props}>
      <path
        d="M23.221875,0 L1.778125,0 C0.941145833,0 0.260416667,0.68125 0.260416667,1.51770833 L0.260416667,29.7322917 C0.260416667,30.56875 0.941145833,31.25 1.778125,31.25 L23.221875,31.25 C24.0588542,31.25 24.7395833,30.56875 24.7395833,29.7322917 L24.7395833,1.51770833 C24.7395833,0.68125 24.0588542,0 23.221875,0 Z M23.6979167,29.7322917 C23.6979167,29.9947917 23.484375,30.2083333 23.221875,30.2083333 L1.778125,30.2083333 C1.515625,30.2083333 1.30208333,29.9947917 1.30208333,29.7322917 L1.30208333,1.51770833 C1.30208333,1.25520833 1.515625,1.04166667 1.778125,1.04166667 L23.221875,1.04166667 C23.484375,1.04166667 23.6979167,1.25520833 23.6979167,1.51770833 L23.6979167,29.7322917 Z"
        id="Shape"
      ></path>
      <path
        d="M2.86458333,28.6458333 L8.59375,28.6458333 L8.59375,22.9166667 L2.86458333,22.9166667 L2.86458333,28.6458333 Z M3.90625,23.9583333 L7.55208333,23.9583333 L7.55208333,27.6041667 L3.90625,27.6041667 L3.90625,23.9583333 Z"
        id="Shape"
      ></path>
      <path
        d="M9.63541667,28.6458333 L15.3645833,28.6458333 L15.3645833,22.9166667 L9.63541667,22.9166667 L9.63541667,28.6458333 Z M10.6770833,23.9583333 L14.3229167,23.9583333 L14.3229167,27.6041667 L10.6770833,27.6041667 L10.6770833,23.9583333 Z"
        id="Shape"
      ></path>
      <path
        d="M16.40625,28.6458333 L22.1354167,28.6458333 L22.1354167,16.1458333 L16.40625,16.1458333 L16.40625,28.6458333 Z M17.4479167,17.1875 L21.09375,17.1875 L21.09375,27.6041667 L17.4479167,27.6041667 L17.4479167,17.1875 Z"
        id="Shape"
      ></path>
      <path
        d="M2.86458333,21.875 L8.59375,21.875 L8.59375,16.1458333 L2.86458333,16.1458333 L2.86458333,21.875 Z M3.90625,17.1875 L7.55208333,17.1875 L7.55208333,20.8333333 L3.90625,20.8333333 L3.90625,17.1875 Z"
        id="Shape"
      ></path>
      <path
        d="M9.63541667,21.875 L15.3645833,21.875 L15.3645833,16.1458333 L9.63541667,16.1458333 L9.63541667,21.875 Z M10.6770833,17.1875 L14.3229167,17.1875 L14.3229167,20.8333333 L10.6770833,20.8333333 L10.6770833,17.1875 Z"
        id="Shape"
      ></path>
      <path
        d="M2.86458333,15.1041667 L8.59375,15.1041667 L8.59375,9.375 L2.86458333,9.375 L2.86458333,15.1041667 Z M3.90625,10.4166667 L7.55208333,10.4166667 L7.55208333,14.0625 L3.90625,14.0625 L3.90625,10.4166667 Z"
        id="Shape"
      ></path>
      <path
        d="M9.63541667,15.1041667 L15.3645833,15.1041667 L15.3645833,9.375 L9.63541667,9.375 L9.63541667,15.1041667 Z M10.6770833,10.4166667 L14.3229167,10.4166667 L14.3229167,14.0625 L10.6770833,14.0625 L10.6770833,10.4166667 Z"
        id="Shape"
      ></path>
      <path
        d="M16.40625,15.1041667 L22.1354167,15.1041667 L22.1354167,9.375 L16.40625,9.375 L16.40625,15.1041667 Z M17.4479167,10.4166667 L21.09375,10.4166667 L21.09375,14.0625 L17.4479167,14.0625 L17.4479167,10.4166667 Z"
        id="Shape"
      ></path>
      <path
        d="M2.86458333,7.8125 L22.1354167,7.8125 L22.1354167,2.60416667 L2.86458333,2.60416667 L2.86458333,7.8125 Z M3.90625,3.64583333 L21.09375,3.64583333 L21.09375,6.77083333 L3.90625,6.77083333 L3.90625,3.64583333 Z"
        id="Shape"
      ></path>
    </svg>
  );
}
