// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import { Link } from "react-router-dom"

const Breadcrumb = ({ data, className, style }) => {
    return (
        <>
            <div className={'breadcrumb page-link ' + className} style={style}>
                <div className='line'></div>
                <div className='container'>
                    <div className='bc-items d-flex justify-content-center px-3'>
                        {data && data.map(item => item.link.includes('http') ?
                            <a href={item.link} className={"bc-button " + item.style + (item.isActive ? " bc-button-active" : "")} key={item.id}>{item.title}</a>
                            :
                            <Link to={item.link} className={"bc-button " + item.style + (item.isActive ? " bc-button-active" : "")} key={item.id}>{item.title}</Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Breadcrumb 
