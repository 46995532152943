// libraries
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// components & functions
import { apiClient } from '../../core/api_client'
import l from '../../core/localize'

const Tenders = ({ paginate = false, tendersOnPage = 8 }) => {
	const [tenders, setTenders] = useState([])
	const { t, i18n } = useTranslation()
	const currentLang = i18n.language.slice(0, 2)

	useEffect(() => {
		apiClient.get('/tenders').then(res => setTenders(res.data))
	}, [])

	const [page, setPage] = useState(0)
	let totalPages = Math.ceil(tenders.length / tendersOnPage)
	let firstActivePage = page - 2
	if (firstActivePage < 1) {
		firstActivePage = 1
	} else if (totalPages - firstActivePage < 4) {
		firstActivePage = totalPages - 4
	}
	let activePages = []
	for (let i = firstActivePage; i <= Math.min(firstActivePage + 4, totalPages); i++) {
		activePages.push(i)
	}
	return (
		<div className='container py-4'>
			<h1 className="text text-center mb-4 t-color5 heading">{t('agentlik:tender_ann')}</h1>
			<div className="row justify-content-between">
				{tenders.slice(page * tendersOnPage, (page + 1) * tendersOnPage).map(item =>
					<Link to={"/agentlik/mida-mmc/elanlar/" + item.id} key={item.id} className='col-lg-3 col-md-3 col-sm-4 col-6 mb-md-5 mb-3'>
						<div className='pt-3 px-3 pb-2 bg-white rounded-4 heading'>
							<p className='t-color5 fw-500 limit-content'>{l(item, "name", currentLang)}</p>
							<p className='t-color5 fw-500 mt-3'>{dayjs(item.date).format('DD.MM.YYYY')}</p>
						</div>
					</Link>
				)}
			</div>
			{tenders.length === 0 && <h4 className="col-12 text-center">{t('common:no_content')}</h4>}
			{paginate && tenders.length > 0 && <div className="col-12">
				<div className="pagination d-flex justify-content-center">
					<button className="btn btn-outline-primary" disabled={page === 0} onClick={() => setPage(0)}>&lt;&lt;</button>
					<button className="btn btn-outline-primary" disabled={page === 0} onClick={() => setPage(page - 1)}>&lt;</button>
					{activePages.map(item =>
						<button
							className={`btn btn-outline-primary ${item - 1 === page ? 'active' : ''}`}
							disabled={item - 1 === page}
							onClick={() => setPage(item - 1)}
							key={item}
						>
							{item}
						</button>
					)}
					<button className="btn btn-outline-primary" disabled={page === totalPages - 1} onClick={() => setPage(page + 1)}>&gt;</button>
					<button className="btn btn-outline-primary" disabled={page === totalPages - 1} onClick={() => setPage(totalPages - 1)}>&gt;&gt;</button>
				</div>
			</div>}
		</div>
	)
}

export default Tenders