const Table11 = ({ apiData, info }) => {

    function search(nameKey, myArray) {
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i].roomsCount === nameKey) {
                return myArray[i].value
            }
        }
    }

    function countTotal(nameKey) {
        let totalMale = 0
        let totalFem = 0

        info.female.map(val => totalFem += val.value)
        info.male.map(val => totalMale += val.value)

        if (nameKey === 'fem') {
            return totalFem
        }
        if (nameKey === 'male') {
            return totalMale
        }
    }

    return (
        <>
            {apiData && (
                <table id="table11" className='table table-bordered table-responsive mb-0'>
                    <thead>
                        <tr>
                            <th rowSpan={3} colSpan={6} className="table-category-header">Kateqoriya</th>
                            <th colSpan={12}>
                               Mənzilin otaq sayı üzrə
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={4}>
                                Kişi  ({countTotal('male')} nəfər)
                            </th>
                            <th colSpan={4}>
                                Qadın  ({countTotal('fem')} nəfər)
                            </th>
                            <th rowSpan={2} className="p-4">
                                Cəmi
                            </th>
                        </tr>
                        <tr>
                            <th>1 otaqlı</th>
                            <th>2 otaqlı</th>
                            <th>3 otaqlı</th>
                            <th>4 otaqlı</th>
                            <th>1 otaqlı</th>
                            <th>2 otaqlı</th>
                            <th>3 otaqlı</th>
                            <th>4 otaqlı</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiData?.table?.map(row =>
                            <tr key={apiData.table.indexOf(row)}>
                                <td colSpan={6} className={`text-${row.category === "TOTAL" ? "center" : "start"}`}>{row.category === "TOTAL" ? "CƏMİ" : row.category}</td>
                                <td>{search(1, row.male)}</td>
                                <td>{search(2, row.male)}</td>
                                <td>{search(3, row.male)}</td>
                                <td>{search(4, row.male)}</td>
                                <td>{search(1, row.female)}</td>
                                <td>{search(2, row.female)}</td>
                                <td>{search(3, row.female)}</td>
                                <td>{search(4, row.female)}</td>
                                <td colSpan={2}>{row.totalCount}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default Table11
