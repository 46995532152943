import { Pie } from 'react-chartjs-2';
import { useEffect, useState } from 'react';

export function PieChart({ apiData }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (apiData && apiData.rows) {
            const filteredData = apiData.rows.filter(item => item.name !== "TOTAL");

            const labels = filteredData.map(item => item.name);
            const percentages = filteredData.map(item => item.percentage);

            setData({
                labels: labels,
                datasets: [{
                    data: percentages,
                    backgroundColor: ['#f79647', '#223a5e', '#8ba2c2', '#8f5062', '#EADFB4', '#508f79', '#F9D949',  '#8f5d9e', '#BED1CF','#47A992', '#ACB1D6'],
                    borderColor: ['#f7f7f7']
                }]
            });
        }
    }, [apiData]);

    const [screenSize, setScreenSize] = useState(window.clientWidth);

    function splitLabelForChart(label) {
        const words = label.split(' ');
        const lineLimit = screenSize < 400 ? 45 : screenSize < 785 ? 55 : screenSize < 1200 ? 60 : 60;
        const lines = [];

        let line = '';
        let currentWordIdx = 0;

        while (currentWordIdx < words.length) {
            if (line.length + words[currentWordIdx].length < lineLimit) {
                line += `${words[currentWordIdx]} `;
                currentWordIdx++;

                if (currentWordIdx === words.length) {
                    lines.push(line);
                }
            } else {
                if (line.length) {
                    lines.push(line);
                    line = '';
                }

                if (words[currentWordIdx].length >= lineLimit) {
                    lines.push([words.currentWord]);
                    currentWordIdx++;
                }
            }
        }

        return lines;
    }

    const options = {
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                    font: {
                        size: 17
                    }
                }
            },
            tooltip: {
                callbacks: {
                    title: function(tooltipItems) {
                        var splitted = splitLabelForChart(tooltipItems[0].label);
                        return splitted;
                    },
                    
                    label: function (tooltipItem) {
                        return tooltipItem.formattedValue + '%';
                    },
            font: {
                size: 5
                }
            }
            }
            
        }
    }

    return (
        <>
            <div className='col-md-7 my-4 mx-auto'>{data && <Pie data={data} options={options} />}</div>
        </>
    );
}
