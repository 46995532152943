// libraries
import { Swiper, SwiperSlide } from "swiper/react"

// assets
import gerb from "../../../../assets/img/links/gerb.svg"
import azerb from "../../../../assets/img/links/azerb.svg"
import virtualg from "../../../../assets/img/links/virtualg.svg"
import xocali from "../../../../assets/img/links/xocali.svg"
import asan from "../../../../assets/img/links/asan.svg"
import ipoteka from "../../../../assets/img/links/ipoteka.svg"

// styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

const UsefulLinks = () => {
    const data = [
        {
            id: 3,
            img: gerb,
            title: "Nazirlər Kabineti",
            link: "https://nk.gov.az"
        },
        {
            id: 5,
            img: azerb,
            title: "Azərbaycan",
            link: "https://azerbaijan.az/"
        },
        {
            id: 6,
            img: virtualg,
            title: "Virtual Qarabağ",
            link: "https://www.virtualkarabakh.az/"
        },
        {
            id: 7,
            img: xocali,
            title: "Xocalıya Ədalət",
            link: "https://justiceforkhojaly.org/en"
        },
        {
            id: 8,
            img: asan,
            title: "ASAN xidmət",
            link: "https://asan.gov.az/"
        },
        {
            id: 9,
            img: ipoteka,
            title: "İpoteka Fondu",
            link: "http://mcgf.gov.az/"
        }
    ]

    return (
        <>
            <div className="swiper-container position-relative mt-2 mb-3 d-none d-md-block">
                <Swiper
                    slidesPerView={6}
                    spaceBetween={10}
                    className="mySwiper">
                    {data.map(item =>
                        <SwiperSlide key={item.id} className="hover-img">
                            <a href={item.link} target="_blank" rel="noreferrer" className="d-flex align-items-center">
                                <img src={item.img} alt="" />
                                <small className="text">{item.title}</small>
                            </a>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
            <div className="row mt-2 mb-3 d-md-none">
                {data.map(item =>
                    <div className="w-100 d-flex align-items-center my-2" key={item.id + Math.random()}>
                        <img src={item.img} alt="" />
                        <small className="text">{item.title}</small>
                    </div>
                )}
            </div>
        </>
    )
}

export default UsefulLinks 
