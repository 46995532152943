import { BarHorStacked } from '../charts/BarHorStacked'

const Template6 = ({ data }) => {
    return (
        <>
            <div className='row chart-container'>
                <div className='chart-header col-md-3'>
                    <h6 className="text-center">{data.title}</h6>
                </div>
                <div className='chart-content col-md-9'>
                    <BarHorStacked apiData={data} />
                </div>
            </div>

        </>
    )
}

export default Template6 
