import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

Chart.register(ArcElement);

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export function PieChart({ apiData, projectName }) {
    const [registrationData, setRegistrationData] = useState(null);
    const [salesData, setSalesData] = useState(null);

    useEffect(() => {
        if (apiData) {
            setRegistrationData({
                labels: ['Öz vəsaiti hesabına', 'İpoteka krediti hesabına'],
                datasets: [{
                    data: [
                        apiData.registrationTime?.cashPercentage,
                        apiData.registrationTime?.loanPercentage
                    ],
                    backgroundColor: ['#223a5e', '#f79647'],
                    borderColor: ['#f7f7f7']
                }]
            });

            setSalesData({
                labels: ['Öz vəsaiti hesabına', 'İpoteka krediti hesabına'],

                datasets: [{
                    data: [
                        apiData.salesTime?.cashPercentage,
                        apiData.salesTime?.loanPercentage
                    ],
                    backgroundColor: ['#223a5e', '#f79647'],
                    borderColor: ['#f7f7f7']
                }]
            });
        }
    }, [apiData]);

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    font: {
                        size: 17
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.formattedValue + '%';
                    }
                }
            }
        }
    }

    return (
        <div className='row chart-container'>
            <div className='chart-header col-md-3'>
                <h5 className='text-center'>{projectName ? projectName + "ndə ö" : "Ö"}dəniş üsulu üzrə bölgüsü (faizlə)</h5>
            </div>

            <div className='chart-content col-md-9'>
                <div className='col-12 row align-center pt-5 mb-4'>
                    <div className='col-6'>
                        <h6 className="text-center">“Güzəştli mənzil” sistemində qeydiyyat zamanı</h6>
                    </div>
                    <div className='col-6'>
                        <h6 className="text-center">Mənzilin alqı-satqısı zamanı</h6>
                    </div>
                </div>

                <div className='col-12 row justify-content-evenly align-center mb-4'>
                    <div className='col-4 justify-content-center d-flex'>
                        {registrationData && <Pie data={registrationData} options={options} />}
                    </div>
                    <div className='col-4 justify-content-center d-flex'>
                        {salesData && <Pie data={salesData} options={options} />}
                    </div>
                </div>
            </div>
        </div>
    );
}