const Confidential = () => {
    return (
        <div className="insidetxt">
            <h1 className="bigheader">KONFİDENSİALLIQ BİLDİRİŞİ</h1>
            <div className="txt legislation f_confidential">
                <p>
                    Bu bildiriş Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyi və onun törəmə və ya tabeliyində olan təşkilatlar tərəfindən elektron portalda yaradılmış “Güzəştli mənzil” sistemi (bundan sonra “Sistem”) vasitəsi ilə informasiyanın toplanılması, işlənilməsi və mühafizəsinə dair öhdəliklərimizi açıqlayır. Nəzərə alın ki, Sistem aşağıda sadalanan normativ-hüquqi aktlar ilə tənzimlənir:
                    <br />• “Fərdi məlumatlar haqqında” Azərbaycan Respublikasının Qanunu;
                    <br />• “Elektron hökumət” portalı haqqında Əsasnamə”;
                    <br />• “Güzəştli mənzil” sistemi haqqında Əsasnamə”.
                    <br />
                    <br />
                    <strong>FƏRDİ MƏLUMATLAR</strong>
                </p>
                <p>
                    <strong>Fərdi məlumat</strong> — şəxsin kimliyini birbaşa və ya dolayısı ilə müəyyənləşdirməyə imkan verən istənilən məlumatdır. Sistem vasitəsi ilə biz aşağıda göstərilən məlumatları əldə edirik:
                    <br />• Ad, soyad, ata adı;
                    <br />• İstifadəçinin adı;
                    <br />• Poçt ünvanı;
                    <br />• Telefon nömrəsi;
                    <br />• Elektron poçt ünvanı;
                    <br />• Şəxsiyyət vəsiqəsinin nömrəsi;
                    <br />• Şəxsiyyət vəsiqəsinin FİN kodu.
                    <br />
                    Fərdi məlumatlardan istifadə etməkdə məqsəd “Güzəştli mənzil” sistemi vasitəsi ilə müraciət edən şəxslərə güzəştli şərtlər ilə mənzil əldə etmək üçün elektron kabinetin verilməsindən ibarətdir. Əldə edilmiş elektron kabinet istifadəçinin mənzilləri güzəştlə əldə etmək hüququnu təsdiqləyir. Əgər siz tərəfimizdən sorğu edilən məlumatları təqdim etməsəniz, Sistemin funksional imkanlarından və xidmətlərimizdən tam istifadə edə bilməyəcəksiniz.
                    <br />
                    <strong>Elektron kabinet</strong> – Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin sərəncamında olan mənzilləri vətəndaşların güzəştlə əldə etməsi qaydasına (bundan sonra “Qayda”) uyğun olaraq mənzilləri güzəştlə əldə etmək hüququ müəyyən edilmiş istifadəçilərə “MİDA” Məhdud Məsuliyyətli Cəmiyyətinin (bundan sonra “Cəmiyyət”) qərarı əsasında verilən, elektron sistemin istifadəçisinin yalnız gücləndirilmiş elektron imza sertifikatı ilə portalda autentifikasiyadan keçməklə elektron sistemə daxil olmasına imkan verən və həmin elektron sistemdə yaradılan, istifadəçinin özü barədə olan məlumatlarla tanış olmasını və onları əldə etməsini, habelə onun sistemin iştirakçıları ilə qarşılıqlı əlaqəsini təmin edən fərdi elektron səhifə.
                    <br />
                    Cəmiyyətimiz elektron kabinet istifadəçilərinə güzəştli şərtlərlə mənzil əldə etmək hüquqlarından istifadə edə biləcəklərinə dair zəmanət verir.
                    <br />
                    <br />
                    <strong>Fərdi məlumatların toplanması</strong>
                    <br />• Biz fərdi məlumatları Sistem vasitəsi ilə toplayırıq və siz müvafiq forma və sorğuları dolduraraq Qaydaya uyğun olaraq elektron kabinet əldə edirsiniz.
                    <br />
                    <br />
                    <strong>Təqdim olunan informasiya</strong>
                    <br />• Elektron qaydada müraciət etdiyiniz zaman sizin fərdi məlumatlarınızdan (elektron poçt ünvanı, ad, soyad, ata adı, telefon nömrəsi və s.) istifadə edirik.
                    <br />
                    Nəzərə almağınız xahiş olunur ki, Sistem vasitəsi ilə digər şəxslərin fərdi məlumatlarını bizə açıqladığınız zaman, qanunvericilikdə nəzərdə tutlmuş etibarnaməyə malik olmalısınız. Təqdim etdiyiniz etibarnamə ilə siz bu bildirişdə qeyd edilən qaydada bizə həmin məlumatlardan istifadə etməyə icazə verirsiniz.
                    <br />
                    <br />
                    <strong>Fərdi məlumatlardan istifadə</strong>
                    <br />
                    <strong>Biz aşağıda göstərilən məqsədlər üçün fərdi məlumatlardan istifadə edirik:</strong>
                    <br />• Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin sərəncamındakı mənzillərin güzəştli qaydada satışını həyata keçirmək;
                    <br />• Müraciətinizin cavablandırılmasını və mənzil seçimi ərizənizə baxılmanı təmin etmək;
                    <br />• Sizə müvafiq informasiyanı (müraciət və ərizənizə baxılmanın nəticəsi, keçiriləcək sorğular, mənzillərin satışı barədə elan və s.) vermək.
                    <br />
                    <br />
                    <strong>Biz bu hərəkətləri sizin icazənizlə və ya qanuni işgüzar maraqlarımızın təmin olunması məqsədilə yerinə yetiririk.</strong>
                    <br />
                    Qeyd olunan hərəkətlər hüquqi öhdəliklərimizin yerinə yetirilməsini səciyyələndirir.
                    <br />
                    <strong>Biz layihələrimizin reallaşdırılması və informasiyanın təhlili üçün aşağıdakı tədbirləri həyata keçiririk:</strong>
                    <br />• Biznes layihələrimizin təmin olunması, o cümlədən məlumatların təhlili, audit nəzarəti, qeyri-qanuni hərəkətlərin qarşısının alınması, yeni xidmətlərin təşkili, elektron sistemin inkişafı və effektivliyin artırılması, reklam aksiyaların qiymətləndirilməsi və fəaliyyətimizin genişləndirilməsi.
                    <br />• Müvafiq məqsədlər üçün IP ünvanlardan istifadə, o cümlədən istifadəçilərin sayının təhlili və elektron sistemdə nasazlıqların diaqnostikası.
                    <br />
                    <strong>Biz bu hərəkətləri aşağıdakı əsaslarla yerinə yetiririk:</strong>
                    <br />• sizin gücləndirilmiş elektron imza ilə təsdiqləyərək verdiyiniz razılıq;
                    <br />• hüquqi öhdəliklərin icrası ilə bağlı və qanuni işgüzar maraqlarımızın təmin olunması.
                    <br />
                    <br />
                    <br />
                    <strong>Fərdi məlumatların açıqlanması </strong>
                    <br />
                    <strong>Biz aşağıdakı hallarda fərdi məlumatları açıqlayırıq:</strong>
                    <br />• Nümayəndələrimizə və müqavilə münasibətində olan kommersiya banklarına;
                    <br />• Bizə xidmət göstərən şəxslərə, o cümlədən xostinq, proqram təminatı, təmir, proqramların dəyişdirilməsi, məlumatların təhlili, informasiya texnologiyaları, müştəri xidmətləri, audit və s.
                    <br />
                    Qeyd: Üçüncü şəxslərə sizin razılığınız olmadan fərdi məlumatlarınızı açıqlamırıq.
                    <br />
                    <br />
                    <strong>Fərdi məlumatların digər hallarda açıqlanması və istifadəsi</strong>
                    <br />
                    Aşağıda göstərilən hallarda və ya lazımi hesab etdikdə, fərdi məlumatlarınızı açıqlayırıq:
                    <br />• Qanunvericiliyə və normativ-hüquqi aktlara əməl olunması;
                    <br />• Müvafiq dövlət orqanlarının və ya məhkəmə sorğularının təmin edilməsi;
                    <br />• Digər qanuni əsaslarla.
                    <br />
                    <br />
                    <strong>Digər məlumatlar</strong> — istifadəçinin şəxsiyyətini açıqlamayan və bir şəxslə əlaqəli olmayan hər hansı məlumat.
                    <br />• Brauzer və cihaz məlumatları;
                    <br />• Demoqrafik məlumat;
                    <br />• İstifadəçilər tərəfindən verilmiş və müəyyən bir şəxsin şəxsiyyətini aşkar etməyən digər məlumatlar;
                    <br />• IP- ünvan.
                    <br />
                    Əgər qanunvericilikdə digər məlumatların fərdi məlumatlar kimi istifadə olunması tələb olunarsa həmin məlumatları da qanunvericilikdə nəzərdə tutulduğu kimi istifadə edəcəyik.
                </p>
                <p>
                    <strong>ÜÇÜNCÜ ŞƏXSLƏRİN XİDMƏTLƏRİ</strong>
                    <br />
                    Bu bildirişdə qeyd olunan öhdəliklər üçüncü şəxslərə aid deyil. O cümlədən biz informasiya resurslarına xidmət göstərən üçüncü şəxslərin hərəkətləri ilə əlaqədar öhdəlik daşımırıq.
                </p>
                <p>
                    <strong>TƏHLÜKƏSİZLİK</strong>
                    <br />
                    Biz məlumatların qorunması, itirilməməsi, məlumatlardan sui-istifadə edilməməsi üçün bütün zəruri tədbirləri yerinə yetiririk. Biz maksimal dərəcədə sizin məlumatlarınızın qorunması üçün çalışırıq, lakin internet vasitəsi ilə ötürdüyünüz məlumatların tam qorunması, oğurlanması, məhv olunması və bunun nəticəsində açıqlanması halına tam zəmant verə bilmirik. Əgər siz bizə ötürdüyünüz məlumatların qorunmasında şübhələnirsinizsə bu barədə dərhal bizə məlumat verməyinizi xahiş edirik.
                </p>
                <p>
                    <strong>SAXLAMA MÜDDƏTİ</strong>
                    <br />
                    Elektron kabinet sahiblərinin fərdi məlumatları məlumat bazamızda tələb olunan və qanunvericilikdə nəzərdə tutulmuş müddətdə saxlanılır.
                    <br />
                    VEB-SAYTDAN YETKİNLİK YAŞINA ÇATMAYANLARIN İSTİFADƏSİ
                    <br />
                    Veb-sayt 18 yaşına çatmayan şəxslərin istifadə etmələri üçün nəzərdə tutulmayıb. Biz həmin şəxslərin fərdi məlumatlarının toplanmasını, emal edilməsini və istifadəsini həyata keçirmirik.
                </p>
                <p>
                    <strong>HÜQUQ DAİRƏSİ VƏ FƏRDİ MƏLUMATLARIN TRANSSƏRHƏD ÖTÜRÜLMƏSİ</strong>
                    <br />
                    Sizin fərdi məlumatlarınız Azərbaycan Respublikasında saxlanılaraq istifadə edilir. Nəzərə alın ki, biz əldə etdiyimiz məlumatları kommersiya banklarına ötürürük və həmin təşkilatlarda fərdi məlumatların istifadə şərtləri bizə məlum olmayan qaydada nəzərdə tutula bilər. Eyni zamanda qanunvericiliklə nəzərdə tutulan hallarda istintaq və məhkəmə orqanları tərəfindən də fərdi məlumatlarınızdan istifadə edilə bilər.
                </p>
                <p>
                    <strong>KONFİDENSİAL MƏLUMAT</strong>
                    <br />
                    Xahiş edirik ki, konfidensial xarakter daşıyan məlumatları bizə göndərməyəsiniz. Konfidensial məlumat, yəni vətəndaşların mülkiyyət növündən asılı olmayaraq yaradılmış idarə, müəssisə və təşkilatların, digər hüquqi şəxslərin qanuni maraqlarının qorunması məqsədilə əldə olunmasına məhdudiyyət qoyulan məlumatları, habelə peşə (həkim, vəkil, notariat), kommersiya, istintaq və məhkəmə sirləri, sığorta şəhadətnaməsinin nömrəsi, siyasi partiyalılıq, dini baxışlar və sağlamlıq vəziyyəti barədə məlumatları ehtiva edir.
                </p>
            </div>
        </div>
    )
}

export default Confidential
