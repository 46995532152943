import { useEffect, useState } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

Chart.register(ArcElement);

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export function PieChart({ apiData, projectName }) {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);

    useEffect(() => {
        if (apiData) {
            setTotal(apiData.salesTime?.cash + apiData.salesTime?.loan)
            setData({
                labels: ['Öz vəsaiti hesabına', 'İpoteka krediti hesabına'],

                datasets: [{
                    data: [
                        apiData.salesTime?.cashPercentage,
                        apiData.salesTime?.loanPercentage
                    ],
                    backgroundColor: ['#223a5e', '#f79647'],
                    borderColor: ['#f7f7f7']
                }]
            });

        }
    }, [apiData]);

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    font: {
                        size: 17
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        if (tooltipItem.dataIndex == 1) {
                            return apiData.salesTime.loan + ' nəfər (' + tooltipItem.raw + '%)'
                        }
                        else {
                            return apiData.salesTime.cash + ' nəfər (' + tooltipItem.raw + '%)'
                            // return tooltipItem.formattedValue + '%';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='row chart-container'>
            <div className='chart-header col-md-3'>
                <h5 className='text-center'>{apiData.title}</h5>
            </div>

            <div className='chart-content col-md-9'>
                <div className='col-12 row justify-content-evenly align-center mb-4'>
                    <div className='col-4 justify-content-center d-flex'>
                        {data && <Doughnut data={data} options={options} />}
                        {/* {data && <Pie data={data} options={options} />} */}
                    </div>
                </div>
            </div>
        </div>
    );
}