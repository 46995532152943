// libraries
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

// components & functions
import Breadcrumb from '../../components/Breadcrumb'

// NİZAMNAMƏ
const About2 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:about'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/nizamname',
            title: t('agentlik:order'),
            isActive: true
        },
        {
            id: 3,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: false
        },
        {
            id: 4,
            link: '/agentlik/mida-mmc',
            title: t('agentlik:mida_mmc'),
            isActive: false
        },
        {
            id: 5,
            link: '/agentlik/beynelxalq-emekdasliq',
            title: t('agentlik:international'),
            isActive: false
        },
        {
            id: 6,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        }
    ]

    return (
        <div id="nizamname" className="block-bg1 pt-lg-3 pb-md-5 py-0">
            <Breadcrumb data={breadcrumb} />

            <div className='container px-md-120 mt-4 mt-sm-5 mt-md-5'>
                <div className="block-bg-white px-md-120 py-md-5 p-3 text-center rounded-4">
                    <h4 className="heading fw-700 t-color5 text-justify text-center mb-4">{parse(t('agentlik:order_heading_main'))}</h4>
                    <div className='px-md-60'>
                        <div className='text-inf t-color1'>
                            <h6 className="heading text-start mt-2 fw-700" style={{
                                fontSize: "22px",
                                lineHeight: "40px"
                            }}>
                                {parse(t('agentlik:order_heading1'))}</h6>
                            <p className="pt-1 pb-5">
                                {parse(t('agentlik:order_text1'))}
                            </p>
                        </div>

                        <div className='text-inf t-color1'>
                            <h6 className="heading text-start mt-2 fw-700" style={{
                                fontSize: "22px",
                                lineHeight: "40px"
                            }}>
                                {parse(t('agentlik:order_heading2'))}</h6>
                            <p className="pt-3 pb-5">
                                {parse(t('agentlik:order_text2'))}
                            </p>
                        </div>

                        <div className='text-inf t-color1'>
                            <h6 className="heading text-start mt-2 fw-700" style={{
                                fontSize: "22px",
                                lineHeight: "40px"
                            }}>
                                {parse(t('agentlik:order_heading3'))}</h6>
                            <p className="pt-3 pb-5">
                                {parse(t('agentlik:order_text3'))}
                            </p>
                        </div>

                        <div className='text-inf t-color1'>
                            <h6 className="heading text-start mt-2 fw-700" style={{
                                fontSize: "22px",
                                lineHeight: "40px"
                            }}>
                                {parse(t('agentlik:order_heading4'))}</h6>
                            <p className="pt-3 pb-5">
                                {parse(t('agentlik:order_text4'))}
                            </p>
                        </div>

                        <div className='text-inf t-color1'>
                            <h6 className="heading text-start mt-2 fw-700" style={{
                                fontSize: "22px",
                                lineHeight: "40px"
                            }}>
                                {parse(t('agentlik:order_heading5'))}</h6>
                            <p className="pt-3 pb-5">
                                {parse(t('agentlik:order_text5'))}
                            </p>
                        </div>

                        <div className='text-inf t-color1'>
                            <h6 className="heading text-start mt-2 fw-700" style={{
                                fontSize: "22px",
                                lineHeight: "40px"
                            }}>
                                {parse(t('agentlik:order_heading6'))}</h6>
                            <p className="pt-3 pb-5">
                                {parse(t('agentlik:order_text6'))}
                            </p>
                        </div>

                        <div className='text-inf t-color1'>
                            <h6 className="heading text-start mt-2 fw-700" style={{
                                fontSize: "22px",
                                lineHeight: "40px"
                            }}>
                                {parse(t('agentlik:order_heading7'))}</h6>
                            <p className="pt-3 pb-5">
                                {parse(t('agentlik:order_text7'))}
                            </p>
                        </div>

                        <div className='text-inf t-color1'>
                            <h6 className="heading text-start mt-2 fw-700" style={{
                                fontSize: "22px",
                                lineHeight: "40px"
                            }}>
                                {parse(t('agentlik:order_heading8'))}</h6>
                            <p className="pt-3 pb-5">
                                {parse(t('agentlik:order_text8'))}
                            </p>
                        </div>

                        <h5 className="txt-nz text-justify fw-bold t-color1 mt-1 mb-5 pt-md-5 pt-2" style={{ lineHeight: "36px", fontStyle: "italic" }}>
                            {parse(t('agentlik:order_confirm'))}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About2 
