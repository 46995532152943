import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

const BarsVert = ({ apiData }) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        size: 16
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.formattedValue + '%';
                    }
                }
            }
        },
        scales: {
            y: {
                max: 100,
                ticks: {
                    callback: (val) => {
                        return val + '%';
                    }
                }
            }
        }
    }

    const [labels, setLabels] = useState()
    const [dataset, setDataset] = useState([])

    const colors = ['#f79647', '#223a5e', '#8ba2c2', '#c0504e']

    const getType = type => {
        if (type === "cash") {
            return "Öz vəsaiti hesabına"
        }
        else if (type === "loan") {
            return "İpoteka krediti hesabına"
        }
        else if (typeof parseInt(type) === 'number') {
            return type + " otaqlı"
        }
        else return type
    }

    useEffect(() => {
        const sortedLabels = apiData && apiData.rooms?.map(row => row.roomsCount).sort((a, b) => a - b)
        const sortedRooms = apiData && apiData.rooms
            ? apiData.rooms.slice().sort((a, b) => a.roomsCount - b.roomsCount)
            : [];

        setLabels(sortedLabels.map(l => l + " otaqlı"))

        let tempRows = []
        if (apiData && apiData.rooms) {
            let typeLength = sortedRooms[0].stats?.map(el => el.type)
            for (let i = 0; i < typeLength?.length; i++) {
                let tempType = []
                sortedRooms?.forEach(element => {
                    const subElement = element.stats?.find(el => el.type === typeLength[i])
                    tempType.push(subElement)
                })
                tempRows.push(tempType)
            }
        }
        // console.log(tempRows) 
        setDataset(tempRows.map(d => {
            return {
                label: getType(d[0].type),
                data: d.map(dd => dd?.percentage),
                backgroundColor: colors[tempRows.indexOf(d)]
            }
        }))
    }, [apiData])

    const data = {
        labels,
        datasets: dataset
    }

    return (
        <>
            <div className='row chart-container'>
                <div className='chart-header col-md-3'>
                    <h6 className="text-center">{(apiData && apiData.title) && apiData.title}</h6>
                </div>
                <div className='chart-content col-md-9'><Bar options={options} data={data} /></div>
            </div>
        </>
    )
}

export default BarsVert
