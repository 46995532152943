import React from "react"
import { Field, FieldProps } from "formik"
import styled from "styled-components"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import { FormControlProps } from "react-bootstrap/FormControl"

const FormLabel = styled(Form.Label)`
  font-weight: 800;
`;

interface IProps extends FormControlProps {
  label?: string
  name: string
  append: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  showErrors?: boolean
}

export const TextInput: React.FC<IProps> = ({ label, name, onChange, append, showErrors = true, ...rest }: IProps) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps): React.ReactNode => {
        return (
          <Form.Group
            controlId={name}
            style={{
              marginBottom: showErrors ? "1rem" : 0,
            }}
          >
            {label && <FormLabel>{label}</FormLabel>}
            <InputGroup>
              <Form.Control
                value={field.value}
                name={field.name}
                onChange={(e: any) => {
                  if (onChange) {
                    onChange(e)
                  }
                  field.onChange(e)
                }}
                {...rest}
              />
              <InputGroup.Text id={name} className="text-white" style={{ background: '#223A5E' }}>{append}</InputGroup.Text>
            </InputGroup>
            {showErrors && <Form.Text className="text-danger">{meta.touched && meta.error && meta.error}</Form.Text>}
          </Form.Group>
        )
      }}
    </Field>
  )
} 
