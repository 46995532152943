// libraries
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

// components
import Breadcrumb from "../../components/Breadcrumb"

const SatishHesLayiheUzre = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/hesabatlar/satis',
            title: t('agentlik:audits_sales'),
            isActive: true
        }
    ]

    const audit_card = [
        {
            id: 1,
            title: "Yasamal Yaşayış Kompleksinin ikinci mərhələsində mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 2,
            title: "Gəncə Yaşayış Kompleksində mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 3,
            title: "Sumqayıt şəhərində inşa olunmuş binalarda mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 4,
            title: "Hövsan Yaşayış Kompleksində mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 5,
            title: "Yasamal Yaşayış Kompleksində mənzillərin satış nəticələrinə dair statistik məlumatlar",
        }
    ]

    return (
        <div className="audit-page block-bg1 pt-md-3 pb-md-5 py-3">
            <Breadcrumb data={breadcrumb} className="mt-4 mb-0" style={{ height: 0 }} />

            <div className='bg-picture2'>
                <div className='container'>
                    <div className='row pt-60 pb-120'>
                        {audit_card.map(item =>
                            <a href="#" className='audit_card text-center rounded-4 mx-md-2 p-md-4 p-3 my-3 hover-img d-flex align-items-center justify-content-center' style={{ backgroundColor: "#223A5E", width: "32%" }} key={item.id}>
                                <div>
                                    <p className='t-color4 mb-0 text-center'>{parse(item.title)}</p>
                                </div>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SatishHesLayiheUzre 
