import React from "react"
import classes from "./info_panel.module.scss"
import classnames from "classnames"

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string
  children?: React.ReactNode
  address?: string
}

export const InfoPanel: React.FC<IProps> = ({ title, address, children, className, ...rest }: IProps) => {
  return (
    <div className={classnames(classes.info_panel, className)} {...rest}>
      {address && <h5 className="text-white mt-3 my-lg-3 px-3">{address}</h5>}
      {title && (<div className={classes.info_panel_title}>
        <h5>{title}</h5>
      </div>)}
      <div className={classes.info_panel_description + " p-2 p-lg-3"}>{children}</div>
    </div>
  )
} 
