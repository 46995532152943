// libraries
import parse from 'html-react-parser'
import { useTranslation } from "react-i18next"
import Breadcrumb from "../../components/Breadcrumb"

const LayihelerRegemlerle = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/hesabatlar/satis',
            title: t('agentlik:audits_sales'),
            isActive: true
        }
    ]

    const audit_card = [
        {
            id: 1,
            title: "Yasamal Yaşayış Kompleksi",
        },
        {
            id: 2,
            title: "Hövsan Yaşayış Kompleksi",
        },
        {
            id: 3,
            title: "Gəncə Yaşayış Kompleksi",
        },
        {
            id: 4,
            title: "Yasamal Yaşayış Kompleksi 2",
        },
        {
            id: 5,
            title: "Hövsan Yaşayış Kompleksi 2",
        },
        {
            id: 6,
            title: "Sumqayıtda güzəştli mənzillər",
        },
        {
            id: 7,
            title: "Füzuli Yaşayış Kompleksi",
        },
        {
            id: 8,
            title: "Cəbrayıl Yaşayış Kompleksi",
        },
        {
            id: 9,
            title: "Sumqayıt Yaşayış Kompleksi",
        },
        {
            id: 10,
            title: "Lənkəran Yaşayış Kompleksi",
        }
    ]

    return (
        <div className="audit-page block-bg1 pt-md-3 pt-5">
            <Breadcrumb data={breadcrumb} className="mt-4 mb-0" style={{ height: 0 }} />

            <div className='bg-picture2'>
                <div className='container'>
                    <div className='row pt-60 pb-120'>
                        {audit_card.map(item =>
                            <a href="#" className='audit_card text-center rounded-4 p-5 my-3 mx-2 hover-img d-flex align-items-center justify-content-center' style={{ backgroundColor: "#223A5E", width: "32%" }} key={item.id}>
                                <div>
                                    <h5 className='t-color4 mb-0 text-center'>{parse(item.title)}</h5>
                                </div>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LayihelerRegemlerle 
