import { BarHorStacked } from '../charts/BarHorStacked'

const Template5 = ({ data, projectName }) => {
    return (
        <div className='row chart-container'>
            <div className='chart-header col-md-3'>
                <h6 className="text-center">{projectName ? projectName + "ndə " + data.title.toLowerCase() : data.title}</h6>
            </div>
            <div className='chart-content col-md-9'>
                <BarHorStacked apiData={data} />
            </div>
        </div>
    )
}

export default Template5 
