import React, { useState } from "react";
import { ProjectDetailPage } from "./detail_page";
import { ROUTES } from "../../../../../routes";
import { SectorDetailPage } from "../../../sector/ui/pages/detail_page";
import { FloorDetailPage } from "../../../floor/ui/pages/detail_page";
import { ApartmentDetailPage } from "../../../apartment/ui/pages/detail_page";

const ProjectMainPage: React.FC<any> = (props: any) => {
  const { projectId } = props;

  const [page, setPage] = useState({
    route: undefined,
    param1: undefined,
    param2: undefined,
    param3: undefined,
    param4: undefined,
  });

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  const { route, param1, param2, param3, param4 } = page;

  return (
    <>
      {route === ROUTES.sector && param1 && param2 ? (
        <SectorDetailPage projectId={param1} sectorId={param2} onPageChange={handlePageChange} />
      ) : route === ROUTES.floor && param1 && param2 && param3 ? (
        <FloorDetailPage projectId={param1} sectorId={param2} floorId={param3} onPageChange={handlePageChange} />
      ) : route === ROUTES.apartment && param1 ? (
        <ApartmentDetailPage apartmentId={param1} projectId={param2} sectorId={param3} floorId={param4} onPageChange={handlePageChange} />
      ) : (
        <ProjectDetailPage projectId={projectId} onPageChange={handlePageChange} />
      )}
    </>
  );
};

export default ProjectMainPage;
