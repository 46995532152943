// libraries
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

// functions & components
import Accordions from './Accordions'
import Announcements from './Announcements'
import Breadcrumb from '../../../components/Breadcrumb'
import GmsSharedBanner from './GmsSharedBanner'
import { VideoModal } from '../../../components/video_modal'

// assets
import num1 from '../../../../assets/img/guzestli menzil/num1.svg'
import num2 from '../../../../assets/img/guzestli menzil/num2.svg'
import num3 from '../../../../assets/img/guzestli menzil/num3.svg'
import num4 from '../../../../assets/img/guzestli menzil/num4.svg'
import num5 from '../../../../assets/img/guzestli menzil/num5.svg'

import icon1 from '../../../../assets/img/guzestli menzil/tab1_icon.svg'
import icon2 from '../../../../assets/img/guzestli menzil/tab2_icon.svg'
import icon3 from '../../../../assets/img/guzestli menzil/tab3_icon.svg'
import icon4 from '../../../../assets/img/guzestli menzil/tab4_icon.svg'
import icon5 from '../../../../assets/img/guzestli menzil/tab5_icon.svg'

import p1 from '../../../../assets/img/guzestli menzil/p1.svg'
import p2 from '../../../../assets/img/guzestli menzil/p2.svg'
import eimza from '../../../../assets/img/guzestli menzil/eimza.svg'
import asanimza from '../../../../assets/img/guzestli menzil/asanimza.svg'

import arrow_right from "../../../../assets/img/utils/arrow_right.svg"
import arrows from '../../../../assets/img/utils/double-arrows-white.svg'

const GMS = () => {
    const imza_pdf = '/documents/Diqqət edilməli məqamlar_04092018_v2.pdf';
    const yazili1 = '/documents/Qeydiyyat modulu telimat.pdf';
    const yazili2 = '/documents/telimat-ilkinraziliq_03.06.2019_v2.pdf';
    const yazili3 = '/documents/Təlimat Satış.pdf';
    const yazili4 = '/documents/Təlimat Gözləmə.pdf';
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/elektron-xidmetler/gms',
            title: t('gms:gms'),
            isActive: true
        },
        {
            id: 2,
            link: 'https://kommunal.mida.gov.az/search',
            title: t('common:kommunal'),
            isActive: false
        }
    ]

    const [activeItems, setActiveItems] = useState([]);

    const handleToggle = (itemKey) => {
        if (activeItems.includes(itemKey)) {
            setActiveItems(activeItems.filter((key) => key !== itemKey));
        } else {
            setActiveItems([...activeItems, itemKey]);
        }
    };

    return (
        <div className='gms-page block-bg1 pt-md-3 pt-2 pb-5'>
            <Breadcrumb data={breadcrumb} />
            <GmsSharedBanner />
            <div className="container" style={{ fontFamily: "Roboto Condensed" }}>
                <div className="accordion accordion-flush py-md-60" id="accordionFlush">
                    <div className="accordion-item">
                        <h2 className="accordion-header d-lg-block d-none" id="heading1">
                            <button className={`accordion-button d-flex ${activeItems.includes('1') ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                onClick={() => handleToggle('1')}
                                style={{ background: "transparent" }}
                            >
                                <img src={num1} alt='' />
                                <div className='acc-btn-content d-flex align-items-center w-100' style={{ background: "rgba(34, 58, 94, 0.6)" }} >
                                    <div className='col-5 text-center border-end border-2 py-3'>
                                        <h4 className='mb-0'>{t('gms:gms_tab_heading1')}</h4>
                                    </div>
                                    <div className='col-7 d-flex px-4 py-1'>
                                        <h5 className='mb-0 t-color4 fw-normal text-justify me-5'>{t('gms:gms_tab_text1')}  <img src={arrows} alt='' /></h5>
                                        <img src={icon1} className="ms-5" alt='' />
                                    </div>
                                </div>
                            </button>
                        </h2>

                        {/* MOBILE VERSION */}
                        <h2 className="accordion-header d-lg-none d-block" id="heading1">
                            <button className="accordion-button d-block pt-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" style={{ background: "rgba(34, 58, 94, 0.6)", "border-radius": "0px 10px 10px 0px" }}>
                                <div className='acc-btn-content d-flex align-items-center justify-content-between w-100 p-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src={num1} width={50} height={50} alt='' />
                                        <h4 className='mb-0 ms-3'>{t('gms:gms_tab_heading1')}</h4>
                                    </div>
                                    <img src={icon1} width={50} height={50} alt='' />
                                </div>
                                <h5 className='mb-0 t-color4 fw-normal text-justify px-3 py-2'>{t('gms:gms_tab_text1')} <img src={arrows} alt='' /></h5>
                            </button>
                        </h2>
                        {/* MOBILE VERSION */}

                        <div id="collapse1" className={`accordion-collapse collapse ${activeItems.includes('1') ? 'show' : ''}`} data-bs-parent="#accordionFlush">
                            <div className="accordion-body">
                                <p className='t-color5 my-4'>
                                    {t('gms:gms_tab_content1')}
                                </p>

                                <Accordions />

                                <p className='t-color5 my-md-4 mt-3'>
                                    {t('gms:gms_tab_content1_2')}
                                </p>
                                <div className='d-flex flex-md-nowrap flex-wrap justify-content-center py-md-4 py-2'>
                                    <a href="https://sima.az/az" rel="noreferrer" target="_blank" className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <svg width="93" height="28" viewBox="0 0 93 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.6501 0H24.3707V4.27731H31.6501V0Z" fill="#1D204F"></path><path d="M4.2294 18.7818C6.31623 20.7743 9.0917 21.8843 11.977 21.8804C13.3463 21.8804 14.6427 21.4098 14.6427 20.4687C14.6427 19.5276 13.6334 19.0571 11.2547 18.6289C7.29269 17.9089 1.2367 16.9819 1.2367 10.9542C1.2367 6.88156 4.62466 3.17126 11.2194 3.17126C15.1462 3.17126 18.6777 4.28882 21.4163 6.52158L17.5248 11.6741C15.4356 10.0907 12.7322 9.29783 10.8218 9.29783C9.02196 9.29783 8.62434 9.94484 8.62434 10.5589C8.62434 11.5 9.59838 11.82 12.1535 12.2529C16.1179 12.9375 21.9927 14.0904 21.9927 19.6382C21.9927 24.9366 18.0636 27.9999 11.6147 27.9999C6.49746 27.9999 3.00126 26.4847 0.441467 24.179L4.2294 18.7818Z" fill="#1D204F"></path><path d="M24.3707 27.5744V6.12903H31.6501V27.5744H24.3707Z" fill="#1D204F"></path><path d="M35.5429 23.6381V27.5742H39.4814L35.5429 23.6381Z" fill="#1D204F"></path><path d="M55.1871 27.5738H62.4665V15.6571L55.1871 22.9365V27.5738Z" fill="#1D204F"></path><path d="M84.3698 27.5739L83.4287 24.4754H74.2012L73.2601 27.5739H65.3784L74.2435 3.53821H83.3957L92.2609 27.5739H84.3698ZM78.8196 9.95181L76.081 18.4217H81.5582L78.8196 9.95181Z" fill="#1D204F"></path><path d="M62.4678 0.00941123V10.1686L55.1884 17.4504L45.0645 27.5743L39.9755 22.4853L39.9778 22.4829L35.5429 18.048V7.87702L35.5453 7.87231L45.0669 17.3939L62.4584 0L62.4678 0.00941123Z" fill="#00B04E"></path></svg>
                                        {/* <small>{t('gms:gms_el_imza')}</small> */}
                                        <small>Rəqəmsal imza barədə məlumat</small>
                                    </a>
                                    <a href={imza_pdf} rel="noreferrer" target="_blank" className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={eimza} className="me-3" alt='' />
                                        <small>{t('gms:gms_el_imza')}</small>
                                    </a>
                                    <a href={imza_pdf} rel="noreferrer" target="_blank" className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={asanimza} className="me-3" alt='' />
                                        <small>{t('gms:gms_asan_imza')}</small>
                                    </a>
                                    <a href={yazili1} rel="noreferrer" target="_blank" className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={p2} className="me-3" alt='' />
                                        <small>{parse(t('gms:gms_yazili_m'))}</small>
                                    </a>
                                    <VideoModal title="Qeydiyyata dair video təlimat" link="https://www.youtube.com/embed/zYtdkSOF5Pk">
                                        <span className='btn-gms px-3 py-2 mx-2 my-2 my-md-0' style={{ height: '100%' }}>
                                            <img src={p1} className="me-3" alt='' />
                                            <small>{parse(t('gms:gms_video_m'))}</small>
                                        </span>
                                    </VideoModal>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header d-lg-block d-none" id="heading2">
                            <button className={`accordion-button d-flex ${activeItems.includes('2') ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                onClick={() => handleToggle('2')}
                                style={{ background: "transparent" }}
                            >
                                <img src={num2} alt='' />
                                <div className='acc-btn-content d-flex align-items-center w-100' style={{ background: "rgba(34, 58, 94, 0.7)" }} >
                                    <div className='col-5 text-center border-end border-2 py-3'>
                                        <h4 className='mb-0'>{t('gms:gms_tab_heading2')}</h4>
                                    </div>
                                    <div className='col-7 d-flex px-4 py-1'>
                                        <h5 className='mb-0 t-color4 fw-normal text-justify me-5'>{t('gms:gms_tab_text2')} <img src={arrows} alt='' /></h5>
                                        <img src={icon2} className="ms-5" alt='' />
                                    </div>
                                </div>
                            </button>
                        </h2>

                        {/* MOBILE VERSION */}
                        <h2 className="accordion-header d-lg-none d-block my-3" id="heading2">
                            <button className="accordion-button d-block pt-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" style={{ background: "rgba(34, 58, 94, 0.7)", "border-radius": "0px 10px 10px 0px" }}>
                                <div className='acc-btn-content d-flex align-items-center justify-content-between w-100 p-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src={num2} width={50} height={50} alt='' />
                                        <h4 className='mb-0 ms-3'>{t('gms:gms_tab_heading2')}</h4>
                                    </div>
                                    <img src={icon2} width={50} height={50} alt='' />
                                </div>
                                <h5 className='mb-0 t-color4 fw-normal text-justify px-3 py-2'>{t('gms:gms_tab_text2')} <img src={arrows} alt='' /></h5>
                            </button>
                        </h2>
                        {/* MOBILE VERSION */}

                        <div id="collapse2" className={`accordion-collapse collapse ${activeItems.includes('2') ? 'show' : ''}`} data-bs-parent="#accordionFlush">
                            <div className="accordion-body">
                                <p className='t-color5 my-md-4'>{parse(t('gms:gms_tab_content2'))}</p>

                                <div className='d-flex justify-content-center flex-md-nowrap flex-wrap pt-2'>
                                    <a href={yazili2} rel="noreferrer" target="_blank" className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={p2} className="me-3" alt='' />
                                        <small>{parse(t('gms:gms_ilkin_yazili'))}</small>
                                    </a>
                                    <a href="https://www.youtube.com/watch?v=FJH36-JXTWg" target='_blank' rel='noreferrer' className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={p1} className="me-3" alt='' />
                                        <small>{parse(t('gms:gms_ilkin_video'))}</small>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header d-lg-block d-none" id="heading3">
                            <button
                                className={`accordion-button d-flex ${activeItems.includes('3') ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                onClick={() => handleToggle('3')}
                                style={{ background: "transparent" }}
                            >
                                <img src={num3} alt='' />
                                <div className='acc-btn-content d-flex align-items-center w-100' style={{ background: "#4C5F7C" }} >
                                    <div className='col-5 text-center border-end border-2 py-3'>
                                        <h4 className='mb-0'>{t('gms:gms_tab_heading3')}</h4>
                                    </div>
                                    <div className='col-7 d-flex px-4 py-1'>
                                        <h5 className='mb-0 t-color4 fw-normal text-justify me-5'>{t('gms:gms_tab_text3')} <img src={arrows} alt='' /></h5>
                                        <img src={icon3} className="ms-5" alt='' />
                                    </div>
                                </div>
                            </button>
                        </h2>

                        {/* MOBILE VERSION */}
                        <h2 className="accordion-header d-lg-none d-block my-3" id="heading3">
                            <button className="accordion-button d-block pt-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" style={{ background: "#4C5F7C", "border-radius": "0px 10px 10px 0px" }}>
                                <div className='acc-btn-content d-flex align-items-center justify-content-between w-100 p-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src={num3} width={50} height={50} alt='' />
                                        <h4 className='mb-0 ms-3'>{t('gms:gms_tab_heading3')}</h4>
                                    </div>
                                    <img src={icon3} width={50} height={50} alt='' />
                                </div>
                                <h5 className='mb-0 t-color4 fw-normal text-justify px-3 py-2'>{t('gms:gms_tab_text3')} <img src={arrows} alt='' /></h5>
                            </button>
                        </h2>
                        {/* MOBILE VERSION */}

                        <div id="collapse3" className={`accordion-collapse collapse ${activeItems.includes('3') ? 'show' : ''}`} data-bs-parent="#accordionFlush">
                            <div className="accordion-body">
                                <p className='t-color5 my-md-4'>{parse(t('gms:gms_tab_content3'))}</p>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header d-lg-block d-none" id="heading4">
                            <button className={`accordion-button d-flex ${activeItems.includes('4') ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                onClick={() => handleToggle('4')}
                                style={{ background: "transparent" }}
                            >
                                <img src={num4} alt='' />
                                <div className='acc-btn-content d-flex align-items-center w-100' style={{ background: "rgba(34, 58, 94, 0.9)" }} >
                                    <div className='col-5 text-center border-end border-2 py-3'>
                                        <h4 className='mb-0'>{t('gms:gms_tab_heading4')}</h4>
                                    </div>
                                    <div className='col-7 d-flex px-4 py-1'>
                                        <h5 className='mb-0 t-color4 fw-normal text-justify me-5'>{t('gms:gms_tab_text4')} <img src={arrows} alt='' /></h5>
                                        <img src={icon4} className="ms-5" alt='' />
                                    </div>
                                </div>
                            </button>
                        </h2>

                        {/* MOBILE VERSION */}
                        <h2 className="accordion-header d-lg-none d-block my-3" id="heading4">
                            <button className="accordion-button d-block pt-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" style={{ background: "rgba(34, 58, 94, 0.9)", "border-radius": "0px 10px 10px 0px" }}>
                                <div className='acc-btn-content d-flex align-items-center justify-content-between w-100 p-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src={num4} width={50} height={50} alt='' />
                                        <h4 className='mb-0 ms-3'>{t('gms:gms_tab_heading4')}</h4>
                                    </div>
                                    <img src={icon4} width={50} height={50} alt='' />
                                </div>
                                <h5 className='mb-0 t-color4 fw-normal text-justify px-3 py-2'>{t('gms:gms_tab_text4')} <img src={arrows} alt='' /></h5>
                            </button>
                        </h2>
                        {/* MOBILE VERSION */}

                        <div id="collapse4" className={`accordion-collapse collapse ${activeItems.includes('4') ? 'show' : ''}`} data-bs-parent="#accordionFlush">
                            <div className="accordion-body">
                                <p className='t-color5 my-md-4'>{parse(t('gms:gms_tab_content4'))}</p>

                                <div className='d-flex flex-md-nowrap flex-wrap justify-content-center py-md-4 py-2'>
                                    <a href={yazili3} rel="noreferrer" target="_blank" className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={p2} className="me-3" alt='' />
                                        <small>{parse(t('gms:gms_satis_yazili'))}</small>
                                    </a>
                                    <a href='https://www.youtube.com/watch?v=WiHyJrrnQzc' target='_blank' rel='noreferrer' className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={p1} className="me-3" alt='' />
                                        <small>{parse(t('gms:gms_satis_video'))}</small>
                                    </a>
                                    <a href={yazili4} rel="noreferrer" target="_blank" className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={p2} className="me-3" alt='' />
                                        <small>{parse(t('gms:gms_gozleme_yazili'))}</small>
                                    </a>
                                    <a href='https://www.youtube.com/watch?v=n6kypNRSo5Y' target='_blank' rel='noreferrer' className='btn-gms px-3 py-2 mx-2 my-2 my-md-0'>
                                        <img src={p1} className="me-3" alt='' />
                                        <small>{parse(t('gms:gms_gozleme_video'))}</small>
                                    </a>
                                </div>

                                <div className="home-announcements block-bg1 pt-5 pb-4" style={{ fontFamily: "Helvetica" }}>
                                    <div className="container px-2">
                                        <div className="block-content">
                                            <h1 className="text text-center section-heading">{t('common:announcements')}</h1>
                                            <div className="mt-md-5 mt-sm-4 mt-3 mb-2 row justify-content-between">
                                                <Announcements />
                                            </div>
                                            <div className="btn-container d-flex justify-content-end mt-3">
                                                <Link to="/elektron-xidmetler/satis-elanlari" className="more-info-btn">
                                                    <small>{t('common:all_announcements')}</small>
                                                    <img className="ms-2" src={arrow_right} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header d-lg-block d-none" id="heading5">
                            <button className={`accordion-button d-flex ${activeItems.includes('5') ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                onClick={() => handleToggle('5')}
                                style={{ background: "transparent" }}
                            >
                                <img src={num5} alt='' />
                                <div className='acc-btn-content d-flex align-items-center w-100' style={{ background: "#223A5E" }} >
                                    <div className='col-5 text-center border-end border-2 py-3'>
                                        <h4 className='mb-0'>{t('gms:gms_tab_heading5')}</h4>
                                    </div>
                                    <div className='col-7 d-flex px-4 py-1'>
                                        <h5 className='mb-0 t-color4 fw-normal text-justify me-5'>{t('gms:gms_tab_text5')} <img src={arrows} alt='' /></h5>
                                        <img src={icon5} className="ms-5" alt='' />
                                    </div>
                                </div>
                            </button>
                        </h2>

                        {/* MOBILE VERSION */}
                        <h2 className="accordion-header d-lg-none d-block my-3" id="heading5">
                            <button className="accordion-button d-block pt-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" style={{ background: "#223A5E", "border-radius": "0px 10px 10px 0px" }}>
                                <div className='acc-btn-content d-flex align-items-center justify-content-between w-100 p-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src={num5} width={50} height={50} alt='' />
                                        <h4 className='mb-0 ms-3'>{t('gms:gms_tab_heading5')}</h4>
                                    </div>
                                    <img src={icon5} width={50} height={50} alt='' />
                                </div>
                                <h5 className='mb-0 t-color4 fw-normal text-justify px-3 py-2'>{t('gms:gms_tab_text5')} <img src={arrows} alt='' /></h5>
                            </button>
                        </h2>
                        {/* MOBILE VERSION */}

                        <div id="collapse5" className={`accordion-collapse collapse ${activeItems.includes('5') ? 'show' : ''}`} data-bs-parent="#accordionFlush">
                            <div className="accordion-body">
                                <p className='t-color5 my-md-4'>{parse(t('gms:gms_tab_content5'))}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GMS 
