// import { useEffect, useState } from 'react';
import { PieChart } from '../charts/PieChart9'
import Table9 from '../tables/Table9'

const Template9 = ({ data, templateData }) => {
    // const [info, setInfo] = useState();
    // useEffect(() => {
    //     setInfo(data.rows.find(row => row.ageCategory === 'TOTAL'))
    // }, [data]);

    return (
        <div className='row chart-container'>
            <div className='chart-header col-md-3'>
                <h6 className="text-center">{data.title}</h6>
            </div>
            <div className='chart-content col-md-9'>
                <PieChart apiData={data} legend={true} />
                <Table9 apiData={templateData} />
            </div>
        </div>
    )
}

export default Template9 
