// import React from "react"
// import ReactResizeDetector from "react-resize-detector"
import { RotatePhoneAlert } from "./rotate_phone_alert"

// interface IProps {
//   children: React.ReactNode
// }

// export const RotateDetector: React.FC<IProps> = ({ children }: IProps) => {
//   return (
//     <ReactResizeDetector handleWidth handleHeight>
//       {({ width, height }: { width: number; height: number }) => {
//         const aspectRatio = width / height
//         const shouldRotate = width < 850 && aspectRatio < 1

//         if (shouldRotate) {
//           return <RotatePhoneAlert />
//         }

//         return children
//       }}
//     </ReactResizeDetector>
//   )
// }

import React, { useState, useEffect } from 'react';
// import { useResizeDetector } from 'react-resize-detector';

interface IProps {
  children: React.ReactNode;
}

export const RotateDetector: React.FC<IProps> = ({ children }) => {
  // const { width, height, ref } = useResizeDetector();
  const [shouldRotate, setShouldRotate] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width, innerHeight: height } = window;
      const aspectRatio = width / height;
      setShouldRotate(width < 850 && aspectRatio < 1);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="position-relative">
      {shouldRotate ? <RotatePhoneAlert /> : children}
    </div>
  );
};
