import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useWindowSize } from 'react-use'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

export function BarHorStacked({ apiData }) {
    const [labels, setLabels] = useState()
    const [dataset, setDataset] = useState([])
    const { width } = useWindowSize();
    const [screenSize, setScreenSize] = useState(window.clientWidth);
    const fontSize = screenSize < 400 ? 4 : screenSize < 490 ? 6 : screenSize < 784 ? 7 : screenSize < 992 ? 10 : screenSize < 1201 ? 11 : 13;

    const getType = type => {
        // console.log(typeof parseInt(type))
        if (type === "cash") {
            return "Öz vəsaiti hesabına"
        }
        else if (type === "loan") {
            return "İpoteka krediti hesabına"
        }
        else if (typeof parseInt(type) === 'number') {
            return type + " otaqlı"
        }
        else return type
    }

    const colors = ['#223a5e', '#f79647', '#c0504e', '#8ba2c2']

    const splitLabelForChart = label => {
        const words = label.split(' ');
        const lineLimit = screenSize < 400 ? 45 : screenSize < 785 ? 55 : screenSize < 1200 ? 60 : 70;
        const lines = [];

        let line = '';
        let currentWordIdx = 0;

        while (currentWordIdx < words.length) {
            if (line.length + words[currentWordIdx].length < lineLimit) {
                line += `${words[currentWordIdx]} `;
                currentWordIdx++;

                if (currentWordIdx === words.length) {
                    lines.push(line);
                }
            } else {
                if (line.length) {
                    lines.push(line);
                    line = '';
                }

                if (words[currentWordIdx].length >= lineLimit) {
                    lines.push([words.currentWord]);
                    currentWordIdx++;
                }
            }
        }

        return lines;
    }

    useEffect(() => {
        setLabels(apiData && apiData?.rows?.map(row => splitLabelForChart((row.category.concat(' (', row.stats.reduce((acc, obj) => acc + obj.value, 0), ' nəfər)')))))

        let tempRows = []
        if (apiData && apiData.rows) {
            let typeLength = []
            apiData.rows.forEach(element => {
                element.stats?.forEach(el => {
                    if (!typeLength.includes(el.type)) typeLength.push(el.type)
                })
            })
            typeLength = typeLength.sort()
            for (let i = 0; i < typeLength.length; i++) {
                let tempType = []
                apiData.rows.forEach(element => {
                    const subElement = element.stats.find(el => el.type == typeLength[i])
                    if (subElement) tempType.push(subElement)
                    else {
                        tempType.push({
                            type: typeLength[i],
                            percentage: 0,
                        })
                    }
                })
                tempRows.push(tempType)
            }
        }

        // console.log("tempRows :", tempRows)
        setDataset(tempRows.map(d => {
            return {
                data: d.map(dd => dd?.percentage),
                backgroundColor: colors[tempRows.indexOf(d)],
                stack: "STACK0",
                label: getType(d[0].type)
            }
        }))
    }, [apiData])

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: true,
                color: '#fff', // Label color
                formatter: function (value, context) {
                    // Access percentageData from the dataset
                    return context.dataset.data[context.dataIndex].data;
                },
            },
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        size: 16
                    }
                }
            },
            tooltip: {
                callbacks: {
                    title: function(tooltipItems)  {
                        const match = tooltipItems[0].label.match(/\d+(?=\D*$)/);
                        var totalValue = parseInt(match[0]);
                        var partialValue = Math.round(totalValue * (tooltipItems[0].raw / 100));
                        const parts = tooltipItems[0].label.split(new RegExp(totalValue + "(?!.*" + totalValue + ")"));
                        const resultingTooltipTitle = parts[0] + partialValue + parts[1];

                        let splittedParts = resultingTooltipTitle.split(" ,");
                        let result = splittedParts.join(" ");

                        return result;
                    },
                    
                    label: function (tooltipItem) {
                        return tooltipItem.formattedValue + '%';
                    }
                }
            }
        },
        barThickness: screenSize < 400 ? 10 : screenSize < 768 ? 15 : 30,
        responsive: true,
        scales: {
            x: {
                ticks: {
                    callback: (val) => {
                        return val + '%';
                    },
                    font: {
                        size: fontSize
                    }
                }
            },
            y: {
                ticks: {
                    autoSkip: false,
                    font: {
                        size: fontSize
                    },
                    // stepSize: 3,
                    min: 0
                }
            }
        },
        legendCallback: function (chart) {
            var text = [];
            text.push('<ul class="' + chart.id + '-legend">');
            for (var i = 0; i < chart.data.datasets.length; i++) {
                text.push('<li><span style="background-color:' + chart.data.datasets[i].backgroundColor + '"></span>');
                if (chart.data.datasets[i].label) {
                    text.push(chart.data.datasets[i].label);
                }
                text.push('</li>');
            }
            text.push('</ul>');

            return text.join('');
        }
    }

    const data = {
        labels,
        datasets: dataset
    }

    return (
        <Bar options={options} data={data} />
    )
}
