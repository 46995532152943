import Announcements from './Announcements'
import GmsSharedBanner from './GmsSharedBanner'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../../components/Breadcrumb'


const AllAnnouncements = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/elektron-xidmetler/gms',
            style: "pe-none",
            title: t('common:e_services'),
            isActive: false
        },
        {
            id: 2,
            link: '/elektron-xidmetler/gms',
            title: t('gms:gms'),
            isActive: false
        },
        {
            id: 3,
            link: 'https://kommunal.mida.gov.az/search',
            title: t('common:kommunal'),
            isActive: true
        }
    ]

    return (
        <div className='block-bg1 pt-md-3  pb-5'>
            <Breadcrumb data={breadcrumb} />
            <GmsSharedBanner />
            <div className="container" style={{ fontFamily: "Roboto Condensed" }}>
                <div className="home-announcements block-bg1 pt-4 pb-3">
                    <div className="container px-2">
                        <div className="block-content">
                            <h1 className="text text-center section-heading mt-4">{t('common:announcements')}</h1>
                            <div className="mt-md-5 mt-sm-4 mt-3 row justify-content-between">
                                <Announcements paginate={true} announcementsOnPage={15} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllAnnouncements 
