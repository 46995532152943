import Table17 from '../tables/Table17'

const Template17 = ({ data }) => {
    return (
        <>
         <div className='row chart-container'>
            <Table17 apiData={data} />
         </div>
        </>
    )
}

export default Template17 
