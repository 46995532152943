// libraries
import dayjs from 'dayjs'
import DOMPurify from "dompurify"
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// components & functions
import { apiClient } from '../../core/api_client'
import l from "../../core/localize"
import Breadcrumb from '../../components/Breadcrumb'

// assets
import arrow_right from "../../../assets/img/utils/arrow_right.svg"
import fb from "../../../assets/img/socials/facebook_light.svg"
import tw from "../../../assets/img/socials/twitter_light.svg"

const Tender = () => {
    const sanitize = DOMPurify.sanitize
    const [tender, setTender] = useState({})
    const id = useParams().id
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    useEffect(() => {
        apiClient.get('/tenders/' + id).then(res => setTender(res.data))
    }, [id])

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:about'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/nizamname',
            title: t('agentlik:order'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: false
        },
        {
            id: 4,
            link: '/agentlik/mida-mmc',
            title: t('agentlik:mida_mmc'),
            isActive: true
        },
        {
            id: 5,
            link: '/agentlik/beynelxalq-emekdasliq',
            title: t('agentlik:international'),
            isActive: false
        },
        {
            id: 6,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        }
    ]

    return (
        <div className="block-bg1 pt-md-3 pt-5 pb-5">
            <Breadcrumb data={breadcrumb} />
            <div className='container px-md-120'>
                <div className='block-bg-white px-md-120 px-5 py-5 text-center rounded-4'>
                    <h4 className="heading mb-2 pb-3 t-color5 fw-700">{l(tender, "name", currentLang)}</h4>
                    <div className='px-md-60'>
                        <div className='py-2 fs-6 t-color4 text-center mx-auto' style={{ background: "#223A5E", width: "20%" }}>
                            {dayjs(tender.date).format('DD.MM.YYYY')}
                        </div>
                        <div className="text-inf mt-4" dangerouslySetInnerHTML={{ __html: sanitize(l(tender, "content", currentLang)) }}></div>
                        <div className='d-flex justify-content-between'>
                            <b></b>
                            <div className='d-flex justify-content-end'>
                                <div className="btn-share fb-share-button" data-href={window.location.href} data-layout="" data-size=""><a target="_blank" rel="noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href) + "&amp src=sdkpreparse"} className="fb-xfbml-parse-ignore t-color4"><img alt='facebook' src={fb} />{t('common:share')}</a></div>
                                <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className='btn-share ms-4' data-show-count="false"><img src={tw} alt='twitter' />Tweet</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-container d-flex justify-content-start mt-3">
                    <Link className="more-info-btn py-2 ps-2 pe-4" to="/agentlik/mida-mmc">
                        <img
                            className='ms-2'
                            src={arrow_right}
                            style={{ transform: "rotate(-180deg)" }}
                            alt=""
                        />
                        <small className='ms-2'>{t('common:go_back')}</small>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Tender 
