import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppReduxState } from "../../../../../redux/store";
import { IAsyncData, Maybe } from "../../../../../core/models";
import { floorRedux } from "../state/state";
import { IFloor, IFloorApartment, EApartmentStatus } from "../../data/entities";
import { ROUTES } from "../../../../../routes";
import { ShapeViewer } from "../../../../../components/editor/shape_viewer/shape_viewer";
import { isSuccess } from "../../../../../core/redux";
import { BackImage } from "../../../../../components/back_image/back_image";
import { Page } from "../../../../../components/page/page";
import styled from "styled-components";
import { InfoPanel } from "../../../../../components/info_panel/info_panel";
// import { Copyright } from "../../../../../components/copyright/copyright";
import { ApartmentInfo } from "../components/apartment_info";
// import { MainButton } from "../../../../../components/main_button";
// import { ApartmentSearchModal } from "../../../project/ui/components/apartment_search_modal";
import { ApartmentList } from "../components/apartment_list";
import { FloorTopNav } from "../components/floor_top_nav";
import { useTranslation } from "react-i18next";
// import { BackLink } from "../../../../components/back_link";
import { Compass } from "../../../../../components/compass";
import arrow_right from "../../../../../../assets/img/utils/arrow_right.svg";
import { projectRedux } from "../../../project/ui/state/state";
import { IProject } from "../../../project/data/entities";
import { RotateDetector } from "../../../../../components/rotate_detector";

const BREAK_POINT_MD = 700;
const BREAK_POINT_SM = 700;

// const Grid = styled.div`
//   flex: 1;
//   display: grid;
//   grid-template-columns: 1fr 2fr 1fr;
//   grid-gap: 20px;

//   @media (max-width: ${BREAK_POINT_MD}px) {
//     grid-template-columns: repeat(3, 1fr);
//   }

//   @media (max-width: ${BREAK_POINT_SM}px) {
//     grid-template-columns: 1fr;
//   }
// `;

const Left = styled.div`
  width: 100%;
  height: 100%;
  justify-self: center;

  @media (max-width: ${BREAK_POINT_MD}px) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: ${BREAK_POINT_SM}px) {
    order: 1;
  }
`;

const Center = styled.div`
  align-self: start;
  padding: 10px 1rem;

  @media (max-width: ${BREAK_POINT_MD}px) {
    padding: 0 20px;
    grid-column: 2 / span 2;
  }

  @media (max-width: ${BREAK_POINT_SM}px) {
    order: 0;
    grid-column: unset;
  }
`;

const ViewerWrapper = styled.div`
  max-width: 100%;
  max-height: 80vh;
  width: 65%;
  height: auto;

  @media screen and (max-width: 1100px) {
    width: 70% !important;
  }

   @media screen and (max-width: 800px) {
    width: 50% !important;
  }
`;

//transform: translate(0%, -25%);
const Right = styled.div`
  display: flex;
  align-self: end;
  justify-content: center;

  @media (max-width: ${BREAK_POINT_MD}px) {
    grid-column: 1 / span 3;
    justify-self: center;
  }

  @media (max-width: ${BREAK_POINT_SM}px) {
    grid-column: unset;
    order: 2;
  }
`;

// const BottomRow = styled.div`
//   margin-top: auto;
// `;

// const ButtonWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-bottom: 20px;
// `;

const StyledFloorInfoPanel = styled(InfoPanel)`
  width: 100% !important;
  height: 100%;

  @media (max-width: ${BREAK_POINT_MD}px) {
    border-radius: 23px;
  }
`;

export const FloorDetailPage: React.FC<any> = (props: any) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.slice(0, 2);
  const { projectId, sectorId, floorId, onPageChange } = props;

  const dispatch = useDispatch();
  const [hoveredApartment, setHoveredApartment] =
    useState<Maybe<IFloorApartment>>(undefined);

  // const [isApartmentSearchOpen, setIsApartmentSearchOpen] = useState(false);

  // function openApartmentSearchModal() {
  //   setIsApartmentSearchOpen(true);
  // }

  // function closeApartmentSearchModal() {
  //   setIsApartmentSearchOpen(false);
  // }

  useEffect(() => {
    dispatch(floorRedux.actions.getDetail(sectorId, floorId));
  }, [dispatch, sectorId, floorId]);
  const floorDetailBranch = useSelector<IAppReduxState, IAsyncData<IFloor>>(
    (state) => state.floor.details
  );

  useEffect(() => {
    dispatch(projectRedux.actions.getDetail(projectId));
  }, [dispatch, projectId]);
  const projectSectorBranch = useSelector<IAppReduxState, IAsyncData<IProject>>(
    (state) => state.project.details
  );

  return (
    <RotateDetector>
      <Page>
        <>
          {isSuccess(floorDetailBranch) && (
            <>
              <div className="project-portal d-flex justify-content-between position-relative" style={{ height: '80vh' }}>
                <Left style={{ width: '28%' }}>
                  <StyledFloorInfoPanel title={`Giriş ${floorDetailBranch.data?.sectorName}`} address={projectSectorBranch.data?.sectors.find(x => x.id == sectorId)?.address[currentLang]}>
                    <FloorTopNav
                      floorDetailBranch={floorDetailBranch}
                      projectId={projectId}
                      sectorId={sectorId}
                      onPageChange={onPageChange}
                    />
                    <div
                      className="my-1 my-lg-3 mx-auto"
                      style={{
                        width: "170px",
                        height: "6px",
                        background: "#223A5E",
                        borderRadius: "22px"
                      }}
                    ></div>
                    {floorDetailBranch.data?.apartments && (
                      <ApartmentList
                        hoveredApartment={hoveredApartment}
                        apartments={floorDetailBranch.data?.apartments}
                        onApartmentHovered={setHoveredApartment}
                        onPageChange={(params) => {
                          onPageChange({
                            ...params,
                            param2: projectId,
                            param3: sectorId,
                            param4: floorId,
                          })
                        }}
                      />
                    )}
                  </StyledFloorInfoPanel>
                </Left>
                <Center className="d-flex justify-content-center align-items-center position-relative p-0 w-75 h-100">
                  <BackImage />
                  <ViewerWrapper>
                    <ShapeViewer
                      svgStyles={{
                        maxHeight: "70vh",
                        marginLeft: '30px'
                      }}
                      hoveredShape={hoveredApartment?.shape}
                      preserveAspectRatio="xMidYMid"
                      image={floorDetailBranch.data?.image}
                      shapes={floorDetailBranch.data?.apartments?.map(
                        (apartment) => apartment.shape
                      )}
                      hoverColor="rgba(0, 38, 51, 0.5)"
                      renderShapeLink={() => ""}
                      renderShapeOnClickLink={(shape) => {
                        const apartment =
                          floorDetailBranch.data?.apartments?.find(
                            (apartment) => apartment.shape.id === shape.id
                          );
                        onPageChange({
                          route: ROUTES.apartment,
                          param1: apartment?.id,
                          param2: projectId,
                          param3: sectorId,
                          param4: floorId,
                        });
                      }}
                      renderHovered={(shape) => {
                        const apartment =
                          floorDetailBranch.data?.apartments?.find(
                            (apartment) => apartment.shape.id === shape.id
                          );
                        return (
                          apartment && <ApartmentInfo apartment={apartment} />
                        );
                      }}
                      renderShapeOverlayText={(shape) => {
                        const apartment =
                          floorDetailBranch.data?.apartments?.find(
                            (apartment) => apartment.shape.id === shape.id
                          );

                        switch (apartment?.status) {
                          case EApartmentStatus.SOLD:
                            return ["SATILIB"];
                          case EApartmentStatus.GOVERNMENT:
                            return ["DÖVLƏT", "SİFARİŞİ"];
                          case EApartmentStatus.INVESTOR:
                            return ["INVESTOR"];
                          case EApartmentStatus.AVAILABLE:
                          default:
                            return null;
                        }
                      }}
                      onHoveredChanged={(shape) => {
                        const apartment =
                          floorDetailBranch.data?.apartments?.find(
                            (apartment) => apartment.shape.id === shape?.id
                          );
                        setHoveredApartment(apartment);
                      }}
                    />
                    <div className="btn-container d-flex justify-content-start position-absolute bottom-10 start-0 z-2 mt-2 ms-3">
                      <div className="more-info-btn p-2" onClick={() => onPageChange({
                        route: ROUTES.sector,
                        param1: projectId,
                        param2: sectorId,
                      })}>
                        <img
                          className=""
                          src={arrow_right}
                          style={{ transform: "rotate(-180deg)" }}
                          alt=""
                        />
                        <small>{t('common:go_back')}</small>
                      </div>
                    </div>
                  </ViewerWrapper>
                </Center>

                <Right className="mb-2 me-3 position-absolute end-0 bottom-10">
                  <Compass compass={floorDetailBranch.data?.compass} />
                </Right>
              </div>
            </>
          )}

          {/* <BottomRow>
            <ButtonWrapper>
              <MainButton onClick={openApartmentSearchModal}>
                {t("common:apartmentSearch")}
              </MainButton>
            </ButtonWrapper>
            <Copyright />
          </BottomRow> */}

          {/* <ApartmentSearchModal
            show={isApartmentSearchOpen}
            onHide={closeApartmentSearchModal}
          /> */}
        </>
      </Page>

      {/* <BackLink href={`${ROUTES.sector}/${sectorId}`} /> */}
    </RotateDetector>
  );
};
