// libraries
import { useTranslation } from 'react-i18next'

// components
import Breadcrumb from '../../components/Breadcrumb'

// assets
import metbuat from '../../../assets/img/metbuat.svg'

const Contact2 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/elaqe/elaqe-vasiteleri',
            title: t('contact:contact'),
            isActive: false
        },
        {
            id: 2,
            link: '/elaqe/metbuat-xidmeti',
            title: t('contact:press'),
            isActive: true
        },
        {
            id: 3,
            link: '/elaqe/en-cox-verilen-suallar',
            title: t('contact:faq'),
            isActive: false
        }
    ]

    return (
        <div className="contact-page block-bg1 pt-lg-3 pb-3">
            <Breadcrumb data={breadcrumb} />

            <div className='container mt-5'>
                <div className='d-flex align-items-center flex-column'>
                    <h2>{t('contact:press_h')}</h2>
                    <div style={{ background: "#8CA2C2", height: "5px", width: "35%" }} className="rounded-5 mt-md-3 mt-2"></div>
                </div>
                <div className='mx-auto col-md-8 col-sm-10 col-12 mt-4 mb-5'>
                    <img src={metbuat} className="w-100" alt='' />
                </div>
            </div>
        </div>
    )
}

export default Contact2
