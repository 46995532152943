// libraries
import parse from 'html-react-parser'
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"

// components
import Breadcrumb from '../../components/Breadcrumb'

// assets
import img from '../../../assets/img/sadiqsadiqov.png'
import img2 from '../../../assets/img/elshad-m.png'
import img3 from '../../../assets/img/chingiz-q.png'

const Management2 = () => {
    const { managementId } = useParams()
    const [manager, setManager] = useState({})
    const { t } = useTranslation()

    const breadcrumbInit = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: false
        },
    ]

    const [breadcrumb, setBreadcrumb] = useState(breadcrumbInit)
    const management_info = [
        {
            id: 0,
            position: t('agentlik:m_sadiq_position'),
            fullname: t('agentlik:m_sadiq_fullname'),
            img: img,
            about: parse(t('agentlik:m_sadiq_about'))
        },
        {
            id: 1,
            position: t('agentlik:m_chingiz_position'),
            fullname: t('agentlik:m_chingiz_fullname'),
            img: img3,
            about: parse(t('agentlik:m_chingiz_about'))
        },
        {
            id: 2,
            position: t('agentlik:m_elshad_position'),
            fullname: t('agentlik:m_elshad_fullname'),
            img: img2,
            about: parse(t('agentlik:m_elshad_about'))
        }
    ]

    useEffect(() => {
        setManager(management_info[managementId])
    }, [managementId])

    useEffect(() => {
        if (manager && manager.position) {
            setBreadcrumb([...breadcrumbInit, { id: 3, link: "/agentlik/rehberlik/etrafli/" + managementId, title: manager.position, isActive: true }])
        }
    }, [manager])

    return (
        <div className="management-page block-bg1 pt-lg-3 pb-md-5 py-0">
            <Breadcrumb data={breadcrumb} />

            <div className='container pb-5 mt-5 mt-sm-5 mt-md-5 '>
                <div className="block-bg-white rounded-5 py-md-5 d-flex align-items-center flex-column">
                    <h5 className="t-color5">{manager.position}</h5>
                    <h2 className="t-color5">{manager.fullname}</h2>

                    {manager.img === null ?
                        (<div className="col-sm-6 mx-auto my-3" style={{ backgroundColor: "#858181", height: "428px", borderRadius: "22px" }}></div>)
                        : (
                            <div className="col-sm-6 mx-auto my-3">
                                <img className="rounded-4 w-100" src={manager.img} alt=''></img>
                            </div>
                        )}

                    <small className="mt-2 px-md-120 mx-md-5 mx-1 text-inf">
                        {manager.about}
                    </small>
                </div>
            </div>
        </div>
    )
}

export default Management2 
