import React from "react";
import { ISectorEtrance } from "../../data/entities";
import styled from "styled-components";
import { IProjectSector } from "../../../project/data/entities";

const Item = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 700;
  // width: 100px;
  margin: 0;
`;

const Seperator = styled.p`
  // text-align: center;
  // flex: 1;
  margin: 0 0.5rem;
`;

const Number = styled.p`
  width: 25px;
  font-size: 20px;
  font-weight: 700;
  // text-align: right;
  margin: 0;
`;

interface IProps {
  etrance?: ISectorEtrance;
  data: IProjectSector;
}

export const EtranceInfo: React.FC<IProps> = ({ etrance, data }: IProps) => {
  return (
    !!etrance ?
      <div key={etrance?.name}>
        <Item className="my-2 my-lg-3">
          <Text>1 otaqlı studio mənzil</Text>
          <Seperator>-</Seperator>
          <Number>{etrance?.studioCount}</Number>
        </Item>
        <Item className="my-2 my-lg-3">
          <Text>1 otaqlı mənzil</Text>
          <Seperator>-</Seperator>
          <Number>{etrance?.oneRoomCount}</Number>
        </Item>
        <Item className="my-2 my-lg-3">
          <Text>2 otaqlı mənzil</Text>
          <Seperator>-</Seperator>
          <Number>{etrance?.twoRoomCount}</Number>
        </Item>
        <Item className="my-2 my-lg-3">
          <Text>3 otaqlı mənzil</Text>
          <Seperator>-</Seperator>
          <Number>{etrance?.threeRoomCount}</Number>
        </Item>
        <Item className="my-2 my-lg-3">
          <Text>4 otaqlı mənzil</Text>
          <Seperator>-</Seperator>
          <Number>{etrance?.fourRoomCount}</Number>
        </Item>
      </div>
      :
      <>
        <div>
          <Item className="my-2 my-lg-3">
            <Text>1 otaqlı studio mənzil</Text>
            <Seperator>-</Seperator>
            <Number>{data.studioCount}</Number>
          </Item>
          <Item className="my-2 my-lg-3">
            <Text>1 otaqlı mənzil</Text>
            <Seperator>-</Seperator>
            <Number>{data.oneRoomCount}</Number>
          </Item>
          <Item className="my-2 my-lg-3">
            <Text>2 otaqlı mənzil</Text>
            <Seperator>-</Seperator>
            <Number>{data.twoRoomCount}</Number>
          </Item>
          <Item className="my-2 my-lg-3">
            <Text>3 otaqlı mənzil</Text>
            <Seperator>-</Seperator>
            <Number>{data.threeRoomCount}</Number>
          </Item>
          <Item className="my-2 my-lg-3">
            <Text>4 otaqlı mənzil</Text>
            <Seperator>-</Seperator>
            <Number>{data.fourRoomCount}</Number>
          </Item>
        </div>
      </>
  );
};