import Footer from "./Footer"
import Navbar from "./Navbar"
import scroll_arrow from '../../../assets/img/utils/scroll.svg'
import { useEffect, useState } from "react"

const Layout = ({ children }) => {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 220) {
        setScroll(true)
      }
      else {
        setScroll(false)
      }
    })
  }, [window])

  return (
    <>
      <Navbar />
      <div id="main" className="position-relative">
        {children}
        <img src={scroll_arrow} className={"position-fixed cursor " + (scroll ? "d-sm-block" : "d-none")} onClick={() => window.scrollTo(0, 0)} style={{ right: "5%", bottom: "5%", zIndex: 100, transition: "all .5s ease-in-out" }} />
      </div>
      <Footer />
    </>
  )
}

export default Layout 
