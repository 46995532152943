// libraries
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"

// functions & components
import { apiClient } from '../../core/api_client'
import l from "../../core/localize"

// assets
import fb from "../../../assets/img/socials/facebook_light.svg"
import insta from "../../../assets/img/socials/instagram_light.svg"
import tw from "../../../assets/img/socials/twitter_light.svg"
import yt from "../../../assets/img/socials/youtube_light.svg"

const Footer = () => {
	const [categories, setCategories] = useState([])
	const { t, i18n } = useTranslation()
	const currentLang = i18n.language.slice(0, 2)

	useEffect(() => {
		apiClient.get('/projectCategories').then(res => setCategories(res.data))
	}, [])

	return (
		<footer className="pt-5 pb-2">
			<div className="container">
				<div className="footer-content justify-content-between row">
					<div className="footer-col col-md-3 col-sm-12 col-12 d-flex flex-column">
						<div className="d-flex flex-column mt-md-0 mt-3">
							<p className="footer-heading-link text-uppercase">
								{t('agentlik:agentlik')}
							</p>
							<Link to="/agentlik/haqqimizda" className="footer-link">
								{t('agentlik:about')}
							</Link>
							<Link to="/agentlik/nizamname" className="footer-link">
								{t('agentlik:order')}
							</Link>
							<Link to="/agentlik/rehberlik" className="footer-link">
								{t('agentlik:management')}
							</Link>
							<Link to="/agentlik/mida-mmc" className="footer-link">
								{t('agentlik:mida_mmc')}
							</Link>
							<Link to="/agentlik/beynelxalq-emekdasliq" className="footer-link">
								{t('agentlik:international')}
							</Link>
							<Link to="/agentlik/hesabatlar" className="footer-link">
								{t('agentlik:audits')}
							</Link>
						</div>
					</div>

					<div className="footer-col col-md-3 col-sm-12 col-12 d-flex flex-column">
						<div className="d-flex flex-column mt-md-0 mt-4">
							<p className="footer-heading-link text-uppercase">
								{t('legislation:legislation')}
							</p>
							<Link to="/qanunvericilik/ar-konstitusiyasi" className="footer-link">
								{t('legislation:constitution')}
							</Link>
							<Link to="/qanunvericilik/ar-mecelleri" className="footer-link">
								{t('legislation:codes')}
							</Link>
							<Link to="/qanunvericilik/ar-qanunlari" className="footer-link">
								{t('legislation:laws')}
							</Link>
							<Link to="/qanunvericilik/ar-prezidentinin-fermanlari" className="footer-link">
								{t('legislation:orders')}
							</Link>
							<Link to="/qanunvericilik/strateji-yol-xeriteleri" className="footer-link">
								{t('legislation:strategic')}
							</Link>
						</div>
					</div>

					<div className="footer-col col-md-3 col-sm-12 col-12 d-flex flex-column">
						<div className="d-flex flex-column mt-md-0 mt-4">
							<p className="footer-heading-link">
								{t('projects:projects')}
							</p>
							<Link to="/layiheler" className="footer-link">
								{t('projects:all_projects')}
							</Link>
							{categories.map(category =>
								<Link to={`/layiheler/${category.id}`} className="footer-link" key={category.id}>{l(category, "name", currentLang)}</Link>
							)}
						</div>

						<div className="d-flex flex-column mt-md-90 mt-4 pt-md-2 pt-3">
							<p className="footer-heading-link">
								{t('common:news_media')}
							</p>
							<Link to="/xeberler-ve-media/bizim-xeberler" className="footer-link">
								{t('common:our_news')}
							</Link>
							<Link to="/xeberler-ve-media/multimedia" className="footer-link">
								{t('common:multimedia')}
							</Link>
						</div>
					</div>

					<div className="footer-col col-md-3 col-sm-12 col-12 d-flex flex-column">
						<div className="d-flex flex-column mt-4 mt-md-0">
							<p className="footer-heading-link">
								{t('common:e_services')}
							</p>
							<Link to="/elektron-xidmetler/gms" className="footer-link">
								{t('common:gms')}
							</Link>
							<a href="https://kommunal.mida.gov.az/search" className="footer-link">
								{t('common:kommunal')}
							</a>
						</div>
						<div className="d-flex flex-column mt-4 pt-md-2 pt-3" >
							<p className="footer-heading-link">
								{t('common:contact_us')}
							</p>
							<Link to="/elaqe/elaqe-vasiteleri" className="footer-link">
								{t('contact:contact')}
							</Link>
							<Link to="/elaqe/metbuat-xidmeti" className="footer-link">
								{t('contact:press')}
							</Link>
							<Link to="/elaqe/en-cox-verilen-suallar" className="footer-link">
								{t('contact:faq')}
							</Link>
						</div>
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-between w-100 flex-wrap flex-sm-nowrap mt-4 mt-md-0">
					<div className="d-flex">
						<a href="https://www.facebook.com/guzeshtlimenzil" className="me-4" target="_blank" rel="noreferrer">
							<img src={fb} width="36" height="36" alt="facebook" />
						</a>
						<a href="https://www.instagram.com/mida.az" className="me-4" target="_blank" rel="noreferrer">
							<img src={insta} width="36" height="36" alt="instagram" />
						</a>
						<a href="https://twitter.com/guzeshtlimenzil" className="me-4" target="_blank" rel="noreferrer">
							<img src={tw} width="36" height="36" alt="twitter" />
						</a>
						<a href="https://www.youtube.com/channel/UC3xOGx3unafEd79Oe_VFpog" className="me-4" target="_blank" rel="noreferrer">
							<img src={yt} width="36" height="36" alt="youtube" />
						</a>
					</div>
					<div className="d-flex justify-content-center align-items-center flex-column py-3 mt-4 mt-md-0">
						<small className="text my-2">© {new Date().getFullYear()} {t('common:rights')} </small>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer 
