import { useEffect } from 'react' 
import { useTranslation } from 'react-i18next' 
import Breadcrumb from '../../components/Breadcrumb' 
import { Link } from 'react-router-dom' 
import img1 from '../../../assets/img/photogallery.png' 
import img2 from '../../../assets/img/videogallery.png' 
import arrow1 from '../../../assets/img/utils/wide-arrow-r.svg'
import arrow2 from '../../../assets/img/utils/wide-arrow-l.svg'

const Multimedia = () => {
    const { t } = useTranslation() 

    const breadcrumb = [
        {
            id: 1,
            link: '/xeberler-ve-media/bizim-xeberler',
            title: t('common:our_news'),
            isActive: false
        },
        {
            id: 2,
            link: '/xeberler-ve-media/multimedia',
            title: t('common:multimedia'),
            isActive: true
        }
    ]

    useEffect(() => {
        document.getElementById('root').style.background = "#F5F5F7" 
    }, []) 

    return (
        <div className="multimedia pb-2">
            <Breadcrumb data={breadcrumb} />

            <div className='container pt-2 mt-md-5 mb-3'>
                <Link to="/xeberler-ve-media/fotogalereya" className="multimedia-link row my-4 my-md-0">
                    <div className='col-md-6 col-12 d-flex align-items-center justify-content-end me-4'>
                        <h1 className='big-link'>{t('common:photo_gallery')}</h1>
                        <img src={arrow1} height={43} width={18} className="ms-5 d-none d-md-block" alt="" />
                    </div>
                    <div className='col-md-5 col-12'>
                        <img src={img1} alt="FOTO QALEREYA" className='w-100' />
                    </div>
                </Link>

                <Link to="/xeberler-ve-media/videogalereya" className="multimedia-link row my-5 my-md-0" style={{ transform: "translateY(-15%)" }}>
                    <div className='col-md-5 col-12'>
                        <img src={img2} alt={t('common:video_gallery')} className='w-100' />
                    </div>
                    <div className='col-md-6 col-12 d-flex align-items-center justify-content-start ms-md-4'>
                        <img src={arrow2} height={43} width={18} className="me-5 d-none d-md-block" alt="" />
                        <h1 className='big-link'>{t('common:video_gallery')}</h1>
                    </div>
                </Link>
            </div>
        </div>
    ) 
}

export default Multimedia 
