import { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Map = ({ projects }) => {
  const [map, setMap] = useState(null);
  const { t, i18n } = useTranslation()
  // const currentLang = i18n.language.slice(0, 2)

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1Ijoib2ZlbGlhcnYiLCJhIjoiY20xejJ1dWhhMDJpZzJpczhzMnJvZjdmMyJ9.yXFL1Nfhvw2sld9yPx8tLA';

    const mapInstance = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [47.912, 40.173],
      zoom: 7
    });

    mapInstance.on('load', function () {

      // YASAMAL
      mapInstance.addSource('300004', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': { 'name': 'Yasamal' },
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  49.79977011680603,
                  40.37505913073455
                ],
                [
                  49.79809641838074,
                  40.37865535090195
                ],
                [
                  49.79605793952942,
                  40.378516409596635
                ],
                [
                  49.79570388793945,
                  40.378254872244604
                ],
                [
                  49.79263544082641,
                  40.37557405585068
                ],
                [
                  49.793879985809326,
                  40.37529616024506
                ],
                [
                  49.79377269744873,
                  40.37470767164974
                ],
                [
                  49.795886278152466,
                  40.37457689570838
                ],
                [
                  49.79619741439819,
                  40.37541876138882
                ],
                [
                  49.79977011680603,
                  40.37505913073455
                ]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        'id': 'yasamal_zone',
        'type': 'fill',
        'source': '300004',
        'paint': {
          'fill-color': '#FFB900',
          'fill-opacity': 0.6
        }
      });

      // YASAMAL 2
      mapInstance.addSource('100001', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Yasamal 2',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [49.799553, 40.375167],
                [49.799958, 40.374261],
                [49.801283, 40.3726],
                [49.79655, 40.371217],
                [49.795703, 40.371117],
                [49.795442, 40.371231],
                [49.795317, 40.372239],
                [49.79595, 40.372408],
                [49.795986, 40.374986],
                [49.796394, 40.37535],
                [49.796722, 40.375417],
                [49.799553, 40.375167],
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'yasamal2_zone',
        type: 'fill',
        source: '100001',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // HOVSAN
      mapInstance.addSource('300001', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Hövsan',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [50.07352423, 40.38197864],
                [50.07453881, 40.37879324],
                [50.0704948, 40.3784629],
                [50.06645078, 40.37813241],
                [50.06793182, 40.3812023],
              ],
            ],
          },
        },
      });

      mapInstance.addLayer({
        id: 'hovsan_zone',
        type: 'fill',
        source: '300001',
        layout: {},
        paint: {
          'fill-color': '#FFB900',
          'fill-opacity': 0.6,
        }
      });

      // HOVSAN 2
      mapInstance.addSource('300005', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Hövsan 2',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [50.06905529, 40.38357140],
                [50.06939525, 40.38407454],
                [50.06862447, 40.38517829],
                [50.06719587, 40.38620828],
                [50.07254375, 40.38906553],
                [50.07357973, 40.38406756]
              ],
            ],
          },
        },
      });

      mapInstance.addLayer({
        id: 'hovsan2_zone',
        type: 'fill',
        source: '300005',
        layout: {},
        paint: {
          'fill-color': '#FFB900',
          'fill-opacity': 0.6,
        }
      });

      // GANJA
      mapInstance.addSource('1', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Gəncə',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [46.3734139004542, 40.6810265342984],
                [46.3742446585469, 40.6817574097155],
                [46.3742850245481, 40.6817288771780],
                [46.3743322240385, 40.6817679069188],
                [46.3742905810941, 40.6817978149348],
                [46.3745255001300, 40.6820044799208],
                [46.3763833856622, 40.6836044654830],
                [46.3764227777085, 40.6835712054319],
                [46.3778879647178, 40.6824249445868],
                [46.3763784647100, 40.6809224076735],
                [46.3757931880354, 40.6812644679365],
                [46.3747570182367, 40.6802371685540],
                [46.3736806204938, 40.6808591989018]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'ganja_zone',
        type: 'fill',
        source: '1',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // SUMQAYIT
      mapInstance.addSource('300006', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Sumqayit',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [49.6682352594550, 40.5808268944006],
                [49.6676206103736, 40.5802381706275],
                [49.6674874372517, 40.5804175433209],
                [49.6674522196378, 40.5804649812415],
                [49.6671852029198, 40.5808246371885],
                [49.6671032701495, 40.5809350009047],
                [49.6674236710952, 40.5810782939724],
                [49.6674015992666, 40.5811074971604],
                [49.6671499864924, 40.5814403952431],
                [49.6674729143422, 40.5815791319992],
                [49.6675716416650, 40.5816215480122]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'sumqait_zone',
        type: 'fill',
        source: '300006',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // SUMQAYIT 2
      mapInstance.addSource('200002', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Sumqayit 2',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [49.67442, 40.60473],
                [49.67477, 40.60492],
                [49.67533, 40.60517],
                [49.67552, 40.60524],
                [49.67572, 40.60525],
                [49.67574, 40.60522],
                [49.67647, 40.60421],
                [49.67658, 40.60402],
                [49.67536, 40.60346],
                [49.67518, 40.60372]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'sumqait_zone2',
        type: 'fill',
        source: '200002',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // BINAQADI
      mapInstance.addSource('300011', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Binaqadi',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [49.815406, 40.454590],
                [49.816947, 40.451461],
                [49.816807, 40.451379],
                [49.816562, 40.451205],
                [49.814027, 40.451215],
                [49.813536, 40.451634],
                [49.813154, 40.452549],
                [49.812687, 40.453616],
                [49.813039, 40.453719],
                [49.812941, 40.453943]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'binaqadi_zone',
        type: 'fill',
        source: '300011',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // SHIRVAN
      mapInstance.addSource('300015', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Shirvan',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [48.958163, 39.963442],
                [48.961312, 39.962696],
                [48.960191, 39.960226],
                [48.957078, 39.960982]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'shirvan_zone',
        type: 'fill',
        source: '300015',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // JABRAIL
      mapInstance.addSource('300009', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Jabrail',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [47.024024, 39.402344],
                [47.024388, 39.399679],
                [47.023479, 39.399542],
                [47.023280, 39.399482],
                [47.023162, 39.399439],
                [47.022041, 39.398819],
                [47.021841, 39.398742],
                [47.021490, 39.398649],
                [47.021484, 39.398829],
                [47.021627, 39.399358],
                [47.021633, 39.399556],
                [47.021569, 39.399756],
                [47.021480, 39.399901],
                [47.021122, 39.400367],
                [47.021057, 39.400530],
                [47.021040, 39.400747],
                [47.021080, 39.400917],
                [47.021225, 39.401113],
                [47.021380, 39.401254],
                [47.021998, 39.401757],
                [47.022262, 39.402041],
                [47.022443, 39.402290]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'jabrail_zone',
        type: 'fill',
        source: '300009',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

    });

    setMap(mapInstance);
  }, []);

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;

    if (map) {
      // Hide all regions first
      const allRegions = ['300001', '300004', '100001', '1', '200002', '300006', '300011', '300009', '300015'];
      allRegions.forEach((region) => {
        const regionElement = document.getElementById(region);
        if (regionElement) {
          regionElement.style.display = 'none';
        }
      });

      // Show selected region and fly to it
      if (selectedRegion !== '-') {
        const regionElement = document.getElementById(selectedRegion);
        if (regionElement) {
          regionElement.style.display = 'block';
        }

        const regionCoordinates = {
          '300004': [49.798, 40.376], //yasamal
          '300001': [50.072, 40.382], //hovsan
          '300005': [50.072, 40.382], //hovsan2
          '100001': [49.798, 40.373], //yasamal2
          '1': [46.377, 40.682], //ganja
          '200002': [49.668, 40.581], //sumqait2
          '300006': [49.67518, 40.60372], //sumqait
          '300011': [49.814027, 40.451215], //binaqadi
          '300009': [47.022, 39.4], //jabrail
          '300015': [48.960191, 39.960226] // shirvan
        };

        const { center, zoom } = {
          center: regionCoordinates[selectedRegion] || [47.912, 40.173],
          zoom: selectedRegion === '-' ? 7 : 14,
        };

        map.flyTo({
          center,
          zoom,
          bearing: 0,
          speed: 0.5,
          curve: 2,
          easing: (t) => t
        });
      } else {
        map.flyTo({
          center: [47.912, 40.173],
          zoom: 7,
          bearing: 0,
          speed: 0.5,
          curve: 2,
          easing: (t) => t
        });
      }
    }
  };

  return (
    <>
      {/* <h5 className='t-color1 fw-bolder' style={{ fontSize: "22px" }}>{t('projects:projects_list')}</h5> */}
      <div id="projects-map" className='position-relative'>
        <div className='proj-buttons w-100 d-flex justify-content-between position-absolute mt-5 px-3 top-0 left-0 z-3'>
          <select className="region-selector cursor ps-4" onChange={handleRegionChange}>
            <option value="-">Layihə</option>
            {projects.map(proj =>
              <option value={proj.id}>{proj.name_az}</option>
            )}
          </select>
          <div className='d-flex position-relative proj-buttons mt-4 pe-4'>
            <Link to='/layiheler-xarite' className='btn btn-secondary me-3'>Xəritə üzrə</Link>
            <Link to='/layiheler' className='btn btn-secondary me-3'>Siyahı üzrə</Link>
          </div>
        </div>

        <div id="map" className='mb-0' style={{ width: '100%', height: '700px' }}></div>

        <div className='mapinfo-popup' id="300004" style={{ display: 'none' }}>Yasamal Region Info</div>
        <div className='mapinfo-popup' id="300001" style={{ display: 'none' }}>Hovsan Region Info</div>
        <div className='mapinfo-popup' id="300005" style={{ display: 'none' }}>Hovsan 2 Region Info</div>
        <div className='mapinfo-popup' id="100001" style={{ display: 'none' }}>Yasamal 2 Region Info</div>
        <div className='mapinfo-popup' id="1" style={{ display: 'none' }}>Ganja Region Info</div>
        <div className='mapinfo-popup' id="300006" style={{ display: 'none' }}>Sumqait Region Info</div>
        <div className='mapinfo-popup' id="200002" style={{ display: 'none' }}>Sumqait 2 Region Info</div>
        <div className='mapinfo-popup' id="300011" style={{ display: 'none' }}>Binaqadi Region Info</div>
        <div className='mapinfo-popup' id="300011" style={{ display: 'none' }}>Binaqadi Region Info</div>
        <div className='mapinfo-popup' id="300015" style={{ display: 'none' }}>Shirvan Region Info</div>
        <div className='mapinfo-popup' id="300009" style={{ display: 'none' }}>Jabrayil Region Info</div>
      </div>
    </>
  );
};

export default Map;