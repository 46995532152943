import React from "react"
import styled from "styled-components"
import rotateImg from "../../assets/img/rotate.png"
// import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #8CA2C2;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Img = styled.img`
  width: 250px;
  margin-bottom: 20px;
`

export const RotatePhoneAlert: React.FC = () => {
  // const { t } = useTranslation()

  return (
    <Wrapper>
      <Img src={rotateImg} alt="rotate" />
      <p className="t-color4">Zəhmət olmazsa, cihazınızı çevirin</p>
    </Wrapper>
  )
}
