import img from '../../../assets/img/utils/link.svg'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../components/Breadcrumb'

const Constitution1 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/qanunvericilik/ar-konstitusiyasi',
            title: t('legislation:constitution'),
            isActive: true
        },
        {
            id: 2,
            link: '/qanunvericilik/ar-mecelleri',
            title: t('legislation:codes'),
            isActive: false
        },
        {
            id: 3,
            link: '/qanunvericilik/ar-qanunlari',
            title: t('legislation:laws'),
            isActive: false
        },
        {
            id: 4,
            link: '/qanunvericilik/ar-prezidentinin-fermanlari',
            title: t('legislation:orders'),
            isActive: false
        },
        {
            id: 5,
            link: '/qanunvericilik/strateji-yol-xeriteleri',
            title: t('legislation:strategic'),
            isActive: false
        }
    ]

    return (
        <div className="constitution-page block-bg1 pt-md-3 pt-4 pb-5">
            <Breadcrumb data={breadcrumb} />

            <div className='container'>
                <div className='mt-3 mb-5'>
                    <table className="table table-borderless w-100">
                        <thead>
                            <tr>
                                <th className="col-2">{t('legislation:doc_no')}</th>
                                <th className="col-2">{t('legislation:accept_date')}</th>
                                <th className="col-6">{t('legislation:doc_name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-2">00</td>
                                <td className="col-2">12.11.1995</td>
                                <td className="col-6"><a href='https://e-qanun.az/framework/897' target="_blank" rel='noreferrer' className='mb-0'>{t('legislation:constitution_ar')}</a> <img src={img} alt='' /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Constitution1 
