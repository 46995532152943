import { useEffect, useState } from "react";

const Table4 = ({ apiData }) => {
    const [tableRows, setTableRows] = useState();

    useEffect(() => {
        const sorted = apiData.rows.sort((a, b) => b.count - a.count)
        const total = sorted?.filter(item => item.name === "TOTAL")
        const rows = sorted?.filter(item => item.name !== "TOTAL")
        setTableRows(rows?.concat(total))
    }, [apiData]);

    return (
        <>
            {apiData && (
                <table id="table4" className="table table-bordered table-responsive mb-2">
                    <thead>
                        <tr>
                            <th colSpan={4} className="py-1">Mənzil əldə etmiş vətəndaşların əhali qrupları üzrə bölgüsü</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows && tableRows.map(row =>
                            <tr key={tableRows.indexOf(row)}>
                                <td colSpan={2} className={`text-${row.name === "TOTAL" ? "center" : "start"}`}>{row.name === "TOTAL" ? "CƏMİ" : row.name}</td>
                                <td colSpan={2}>{row.count}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default Table4
