// libraries
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// components & functions
import Breadcrumb from '../../components/Breadcrumb'

// assets
import img1 from '../../../assets/img/utils/audit-icon1.svg'
import img2 from '../../../assets/img/utils/audit-icon2.svg'

// HESABATLAR
const About3 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:about'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/nizamname',
            title: t('agentlik:order'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: false
        },
        {
            id: 4,
            link: '/agentlik/mida-mmc',
            title: t('agentlik:mida_mmc'),
            isActive: false
        },
        {
            id: 5,
            link: '/agentlik/beynelxalq-emekdasliq',
            title: t('agentlik:international'),
            isActive: false
        },
        {
            id: 6,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: true
        }
    ]

    const audit_card = [
        {
            id: 1,
            title: t('agentlik:audits_sales'),
            img: img1,
            link: '/agentlik/hesabatlar/satis',
            isUpdated: false
        },
        {
            id: 2,
            title:  t('agentlik:audits_finance'),
            img: img2,
            link: '/agentlik/hesabatlar/maliyye',
            isUpdated: false
        },
        // {
        //     id: 3,
        //     title: "Layihələrin statistikası",
        //     img: img3,
        //     link: '/agentlik/hesabatlar/lahiyelerin-statistikasi'
        // },
        // {
        //     id: 4,
        //     title: "E-xidmət statistikası",
        //     img: img4,
        //     link: '/agentlik/hesabatlar/exidmet-statistikasi'
        // }
    ]

    return (
        <div className="block-bg1 pt-lg-3 pb-md-0 py-0 ">
            <Breadcrumb data={breadcrumb} className={'mb-0 h-0'} />

            <div className='bg-picture1 pt-3 '>
                <div className='container '>
                    <div className='audit-info mt-4 row d-flex justify-content-center pt-60 pb-80 mx-auto'>
                        {audit_card.map(item =>
                            <Link to={item.link} className='audit-card my-4 mx-3 rounded-4 p-5 col-lg-5 col-md-10 col-10 flex-md-nowrap flex-wrap justify-content-center justify-content-md-between' key={item.id}>
                                <h2 className='my-3 t-color4'>{item.title}</h2>
                                <img src={item.img} className="audit-symbol mt-auto" alt="pdf" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About3 