const Table9 = ({ apiData }) => {

    function search(nameKey, myArray) {
        for (let i = 0; i < myArray?.length; i++) {
            if (myArray[i].roomsCount === nameKey) {
                return myArray[i].value;
            }
        }
        return 0;
    }

    return (
        <>
            {apiData && (
                <table id="table9" className='table table-bordered table-responsive'>
                    <thead>
                        <tr>
                           <th colSpan={11}> Mənzil əldə etmiş vətəndaşların yaş üzrə bölgüsü</th>
                        </tr>
                        <tr>
                            <th rowSpan={3} colSpan={6} className="table-category-header">Yaş</th>
                        </tr>
                        <tr>
                            <th colSpan={4}>
                                Otaq sayı
                            </th>
                            <th rowSpan={2} className="p-4">
                                Cəmi
                            </th>
                        </tr>
                        <tr>
                            <th>1 otaqlı</th>
                            <th>2 otaqlı</th>
                            <th>3 otaqlı</th>
                            <th>4 otaqlı</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiData.rows?.map(row => {
                            let totalRoomsCount = 0;
                            row.rooms?.forEach(room => {
                                totalRoomsCount += room.value;
                            });
                            return (
                                <tr key={row.ageCategory}>
                                    <td colSpan={6} className={`text-${row.ageCategory === "TOTAL" ? "center" : "start"}`}>{row.ageCategory === "TOTAL" ? "CƏMİ" : row.ageCategory}</td>
                                    <td>{search(1, row.rooms)}</td>
                                    <td>{search(2, row.rooms)}</td>
                                    <td>{search(3, row.rooms)}</td>
                                    <td>{search(4, row.rooms)}</td>
                                    <td>{totalRoomsCount}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default Table9;
