const Template1 = ({ data }) => {
    return (
        <div className='temp1-stats row my-4'>
            {data.totalAnnouncedApartments &&
                <div className="d-flex flex-column align-items-center justify-content-center col" style={{ borderRight: "5px solid #223a5e" }}>
                    <h4 className="text-center" >Barəsində elan verilmiş <br /> mənzillərin sayı</h4>
                    <h1>{data.totalAnnouncedApartments}</h1>
                </div>
            }
            {data.closedOrders &&
                <div className="d-flex flex-column align-items-center justify-content-center col" style={{ borderRight: "5px solid #223a5e" }}>
                    <h4 className="text-center" >Alqı-satqısı rəsmiləşdirilmiş <br /> mənzillərin sayı</h4>
                    <h1>{data.closedOrders}</h1>
                </div>
            }
            {data.waitingsCount &&
                <div className="d-flex flex-column align-items-center justify-content-center col">
                    <h4 className="text-center" >Gözləmə qaydasında seçilmiş <br /> mənzillərin sayı</h4>
                    <h1>{data.waitingsCount}</h1>
                </div>
            }
        </div>
    )
}

export default Template1