import fb from "../../../assets/img/socials/facebook_light.svg"
import insta from "../../../assets/img/socials/instagram_light.svg"
import tw from "../../../assets/img/socials/twitter_light.svg"
import yt from "../../../assets/img/socials/youtube_light.svg"
import arrow from "../../../assets/img/utils/arrow-down.svg"
// import foto from "../../../assets/img/utils/foto-light.svg" 
import { useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { apiClient } from '../../core/api_client'
import { useTranslation } from "react-i18next"
import Breadcrumb from "../../components/Breadcrumb"
import l from "../../core/localize"

const VideoGallery = () => {
    const match = useRouteMatch()
    const { id } = match.params
    const [gallery, setGallery] = useState([])
    const [galleryLength, setGalleryLength] = useState([])
    const [activeVideo, setActiveVideo] = useState({})
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)
    const [numComponents, setNumComponents] = useState(12)
    const increment = 9

    const breadcrumb = [
        {
            id: 1,
            link: '/xeberler-ve-media/bizim-xeberler',
            title: t('common:our_news'),
            isActive: false
        },
        {
            id: 2,
            link: '/xeberler-ve-media/multimedia',
            title: t('common:multimedia'),
            isActive: false
        },
        {
            id: 3,
            link: '/xeberler-ve-media/videogalereya',
            title: t('common:video_gallery'),
            isActive: true
        }
    ]

    useEffect(() => {
        document.getElementById('root').style.background = "#F5F5F7"
    }, [])

    useEffect(() => {
        apiClient.get('/multimediaGalleries?type=video')
            .then(res => {
                setGallery(res.data)
                setActiveVideo(getItemById(res.data, id))
                setGalleryLength(res.data.length)
            })
    }, [])

    useEffect(() => {
        setActiveVideo(getItemById(gallery, id))
    }, [id])

    function getItemById(arr, id) {
        if (id) {
            const item = arr.find(x => x.id == id)
            if (item) {
                return item
            }
        }

        return arr[0]
    }

    function getVideoImgUrl(url) {
        const regex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        const match = url.match(regex)
        var videoId = match && match[2].length === 11 ? match[2] : null
        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    }

    return (
        <div className="multimedia pb-2">
            <Breadcrumb data={breadcrumb} />

            <div className='container pt-2 mt-5'>
                <div className='t-color4 text-center w-75 mx-auto'>
                    <h4>{activeVideo && l(activeVideo, "name", currentLang)}</h4>
                    <h5 className='fw-normal mt-3 mb-5'>{activeVideo?.date?.slice(0, 10) ?? ""}</h5>
                </div>
                {/* <div className='d-flex justify-content-end'>
                    <Link to="/xeberler-ve-media/fotogalereya" className='text-center t-color4 mb-1' style={{ transform: "translateX(-14rem)" }}><img src={foto} alt="video" className='me-2' />Foto izlə</Link>
                </div> */}
                <div className='d-flex mb-4'>
                    <iframe className="col-8 mx-auto w-sm-100" style={{ aspectRatio: "16/9" }} src={activeVideo?.video_link} title={activeVideo?.video_link}></iframe>
                </div>
                <div className="d-flex justify-content-center mb-5">
                    <a href="https://www.facebook.com/guzeshtlimenzil" className="me-4" target="_blank" rel="noreferrer">
                        <img src={fb} alt="facebook" />
                    </a>
                    <a href="https://www.instagram.com/mida.az" className="me-4" target="_blank" rel="noreferrer">
                        <img src={insta} alt="instagram" />
                    </a>
                    <a href="https://twitter.com/guzeshtlimenzil" className="me-4" target="_blank" rel="noreferrer">
                        <img src={tw} alt="twitter" />
                    </a>
                    <a href="https://www.youtube.com/channel/UC3xOGx3unafEd79Oe_VFpog" className="me-4" target="_blank" rel="noreferrer">
                        <img src={yt} alt="youtube" />
                    </a>
                </div>

                <div className='row gx-5 mt-4'>
                    {gallery.slice(1, numComponents).map(item => item.id != activeVideo.id &&
                        <Link className='col-lg-3 my-4' key={item.id} to={`/xeberler-ve-media/videogalereya/${item.id}`}>
                            <div className="">
                                <img src={getVideoImgUrl(item.video_link)} className="w-100" alt="..." />
                                <div className="card-body py-3">
                                    <p className="news-title mt-2 t-color4" style={{ fontSize: "14px" }}>{l(item, "name", currentLang)}</p>
                                </div>
                            </div>
                        </Link>
                    )}
                </div>

                {numComponents < galleryLength ?
                    <div className='d-flex justify-content-end pb-5'>
                        <button className='btn-show-more' onClick={() => setNumComponents(numComponents + increment)}>
                            {t('common:show_more')} <img src={arrow} className="ms-2" alt="" />
                        </button>
                    </div> : null}
            </div>
        </div>
    )
}

export default VideoGallery 
