import { useEffect, useState } from 'react'
import Table10 from '../tables/Table10'

const Template10 = ({ data, projectName }) => {
    const [info, setInfo] = useState();
    useEffect(() => {
        setInfo(data.rows.find(row => row.category === 'TOTAL'))
    }, [data]);

    return (
        <>
            {info?.total > 0 &&
                <>
                    <div className='row chart-container'>
                        <div className='chart-header col-md-3'>
                            <h6 className="text-center">{projectName ? projectName + "ndə " + data.title.toLowerCase() : data.title}</h6>
                        </div>
                        <div className='chart-content col-md-9 px-0'>
                            <Table10 apiData={data} />
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Template10 
