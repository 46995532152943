import Table2 from '../tables/Table2'

const Template2 = ({ data, projectName }) => {
    return (
        <>
            <Table2 apiData={data} projectName={projectName} />
        </>
    )
}

export default Template2 
