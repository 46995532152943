
const Table17 = ({ apiData }) => {


    return (
        <>
            {apiData && (
                <table id="table17" className="table table-bordered table-responsive mb-0">
                    <thead>
                        <tr>
                            <th>Gözləmə qaydasında 1-ci sırada seçim edənlər</th>
                            <th>Gözləmə qaydasında 2-ci sırada seçim edənlər</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>{apiData.firstLineCount}</td>
                            <td>{apiData.secondLineCount}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colSpan={12} className="total">CƏMİ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={12}>{apiData.total}</td>
                        </tr></tbody>
                </table>
            )}
        </>

    )
}

export default Table17
