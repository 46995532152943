import styled from "styled-components";
import { IArea } from "../../data/entities";
import Square from "../../../../../components/square";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const orderSize = 24;
const Order = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${orderSize}px;
  height: ${orderSize}px;
  border: 1px solid white;
  border-radius: ${orderSize / 2}px;
  margin-right: 15px;
`;

const OrderText = styled.p`
  margin: 0;
`;

const Text = styled.p`
  margin: 0;
`;

export interface IProps {
  area: IArea;
}

export function AreaItem({ area }: IProps) {
  return (
    <Wrapper>
      <Order>
        <OrderText>{area.order}</OrderText>
      </Order>
      <Text>
        {area.name.az} -
        <Square square={area.area} />
      </Text>
    </Wrapper>
  );
}
