import { PieChart } from '../charts/PieChart7_1'

const Template7_1 = ({ data, projectName }) => {
  return (
    <>
      <PieChart apiData={data} projectName={projectName} legend={true} />
    </>
  )
}

export default Template7_1 