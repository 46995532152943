// libraries
import { Link } from "react-router-dom"
import parse from 'html-react-parser'
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"

// components
import { apiClient } from "../../core/api_client"
import Breadcrumb from "../../components/Breadcrumb"

// assets
import pdf15 from '../../../assets/files/satis/satis15.pdf'

const SatishUzreHesabatlar = () => {
    const { t } = useTranslation()
    const [reportsDB, setReportsDB] = useState()

    useEffect(() => {
        apiClient.get("/reports?report_type=SALES")
            .then(res => {
                setReportsDB(res.data)
                console.log(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/hesabatlar/satis',
            title: t('agentlik:audits_sales'),
            isActive: true
        }
    ]

    const audit_card = [
        // sale 15 is added manually before map() of those components (temporarily)
        {
            id: 12,
            name_az: t('common:sale') + " №14",
            title_az: "Yasamal Yaşayış Kompleksinin ikinci mərhələsində 15.09.2022 və 19.09.2022-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 1333,
            name_az: t('common:sale') + " №13",
            title_az: "Gəncə Yaşayış Kompleksində 23.12.2021 və 27.12.2021-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 1222,
            name_az: t('common:sale') + " №12",
            title_az: "Sumqayıt şəhərində inşa olunmuş binalarda 17.08.2021 və 20.08.2021-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 1110,
            name_az: t('common:sale') + " №11",
            title_az: "Hövsan Yaşayış Kompleksində 01.07.2021 və 06.07.2021-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 1001,
            name_az: t('common:sale') + " №10",
            title_az: "Hövsan Yaşayış Kompleksində 21.04.2021 və 26.04.2021-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 9999,
            name_az: t('common:sale') + " №9",
            title_az: "Hövsan Yaşayış Kompleksində 18.12.2020 və 22.12.2020-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 8888,
            name_az: t('common:sale') + " №8",
            title_az: "Hövsan Yaşayış Kompleksində 10.09.2020 və 14.09.2020-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 7777,
            name_az: t('common:sale') + " №7",
            title_az: "Hövsan Yaşayış Kompleksində 30.06.2020 və 03.07.2020-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 6666,
            name_az: t('common:sale') + " №6",
            title_az: "Yasamal Yaşayış Kompleksində 25.12.2019 və 27.12.2019-cu il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 5555,
            name_az: t('common:sale') + " №5",
            title_az: "Yasamal Yaşayış Kompleksində 16.10.2019 və 18.10.2019-cu il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 4444,
            name_az: t('common:sale') + " №4",
            title_az: "Yasamal Yaşayış Kompleksində 12.07.2019 və 14.07.2019-cu il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 3333,
            name_az: t('common:sale') + " №3",
            title_az: "Yasamal Yaşayış Kompleksində 29.04.2019-cu il tarixində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 2222,
            name_az: t('common:sale') + " №2",
            title_az: "Yasamal Yaşayış Kompleksində 25.01.2019-cu il tarixində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        },
        {
            id: 1111,
            name_az: t('common:sale') + " №1",
            title_az: "Yasamal Yaşayış Kompleksində 05.09.2018-ci il tarixində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
        }
    ]

    return (
        <div className="block-bg1 px-2 px-md-0 ">
            <Breadcrumb data={breadcrumb} className="my-0 pt-5" style={{ height: 0 }} />
            <div className='bg-picture3'>
                <div className='container'>

                    <div className='row pt-60 pb-120'>
                        {reportsDB && reportsDB.map(item =>
                            <Link to={'/agentlik/hesabatlar/satis/' + item.id} className='audit-card flip-card text-center rounded-4 px-3 my-3 mx-2 ms-0 w-md-31' style={{ backgroundColor: "#223A5E" }} key={item.id}>
                                <div className="flip-card-inner">
                                    <div className='flip-card-back'>
                                        <p className='py-md-5 t-color4 mb-0'>{parse(item.title_az)}</p>
                                    </div>
                                    <div className='flip-card-front p-md-3'>
                                        <h2 className='my-auto t-color4 text-uppercase py-md-5' style={{ transform: "rotate(0)", fontSize: "2rem" }}>{item.name_az}</h2>
                                    </div>
                                </div>
                            </Link>
                        )}

                        <div className='audit-card flip-card text-center rounded-4 px-3 my-3 mx-2 ms-0 w-md-31' style={{ backgroundColor: "#223A5E" }}>
                            <div className="flip-card-inner">
                                <div className='flip-card-back'>
                                    <a href={pdf15} rel="noreferrer" target="_blank" className='py-md-5 t-color4 mb-0'>Yasamal Yaşayış Kompleksinin ikinci mərhələsində, Hövsan Yaşayış Kompleksinin ikinci mərhələsində və Gəncə Yaşayış Kompleksində 29.07.2023 və 03.08.2023-cü il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar</a>
                                </div>

                                <div className='flip-card-front p-md-3'>
                                    <h2 className='my-auto t-color4 text-uppercase py-md-5' style={{ transform: "rotate(0)", fontSize: "2rem" }}>{t('common:sale') + " №15"}</h2>
                                </div>
                            </div>
                        </div>

                        {audit_card.map(item =>
                            <Link to={'/agentlik/hesabatlar/satis/' + item.id} className='audit-card flip-card text-center rounded-4 px-3 my-3 mx-2 ms-0 w-md-31 ' style={{ backgroundColor: "#223A5E" }} key={item.id}>
                                <div className="flip-card-inner">
                                    <div className='flip-card-back'>
                                        <p className='py-md-5 t-color4 mb-0'>{parse(item.title_az)}</p>
                                    </div>

                                    <div className='flip-card-front p-md-3'>
                                        <h2 className='my-auto t-color4 text-uppercase py-md-5' style={{ transform: "rotate(0)", fontSize: "2rem" }}>{item.name_az}</h2>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SatishUzreHesabatlar 