import { useTranslation } from 'react-i18next'
import img from '../../../assets/img/utils/link.svg'
import Breadcrumb from '../../components/Breadcrumb'

const Constitution2 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/qanunvericilik/ar-konstitusiyasi',
            title: t('legislation:constitution'),
            isActive: false
        },
        {
            id: 2,
            link: '/qanunvericilik/ar-mecelleri',
            title: t('legislation:codes'),
            isActive: true
        },
        {
            id: 3,
            link: '/qanunvericilik/ar-qanunlari',
            title: t('legislation:laws'),
            isActive: false
        },
        {
            id: 4,
            link: '/qanunvericilik/ar-prezidentinin-fermanlari',
            title: t('legislation:orders'),
            isActive: false
        },
        {
            id: 5,
            link: '/qanunvericilik/strateji-yol-xeriteleri',
            title: t('legislation:strategic'),
            isActive: false
        }
    ]

    const constitution = [
        {
            id: 1,
            no: " ",
            date: "30.06.2009",
            title: "Mənzil Məcəlləsi",
            link: "https://e-qanun.az/framework/46955"
        },
        {
            id: 2,
            no: " ",
            date: "28.12.1999",
            title: "Mülki Məcəlləsi",
            link: "https://e-qanun.az/framework/46944"
        },
        {
            id: 3,
            no: " ",
            date: "29.06.2012",
            title: "Şəhərsalma və Tikinti Məcəlləsi",
            link: "https://e-qanun.az/framework/46958"
        },
        {
            id: 4,
            no: " ",
            date: "25.06.1999",
            title: "Torpaq Məcəlləsi",
            link: "https://e-qanun.az/framework/46942"
        },
        {
            id: 5,
            no: " ",
            date: "11.07.2000",
            title: "Vergi Məcəlləsi",
            link: "https://e-qanun.az/framework/46948"
        }
    ]

    return (
        <div className="constitution-page block-bg1 pt-md-3 pt-4 py-1">
            <Breadcrumb data={breadcrumb} />

            <div className='container'>
                <div className='mt-3 mb-5'>
                    <table className="table table-borderless w-100">
                        <thead>
                            <tr>
                                <th className="col-2">{t('legislation:doc_no')}</th>
                                <th className="col-2">{t('legislation:accept_date')}</th>
                                <th className="col-6">{t('legislation:doc_name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {constitution.map(item =>
                                <tr>
                                    <td className="col-2">{item.no}</td>
                                    <td className="col-2">{item.date}</td>
                                    <td className="col-6"><a href={item.link} target="_blank" rel='noreferrer' className='mb-0'>{item.title}</a> <img src={img} alt='' /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Constitution2 
