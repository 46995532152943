import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppReduxState } from "../../../../../redux/store";
import { IAsyncData } from "../../../../../core/models";
import { projectRedux } from "../state/state";
import { IProject, EProjectSectorStatus } from "../../data/entities";
import { ROUTES } from "../../../../../routes";
import { ShapeViewer } from "../../../../../components/editor/shape_viewer/shape_viewer";
import { isLoading } from "../../../../../core/redux";
import styled from "styled-components";
// import { Copyright } from "../../../../../components/copyright/copyright";
// import Container from "react-bootstrap/Container";
// import Col from "react-bootstrap/Col";
// import { MainButton } from "../../../../../components/main_button";
import { SectorInfo } from "../components/sector_info";
import { ApartmentSearchModal } from "../components/apartment_search_modal";
import { RotateDetector } from "../../../../../components/rotate_detector";

const Wrapper = styled.div`
  // height: 75vh;
  width: 100%;
`;

// const BottomRow = styled.div`
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// `;

// const ButtonWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-bottom: 20px;
// `;

// const StyledRow = styled(Row)`
//   @media screen and (max-height: 670px) {
//     display: none;
//   }
// `;

export const ProjectDetailPage: React.FC<any> = (props: any) => {
  const { projectId, onPageChange } = props;

  const dispatch = useDispatch();

  const [isApartmentSearchOpen, setIsApartmentSearchOpen] = useState(false);

  // function openApartmentSearchModal() {
  //   setIsApartmentSearchOpen(true);
  // }

  // function closeApartmentSearchModal() {
  //   setIsApartmentSearchOpen(false);
  // }

  useEffect(() => {
    dispatch(projectRedux.actions.getDetail(projectId));
  }, [dispatch, projectId]);
  const projectDetailBranch = useSelector<IAppReduxState, IAsyncData<IProject>>(
    (state) => state.project.details
  );

  return (
    <RotateDetector>
      <div className="project-portal d-flex position-relative">
        <ApartmentSearchModal
          show={isApartmentSearchOpen}
          onPageChange={onPageChange}
          projectPlanId={projectId}
          sectors={projectDetailBranch.data?.sectors}
        // onHide={closeApartmentSearchModal}
        />
        <Wrapper>
          {!isLoading(projectDetailBranch) && (
            <ShapeViewer
              image={projectDetailBranch.data?.image}
              shapes={projectDetailBranch.data?.sectors?.map((sec) => sec.shape)}
              hoverColor="rgba(34, 58, 94, 0.6)"
              invertHover
              renderShapeLink={() => ""}
              renderShapeOnClickLink={(shape) => {
                const sector = projectDetailBranch.data?.sectors?.find(
                  s => s.shape.id === shape.id
                );
                if (sector?.status === EProjectSectorStatus.Active) {
                  // if (sector?.status === EProjectSectorStatus.Active || sector?.status === EProjectSectorStatus.Sold) {
                  onPageChange({
                    route: ROUTES.sector,
                    param1: projectId,
                    param2: sector?.id,
                  });
                }
              }}
              renderHovered={(shape) => {
                const sector = projectDetailBranch.data?.sectors?.find(
                  (s) => s.shape.id === shape.id
                );
                return sector && <SectorInfo sector={sector} />;
              }}
            />
          )}

          {/* <BottomRow>
          <Container fluid>
            <Row>
              <Col>
                <ButtonWrapper>
                  <MainButton onClick={openApartmentSearchModal}>{t("common:apartmentSearch")}</MainButton>
                </ButtonWrapper>
              </Col>
            </Row>
            <StyledRow>
              <Col>
                <Copyright />
              </Col>
            </StyledRow>
          </Container>
        </BottomRow> */}
          {/* <ApartmentSearchModal show={isApartmentSearchOpen} /> */}
        </Wrapper>
      </div>
    </RotateDetector>
  );
};
