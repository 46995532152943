import { useTranslation } from 'react-i18next'
import img from '../../../assets/img/utils/link.svg'
import Breadcrumb from '../../components/Breadcrumb'

const Constitution3 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/qanunvericilik/ar-konstitusiyasi',
            title: t('legislation:constitution'),
            isActive: false
        },
        {
            id: 2,
            link: '/qanunvericilik/ar-mecelleri',
            title: t('legislation:codes'),
            isActive: false
        },
        {
            id: 3,
            link: '/qanunvericilik/ar-qanunlari',
            title: t('legislation:laws'),
            isActive: true
        },
        {
            id: 4,
            link: '/qanunvericilik/ar-prezidentinin-fermanlari',
            title: t('legislation:orders'),
            isActive: false
        },
        {
            id: 5,
            link: '/qanunvericilik/strateji-yol-xeriteleri',
            title: t('legislation:strategic'),
            isActive: false
        }
    ]

    const constitution = [
        {
            id: 1,
            no: "1308-IVQ",
            date: "30.09.2015",
            title: "Vətəndaşların müraciətləri haqqında AR Qanunu",
            link: "https://e-qanun.az/framework/31281"
        },
        {
            id: 2,
            no: "1024-IIQ",
            date: "30.09.2005",
            title: "İnformasiya əldə etmək haqqında AR Qanunu",
            link: "https://e-qanun.az/framework/11142"
        },
        {
            id: 3,
            no: "21-IVKQ",
            date: "21.12.2009",
            title: "Normativ hüquqi aktlar haqqında AR Konstitusiya Qanunu",
            link: "https://e-qanun.az/framework/21300"
        },
        {
            id: 4,
            no: "883-IIQ",
            date: "15.04.2005",
            title: "İpoteka haqqında AR Qanunu",
            link: "https://e-qanun.az/framework/9902"
        },
        {
            id: 5,
            no: "713-IIQ",
            date: "29.06.2004",
            title: "Daşınmaz əmlakın dövlət reyestri haqqında AR Qanunu",
            link: "https://e-qanun.az/framework/5456"
        }
    ]

    return (
        <div className="constitution-page block-bg1 pt-md-3 pt-4 py-1">
            <Breadcrumb data={breadcrumb} />

            <div className='container'>
                <div className='mt-3 mb-5'>
                    <table className="table table-borderless w-100">
                        <thead>
                            <tr>
                                <th className="col-2">{t('legislation:doc_no')}</th>
                                <th className="col-2">{t('legislation:accept_date')}</th>
                                <th className="col-6">{t('legislation:doc_name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {constitution.map(item =>
                                <tr>
                                    <td className="col-2">{item.no}</td>
                                    <td className="col-2">{item.date}</td>
                                    <td className="col-6"><a href={item.link} target="_blank" rel="noreferrer" className='mb-0'>{item.title}</a> <img src={img} /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Constitution3 
