const Table12 = ({ apiData, info }) => {

    // function countTotal(nameKey) {
    //     if (nameKey == 'fem') {
    //         return info.female.cash + info.female.loan
    //     }
    //     if (nameKey == 'male') {
    //         return info.male.cash + info.male.loan
    //     }
    // }

    return (
        <>
            {apiData && (
                <table id="table12" className='table table-responsive table-bordered mb-0'>
                    <thead>
                        <tr>
                            <th rowSpan={3} colSpan={6} className="table-category-header">Kateqoriya</th>
                            <th colSpan={12}>
                                Mənzilin alqı-satqısı zamanı ödəniş üsulu üzrə
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                Kişi
                            </th>
                            <th colSpan={2}>
                                Qadın
                            </th>
                            <th rowSpan={2} className="p-4">
                                Cəmi
                            </th>
                        </tr>
                        <tr>
                            <th>Öz vəsaiti hesabına</th>
                            <th>İpoteka krediti hesabına</th>
                            <th>Öz vəsaiti hesabına</th>
                            <th>İpoteka krediti hesabına</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiData?.table?.map(row =>
                            <tr key={apiData.table?.indexOf(row)}>
                                <td colSpan={6} className={`text-${row.category === "TOTAL" ? "center" : "start"}`}>{row.category === "TOTAL" ? "CƏMİ" : row.category}</td>
                                <td>{row.male.cash > 0 && row.male.cash}</td>
                                <td>{row.male.loan > 0 && row.male.loan}</td>
                                <td>{row.female.cash > 0 && row.female.cash}</td>
                                <td>{row.female.loan > 0 && row.female.loan}</td>
                                <td colSpan={2}>{row.totalCount}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default Table12
