//libraries 
import { Navigation, Thumbs, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next"

// functions & components
import { apiClient } from '../../core/api_client'
import { Routing } from '../../core/routing'
import Breadcrumb from "../../components/Breadcrumb"
import l from "../../core/localize"
import ResizableImg from "../../components/ResizableImg"

// assets
import fb from "../../../assets/img/socials/facebook_light.svg"
import insta from "../../../assets/img/socials/instagram_light.svg"
import tw from "../../../assets/img/socials/twitter_light.svg"
import yt from "../../../assets/img/socials/youtube_light.svg"
import arrow from "../../../assets/img/utils/arrow-down.svg"
import arrow1 from '../../../assets/img/utils/gallery-arrow-l-1.svg'
import arrow2 from '../../../assets/img/utils/gallery-arrow-r-1.svg'

// styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

const PhotoGallery = () => {
    const [data, setData] = useState([])
    const [album, setAlbum] = useState([])
    const [galleryLength, setGalleryLength] = useState([])
    const [isDataLoading, setDataLoading] = useState(true)
    const [isLoading, setLoading] = useState(true)
    const [activeGalleryId, setActiveGalleryId] = useState()
    const [end, setEnd] = useState(false)
    const [start, setStart] = useState(false)
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)
    const [numComponents, setNumComponents] = useState(12)
    const increment = 8
    const sliderRef = useRef(null);

    const breadcrumb = [
        {
            id: 1,
            link: '/xeberler-ve-media/bizim-xeberler',
            title: t('common:our_news'),
            isActive: false
        },
        {
            id: 2,
            link: '/xeberler-ve-media/multimedia',
            title: t('common:multimedia'),
            isActive: false
        },
        {
            id: 3,
            link: '/xeberler-ve-media/fotogalereya',
            title: t('common:photo_gallery'),
            isActive: true
        }
    ]

    useEffect(() => {
        document.getElementById('root').style.background = "#F5F5F7"
    }, [])

    useEffect(() => {
        apiClient.get('/multimediaGalleries?type=photo')
            .then(res => {
                setData(res.data)
                setGalleryLength(res.data.length)
                setActiveGalleryId(res.data[0].id)
                setDataLoading(false)
            })
    }, [])

    useEffect(() => {
        apiClient.get('/multimediaGalleries/' + activeGalleryId)
            .then(res => {
                setAlbum(res.data)
                setLoading(false)
            })
    }, [activeGalleryId]);

    const handlePrev = () => {
        if (sliderRef.current.swiper.slides.length > 1) {
            if (sliderRef.current.swiper.isBeginning) {
                setStart(true)
            }
        }

        if (start || sliderRef.current.swiper.slides.length === 1) {
            var currentIndex = data.findIndex(item => item.id === album.id);
            const lastSlide = sliderRef.current.swiper.slides.length - 1;
            if (currentIndex > 0) {
                var prevIndex = currentIndex - 1;
                setActiveGalleryId(data[prevIndex].id);
                sliderRef.current.swiper.slideTo(lastSlide)
            }
            else {
                setActiveGalleryId(data[galleryLength - 1].id);
                sliderRef.current.swiper.slideTo(lastSlide)
            }

            setStart(false)
        }
    }

    const handleNext = () => {
        if (sliderRef.current.swiper.slides.length > 1) {
            if (sliderRef.current.swiper.isEnd) {
                setEnd(true)
            }
        }

        if (end || sliderRef.current.swiper.slides.length === 1) {
            var currentIndex = data.findIndex(item => item.id === album.id);
            if (currentIndex < galleryLength - 1) {
                var nextIndex = currentIndex + 1;
                setActiveGalleryId(data[nextIndex].id);
                sliderRef.current.swiper.slideTo(0)
            }
            else {
                setActiveGalleryId(data[0].id);
                sliderRef.current.swiper.slideTo(0)
            }

            setEnd(false)
        }
    }

    return (
        <div className="multimedia pb-2">
            <Breadcrumb data={breadcrumb} />

            <div className='container pt-2 mt-4'>
                <div className='t-color4 text-center w-75 mx-auto my-auto d-flex flex-column' style={{ height: '160px' }}>
                    <h4>{l(album, "name", currentLang)}</h4>
                    <h5 className='fw-normal mt-3 mb-5'>{album?.date?.slice(0, 10) ?? ""}</h5>
                </div>
                <div className='gallery-horizontal position-relative'>
                    {isLoading && <div className='d-flex justify-content-center align-items-center' style={{ height: '300px' }}><div className="lds-roller white"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
                    <div className={"swiper-button-prev-n d-md-flex"}
                        style={{
                            height: '120px',
                            left: '0',
                            top: '45%'
                        }}
                        onClick={handlePrev}>
                        <img src={arrow1} alt="" />
                    </div>
                    <Swiper
                        ref={sliderRef}
                        pagination={{
                            type: "fraction",
                        }}
                        spaceBetween={10}
                        navigation={{
                            nextEl: '.swiper-button-next-n',
                            prevEl: '.swiper-button-prev-n',
                        }}
                        modules={[Navigation, Pagination, Thumbs]}
                        className="mySwiper2 swiper-main col-sm-10"
                    >
                        {album &&
                            <>
                                <SwiperSlide>
                                    <img src={Routing.generateFileRoute(album.main_file)} alt="" />
                                    {/* <p className="news-title mt-2 t-color4 position-absolute" style={{ fontSize: "14px" }}>{l(album, "name", currentLang)} 0 {"/ " + album.multimediaGalleryFiles?.length}</p> */}
                                </SwiperSlide>
                                {album && album.multimediaGalleryFiles?.map(item =>
                                    <SwiperSlide key={item.id}>
                                        <img src={Routing.generateFileRoute(item)} alt="" />
                                        {/* <p className="news-title mt-2 t-color4 position-absolute" style={{ fontSize: "14px" }}>{l(album, "name", currentLang) + " " + (album.multimediaGalleryFiles?.indexOf(item) + 1) + "/" + album.multimediaGalleryFiles?.length}</p> */}
                                    </SwiperSlide>
                                )}
                            </>
                        }
                    </Swiper>
                    <div className={"swiper-button-next-n d-md-flex"}
                        onClick={handleNext}
                        style={{
                            height: '120px',
                            right: '0',
                            top: '45%'
                        }}>
                        <img src={arrow2} alt="" />
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-5 mt-3">
                    <a href="https://www.facebook.com/guzeshtlimenzil" className="me-4" target="_blank" rel="noreferrer">
                        <img src={fb} alt="facebook" />
                    </a>
                    <a href="https://www.instagram.com/mida.az" className="me-4" target="_blank" rel="noreferrer">
                        <img src={insta} alt="instagram" />
                    </a>
                    <a href="https://twitter.com/guzeshtlimenzil" className="me-4" target="_blank" rel="noreferrer">
                        <img src={tw} alt="twitter" width={32} />
                    </a>
                    <a href="https://www.youtube.com/channel/UC3xOGx3unafEd79Oe_VFpog" className="me-4" target="_blank" rel="noreferrer">
                        <img src={yt} alt="youtube" />
                    </a>
                </div>

                <div className='row gx-5 mt-4'>
                    {isDataLoading && <div className='d-flex justify-content-center align-items-center' style={{ height: '300px' }}><div className="lds-roller white"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
                    {data.slice(0, numComponents).map(item =>
                        <a className='col-lg-3 my-md-4 my-2'
                            onClick={_ => setActiveGalleryId(item.id)}
                            href={!!item.news_id ? `/xeberler-ve-media/bizim-xeberler/${item.news_id}` : null}
                            key={item.id}>
                            <div className="">
                                <ResizableImg src={Routing.generateFileRoute(item.main_file)} className="w-100" alt="..." />
                                <div className="card-body py-3">
                                    <p className="news-title mt-2 t-color4" style={{ fontSize: "14px" }}>{l(item, "name", currentLang)}</p>
                                </div>
                            </div>
                        </a>
                    )}
                </div>

                {numComponents < galleryLength ?
                    <div className='d-flex justify-content-end pb-5'>
                        <button className='btn-show-more' onClick={() => setNumComponents(numComponents + increment)}>
                            {t('common:show_more')} <img src={arrow} className="ms-2" alt="" />
                        </button>
                    </div> : null
                }
            </div>
        </div>
    )
}

export default PhotoGallery 
