import React from "react";
import { ISectorFloor } from "../../data/entities";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: white;
  padding: 10px;
  color: black;
  border-radius: 5px;
`;

interface IProps {
  floor: ISectorFloor;
}

export const FloorInfo: React.FC<IProps> = ({ floor }: IProps) => {
  return <Wrapper>Mərtəbə {floor.number}</Wrapper>;
};
