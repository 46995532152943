import * as React from "react";

export function PlanIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" {...props}>
      <path
        d="M27.437498,0 L0.562624926,0 C0.251914265,0 0,0.251908732 0,0.562612569 L0,27.4371414 C0,27.7478453 0.251914265,27.999754 0.562624926,27.999754 L16.9536578,27.999754 C17.2643685,27.999754 17.5161598,27.7478453 17.5161598,27.4371414 C17.5161598,27.1264376 17.2643685,26.8745289 16.9536578,26.8745289 L1.12524985,26.8745289 L1.12524985,13.7616215 L8.97272362,13.7616215 L8.97272362,19.1551498 C8.97272362,19.4658537 9.22463789,19.7177624 9.53534855,19.7177624 C9.84605921,19.7177624 10.0979735,19.4658537 10.0979735,19.1551498 L10.0979735,7.37521855 C10.0979735,7.06451471 9.84605921,6.81260598 9.53534855,6.81260598 C9.22463789,6.81260598 8.97272362,7.06451471 8.97272362,7.37521855 L8.97272362,12.6363964 L1.12524985,12.6363964 L1.12524985,1.12522514 L16.3911559,1.12522514 L16.3911559,8.16969047 C16.3911559,8.48039431 16.6429472,8.73230304 16.9536578,8.73230304 L22.9036563,8.73230304 C23.2143669,8.73230304 23.4661582,8.48039431 23.4661582,8.16969047 C23.4661582,7.85898664 23.2143669,7.6070779 22.9036563,7.6070779 L17.5161598,7.6070779 L17.5161598,1.12522514 L26.8749961,1.12522514 L26.8749961,18.5926603 L16.9536578,18.5926603 C16.6429472,18.5926603 16.3911559,18.844569 16.3911559,19.1552728 C16.3911559,19.4659767 16.6429472,19.7178854 16.9536578,19.7178854 L26.8749961,19.7178854 L26.8749961,26.8747749 L22.9036563,26.8747749 C22.5929456,26.8747749 22.3411544,27.1266836 22.3411544,27.4373874 C22.3411544,27.7480913 22.5929456,28 22.9036563,28 L27.437498,28 C27.7482087,28 28,27.7480913 28,27.4373874 L28,0.562612569 C28.000123,0.251908732 27.7483317,0 27.437498,0 Z"
        id="Path"
      ></path>
    </svg>
  );
}
