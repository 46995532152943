import BarsVert from '../charts/BarsVert'

const Template8 = ({ data }) => {
    return (
        <div className='my-3 py-3'>
            <BarsVert apiData={data} legend={false} />
        </div>
    )
}

export default Template8 
