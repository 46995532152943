import { BarChartHor1 } from '../charts/BarChartHor1'

const Template13 = ({ data }) => {
    return (
        <>
            <div className='row chart-container'>
                <div className='chart-header col-md-3'>
                    <h6 className="text-center">{data.title}</h6>
                </div>
                <div className='chart-content col-md-9'>
                    <BarChartHor1 apiData={data} legend={false} color={'#f79646'} />
                </div>
            </div>
        </>
    )
}

export default Template13 
