// libraries
import parse from 'html-react-parser'
import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// assets
import close from '../../../../assets/img/guzestli menzil/x.svg'

import dovlet from '../../../../assets/files/arayis formalari/Dövlət qulluqçuları-ARAYIŞ.doc'
import xususi from '../../../../assets/files/arayis formalari/Dövlət qulluğunun xüsusi növündə xidmət keçməyə dair ARAYIŞ.doc'
import herbiEhtiyat from '../../../../assets/files/arayis formalari/Ehtiyata ve istefaye buraxilmish herbi qulluqchular.docx'
import daxili from '../../../../assets/files/arayis formalari/ən azı 3 il daxili işlər orqanlarında xidmət keçən xüsusi rütbəsi olan şəxs.doc'
import tehsil3 from '../../../../assets/files/arayis formalari/en azi 3 il dovlet umumi tehsil muessisesinde muellim ishleyen shexs.doc'
import elmi from '../../../../assets/files/arayis formalari/ən azı 3 il elmi vəzifədə işləyən elmi işçilər.doc'
import herbi3 from '../../../../assets/files/arayis formalari/En azi 3 il herbi vezife ve xidmet.docx'
import tehsil15 from '../../../../assets/files/arayis formalari/en azi 15 il dovlet umumi tehsil muessisesinde muellim ishleyen shexs.doc'
import idman from '../../../../assets/files/arayis formalari/idman sahəsində xüsusi xidmətləri olan şəxs.doc'

const Accordions = () => {
    const [note, setNote] = useState({})
    const [noteVisible, setNoteVisible] = useState(false)
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [height, setHeight] = useState(0)
    const history = useHistory()
    const { t } = useTranslation()

    const parseOrRender = useCallback(content => {
        if (!content) {
            return null
        }
        switch (typeof content) {
            case 'string': {
                return parse(content)
            }
            case 'function': return content()
            default: return content
        }
    }, [])

    const notes = [
        {
            id: 1,
            title: t('gms:note_title1'),
            note: t('gms:note_content1')
        },
        {
            id: 2,
            title: t('gms:note_title2'),
            note: t('gms:note_content2')
        },
        {
            id: 3,
            title: t('gms:note_title3'),
            note: t('gms:note_content3')
        },
        {
            id: 4,
            title: t('gms:note_title4'),
            note: t('gms:note_content4')
        },
        {
            id: 5,
            title: t('gms:note_title5'),
            note: t('gms:note_content5')
        },
        {
            id: 6,
            title: t('gms:note_title4'),
            note: t('gms:note_content4')
        },
        {
            id: 7,
            title: t('gms:note_title7'),
            note: t('gms:note_content7')
        },
        {
            id: 8,
            title: t('gms:note_title8'),
            note: t('gms:note_content8')
        },
        {
            id: 9,
            title: t('gms:note_title6'),
            note: t('gms:note_content6')
        },
        {
            id: 10,
            title: t('gms:note_title9'),
            note: t('gms:note_content9')
        },
        {
            id: 11,
            title: t('gms:note_title5'),
            note: t('gms:note_content5')
        }
    ]

    const files = [
        {
            name: 'xususi',
            file: xususi
        },
        {
            name: 'dovlet',
            file: dovlet
        },
        {
            name: 'herbiEhtiyat',
            file: herbiEhtiyat
        },
        {
            name: 'daxili',
            file: daxili
        },
        {
            name: 'tehsil3',
            file: tehsil3
        },
        {
            name: 'idman',
            file: idman
        },
        {
            name: 'elmi',
            file: elmi
        },
        {
            name: 'tehsil15',
            file: tehsil15
        },
        {
            name: 'herbi3',
            file: herbi3
        }
    ]

    const showNote = id => {
        setNote(notes.find(x => x.id == id))
        setNoteVisible(true)
    }

    const closeNote = () => {
        setNote({})
        setNoteVisible(false)
    }

    useEffect(() => {
        setData1([
            {
                id: 6,
                title: t('gms:title1_1'),
                content: t('gms:content1_1')
            },
            {
                id: 7,
                title: t('gms:title1_2'),
                content: t('gms:content1_2')
            },
            {
                id: 8,
                title: t('gms:title1_3'),
                content: t('gms:content1_3')
            },
            {
                id: 9,
                title: t('gms:title1_4'),
                content: t('gms:content1_4')
            },
            {
                id: 10,
                title: t('gms:title1_5'),
                content: t('gms:content1_5')
            },
            {
                id: 11,
                title: t('gms:title1_6'),
                content: t('gms:content1_6')
            },
            {
                id: 12,
                title: t('gms:title1_7'),
                content: t('gms:content1_7')
            },
            {
                id: 13,
                title: t('gms:title1_8'),
                content: t('gms:content1_8')
            },
            {
                id: 23,
                title: t('gms:title1_9'),
                content: t('gms:content1_9'),
                // style: { border: "3px solid #F79A45", borderRadius: "inherit" }
            }
        ])

        setData2([
            {
                id: 14,
                title: t('gms:title2_1'),
                content: t('gms:content2_1')
            },
            {
                id: 15,
                title: t('gms:title2_2'),
                content: t('gms:content2_2')
            },
            {
                id: 16,
                title: t('gms:title2_3'),
                content: t('gms:content2_3')
            },
            {
                id: 17,
                title: t('gms:title2_4'),
                content: t('gms:content2_4')
            },
            {
                id: 18,
                title: t('gms:title2_5'),
                content: t('gms:content2_5')
            },
            {
                id: 19,
                title: t('gms:title2_6'),
                content: t('gms:content2_6')
            },
            {
                id: 20,
                title: t('gms:title2_7'),
                content: t('gms:content2_7')
            },
            {
                id: 21,
                title: t('gms:title2_8'),
                content: t('gms:content2_8')
            },
            {
                id: 22,
                title: t('gms:title2_9'),
                content: t('gms:content2_9')
            },
            {
                id: 24,
                title: t('gms:title2_10'),
                content: t('gms:content2_10'),
                // style: { border: "3px solid #F79A45", borderRadius: "inherit" }
            }
        ])
    }, [])

    useEffect(() => {
        document.querySelectorAll('[data-id]').forEach(element => {
            element.onclick = () => { showNote(element.getAttribute("data-id")) }
        });

        document.addEventListener('DOMContentLoaded', function () {
            document.getElementById('page-link').onclick = () => { history.push(document.getElementById('page-link').getAttribute("href")) };
        });

        document.querySelectorAll('[data-file]').forEach(element => {
            let file = files.find(item => item.name === element.getAttribute("data-file"))
            element.setAttribute('href', file.file)
        });
    }, [data1, data2, document])

    useEffect(() => {
        var body = document.body,
            html = document.documentElement

        setHeight(Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight))
    }, []);

    useEffect(() => {
        const links = document.querySelectorAll('.accordion-button2.d-block a');
        links.forEach((link) => {
            link.addEventListener("click", handleLinkClick);
        });

        return () => {
            links.forEach((link) => {
                link.removeEventListener("click", handleLinkClick);
            });
        };
    }, [data2]);

    const handleLinkClick = (e) => {
        e.preventDefault();
        if (e.target.href) window.open(e.target.href, "_blank");
    };

    return (
        <>
            <div className={'position-absolute w-100 h-100 top-0 start-0 justify-content-center ' + (noteVisible ? "d-flex" : "d-none")} style={{ background: "rgba(34, 50, 74, 0.4)", zIndex: 10000 }}>
                <div className='note-card rounded-4 text-black position-fixed pt-2 pb-4 px-3 my-auto' style={{ transform: "translate(0%, 90%)", bottom: "70%", maxWidth: "75%", backgroundColor: "white" }}>
                    <img className="d-flex ms-auto cursor" src={close} onClick={closeNote} alt='' />
                    <div>
                        <h5 className='t-black text-center mb-4'>{parseOrRender(note?.title)}</h5>
                        <p className='t-black mb-0 text-justify'>{parseOrRender(note?.note)}</p>
                    </div>
                </div>
            </div>
            <div className='my-3 row' style={{ fontFamily: "Roboto Condensed" }}>
                <div className='col-12 col-md-6'>
                    <div className="accordion2" id='accordionEx1'>
                        <div className="accordion-item">
                            <h2 className="accordion-header2 d-block" style={{ background: "#223A5E" }}>
                                <div className="accordion-button2 d-block py-3 fw-normal">
                                    {parseOrRender(t("gms:heading1"))}
                                </div>
                            </h2>
                        </div>
                        {data1.map(item =>
                            <div className="accordion-item" key={item.id}>
                                <h2 className="accordion-header2" id={"heading" + item.id}>
                                    <button className="accordion-button2 d-block py-3"
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target={"#collapse" + item.id}
                                        style={item.style}>
                                        {parseOrRender(item?.title)}
                                    </button>
                                </h2>
                                <div id={"collapse" + item.id} className="accordion-collapse2 collapse" data-bs-parent="#accordionEx1">
                                    <div className="accordion-body2">
                                        {parseOrRender(item?.content)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className='col-12 col-md-6 mt-md-0 mt-5'>
                    <div className="accordion2" id='accordionEx2'>
                        <div className="accordion-item">
                            <h2 className="accordion-header2" style={{ background: "#223A5E" }}>
                                <div className="accordion-button2 py-3 fw-normal">
                                    {parseOrRender(t("gms:heading2"))}
                                </div>
                            </h2>
                        </div>
                        {data2.map(item =>
                            <div className="accordion-item" key={item.id}>
                                <h2 className="accordion-header2" id={"heading" + item.id}>
                                    <button className="accordion-button2 d-block py-3" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + item.id} style={item.style}>
                                        {parseOrRender(item.title)}
                                    </button>
                                </h2>
                                <div id={"collapse" + item.id} className="accordion-collapse2 collapse" data-bs-parent="#accordionEx2">
                                    <div className="accordion-body2">
                                        {parseOrRender(item.content)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accordions
