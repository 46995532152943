// libraries
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"

// components
import { apiClient } from "../../core/api_client"
import SatisStats from "../../components/reports/pages/SatisStats"
import Breadcrumb from "../../components/Breadcrumb"

// assets
import img1 from '../../../assets/img/hesabatlar/satis-uzre/satish1.jpg'
import img2 from '../../../assets/img/hesabatlar/satis-uzre/satish2.jpg'
import img3 from '../../../assets/img/hesabatlar/satis-uzre/satish3.jpg'
import img4 from '../../../assets/img/hesabatlar/satis-uzre/satish4.jpg'
import img5 from '../../../assets/img/hesabatlar/satis-uzre/satish5.jpg'
import img6 from '../../../assets/img/hesabatlar/satis-uzre/satish6.jpg'
import img7 from '../../../assets/img/hesabatlar/satis-uzre/satish7.jpg'
import img8 from '../../../assets/img/hesabatlar/satis-uzre/satish8.jpg'
import img9 from '../../../assets/img/hesabatlar/satis-uzre/satish9.jpg'
import img10 from '../../../assets/img/hesabatlar/satis-uzre/satis10.jpg'
import img11 from '../../../assets/img/hesabatlar/satis-uzre/satish11.jpg'
import img12 from '../../../assets/img/hesabatlar/satis-uzre/satish12.jpg'
import img13 from '../../../assets/img/hesabatlar/satis-uzre/satish13.jpg'
import img14 from '../../../assets/img/hesabatlar/satis-uzre/satish14.jpg'


const SatishHesabatPage = () => {
    const { sellId } = useParams()
    const [report, setReport] = useState({})
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/hesabatlar/satis',
            title: t('agentlik:audits_sales'),
            isActive: true
        }
    ]

    const audit_card = [
        {
            id: 12,
            name_az: t('common:sale') + " №14",
            title_az: "Yasamal Yaşayış Kompleksinin ikinci mərhələsində 15.09.2022 və 19.09.2022-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img14,
        },
        {
            id: 1333,
            name_az: t('common:sale') + " №13",
            title_az: "Gəncə Yaşayış Kompleksində 23.12.2021 və 27.12.2021-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img13,
        },
        {
            id: 1222,
            name_az: t('common:sale') + " №12",
            title_az: "Sumqayıt şəhərində inşa olunmuş binalarda 17.08.2021 və 20.08.2021-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img12,
        },
        {
            id: 1110,
            name_az: t('common:sale') + " №11",
            title_az: "Hövsan Yaşayış Kompleksində 01.07.2021 və 06.07.2021-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img11,
        },
        {
            id: 1001,
            name_az: t('common:sale') + " №10",
            title_az: "Hövsan Yaşayış Kompleksində 21.04.2021 və 26.04.2021-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img10,
        },
        {
            id: 9999,
            name_az: t('common:sale') + " №9",
            title_az: "Hövsan Yaşayış Kompleksində 18.12.2020 və 22.12.2020-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img9,
        },
        {
            id: 8888,
            name_az: t('common:sale') + " №8",
            title_az: "Hövsan Yaşayış Kompleksində 10.09.2020 və 14.09.2020-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img8,
        },
        {
            id: 7777,
            name_az: t('common:sale') + " №7",
            title_az: "Hövsan Yaşayış Kompleksində 30.06.2020 və 03.07.2020-ci il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img7,
        },
        {
            id: 6666,
            name_az: t('common:sale') + " №6",
            title_az: "Yasamal Yaşayış Kompleksində 25.12.2019 və 27.12.2019-cu il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img6,
        },
        {
            id: 5555,
            name_az: t('common:sale') + " №5",
            title_az: "Yasamal Yaşayış Kompleksində 16.10.2019 və 18.10.2019-cu il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img5,
        },
        {
            id: 4444,
            name_az: t('common:sale') + " №4",
            title_az: "Yasamal Yaşayış Kompleksində 12.07.2019 və 14.07.2019-cu il tarixlərində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img4,
        },
        {
            id: 3333,
            name_az: t('common:sale') + " №3",
            title_az: "Yasamal Yaşayış Kompleksində 29.04.2019-cu il tarixində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img3,
        },
        {
            id: 2222,
            name_az: t('common:sale') + " №2",
            title_az: "Yasamal Yaşayış Kompleksində 25.01.2019-cu il tarixində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img2,
        },
        {
            id: 1111,
            name_az: t('common:sale') + " №1",
            title_az: "Yasamal Yaşayış Kompleksində 05.09.2018-ci il tarixində barəsində elan verilmiş mənzillərin satış nəticələrinə dair statistik məlumatlar",
            img: img1,
        }
    ]

    useEffect(() => {
        if (sellId >= 1000) {
            setReport(audit_card.find(x => x.id.toString() === sellId))
        }
        else {
            apiClient.get("/reports/" + sellId)
                .then(res => {
                    setReport(res.data)
                })
                .catch(err => console.log(err))
        }
    }, [sellId])

    return (
        <div className="audit-page block-bg1 pt-md-3 pt-2 w-100">
            <Breadcrumb data={breadcrumb}  />
            <div className='sales-page-heading mt-5 px-md-60 py-5 d-flex flex-wrap flex-md-nowrap' style={{ backgroundColor: "#223A5E" }}>
                <div className='border-end border-2 fw-700 text-uppercase' style={{ fontSize: "48px", lineHeight: "58px" }}>
                    {report.name_az}
                </div>
                <div style={{ fontSize: "2rem", lineHeight: "38px" }}>
                    {report.title_az}
                </div>
            </div>
            <div className="container reports-charts mx-auto w-100">
                {sellId > 1000 ?
                    <img src={report.img} alt="satis hesabatlari" className='mx-auto d-flex my-5 w-100 justify-content-center' />
                    :
                    <SatisStats id={sellId} />
                }
            </div>
        </div>
    )
}

export default SatishHesabatPage 
