// libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// functions & components
import { apiClient } from '../../../core/api_client';
import l from '../../../core/localize';
import Map from '../../../components/Map';

const ProjectsMap = () => {
    const [categoryId, setCategoryId] = useState(0);
    const [allProjects, setAllProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    useEffect(() => {
        apiClient.get('/projectCategories').then(res => setCategories(res.data));
    }, []);

    useEffect(() => {
        apiClient.get('/projectPlans' + (!categoryId ? "" : `?project_category_id=${categoryId}`))
            .then(res => {
                setAllProjects(res.data.filter(i => i.id !== 300006 && i.id !== 300007));
            });
    }, [categoryId]);

    return (
        <div className='block-bg1 pt-2'>
            <div className='breadcrumb page-link mb-0 h-0'>
                <div className='line'></div>
                <div className='container'>
                    <div className='bc-items px-3 d-flex justify-content-center '>
                        <p onClick={() => setCategoryId(0)} className={"bc-button " + (!categoryId ? " bc-button-active" : "")}>{t('projects:all_projects')}</p>
                        {categories.map(category => {
                            const style = categoryId == category.id && "bc-button-active";
                            return (
                                <p onClick={() => setCategoryId(category.id)} className={`bc-button ${style}`} key={category.id}>{l(category, "name", currentLang)}</p>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Map projects={allProjects} />
        </div>
    );
}

export default ProjectsMap;
