// libraries
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"

// components & functions
import { apiClient } from "../../core/api_client"
import Breadcrumb from "../../components/Breadcrumb"
import l from "../../core/localize"
import { Routing } from "../../core/routing"

// assets
import arrow_right from "../../../assets/img/utils/arrow_right.svg"
import fb from "../../../assets/img/socials/facebook_light.svg"
import parse from 'html-react-parser'
import tw from "../../../assets/img/socials/twitter_light.svg"

const CooperationPage = () => {
    const { coopId } = useParams()
    const [coop, setCoop] = useState()
    const history = useHistory()
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:about'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/nizamname',
            title: t('agentlik:order'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: false
        },
        {
            id: 4,
            link: '/agentlik/mida-mmc',
            title: '"MİDA" MMC',
            isActive: false
        },
        {
            id: 5,
            link: '/agentlik/beynelxalq-emekdasliq',
            title: t('agentlik:international'),
            isActive: true
        },
        {
            id: 6,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        }
    ]

    useEffect(() => {
        apiClient.get('/news/' + coopId)
            .then(function (response) {
                setCoop(response.data)
            })
    }, [coopId])

    return (
        <div className="news-page block-bg1 pt-lg-3 pb-md-5 py-0">
            <Breadcrumb data={breadcrumb} />

            <div className='container px-md-2 px-1 '>
                {coop &&
                    <div className="news-item mt-4 px-md-5 px-1 pt-3 pb-5 block-bg-white">
                        <div className="d-flex flex-column align-items-center">
                            <h4 className="px-3 mt-2 w-75 text-center mx-auto">{l(coop, "name", currentLang)}</h4>
                            <div className="my-3" style={{ height: "6px", width: "180px", background: "#8CA2C2", borderRadius: "50px" }}></div>
                            <small className="news-date fw-normal px-4 py-2">{dayjs(coop.created_at).format('DD.MM.YYYY')}</small>
                        </div>
                        <div className="text-inf row mt-5 mt-md-3 px-md-120 gx-2 pb-5">
                            <p className='col-12 text-justify' style={{ lineHeight: "36px" }}><img src={Routing.generateFileRoute(l(coop, "file", currentLang))} alt="file" className='news-main-pic col-md-4 col-12' style={{ maxHeight: "300px" }} />{parse(l(coop, "content", currentLang))}</p>
                        </div>
                        <div className='d-flex justify-content-end mt-4 px-md-180'>
                            <div className="btn-share fb-share-button" data-href={window.location.href} data-layout="" data-size=""><a target="_blank" rel="noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href) + "&amp src=sdkpreparse"} className="fb-xfbml-parse-ignore t-color4"><img src={fb} alt='facebook' />{t('common:share')}</a></div>
                            <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className='btn-share ms-4' data-show-count="false"><img src={tw} alt='twitter' />Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                        </div>
                    </div>
                }
                <div className="btn-container d-flex justify-content-start mt-3">
                    <div className="more-info-btn py-2 px-2" onClick={() => history.goBack()}>
                        <img
                            className="me-1"
                            src={arrow_right}
                            style={{ transform: "rotate(-180deg)" }}
                            alt="back"
                        />
                        <small className="me-1">{t('common:go_back')}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CooperationPage 
