// libraries
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// components & functions
import { apiClient } from "../../../core/api_client"
import Breadcrumb from '../../../components/Breadcrumb'
import NewsComponent from './NewsComponent'
import l from '../../../core/localize'
import { Routing } from '../../../core/routing'
// assets
import arrow_right from "../../../../assets/img/utils/arrow_right_orange.svg"
import date from '../../../../assets/img/utils/date.svg'

const News = () => {
    const [latestNews, setLatestNews] = useState()
    const [isLoading, setLoading] = useState(true)
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    const breadcrumb = [
        {
            id: 1,
            link: '/xeberler-ve-media/bizim-xeberler',
            title: t('common:our_news'),
            isActive: true
        },
        {
            id: 2,
            link: '/xeberler-ve-media/multimedia',
            title: t('common:multimedia'),
            isActive: false
        }
    ]

    useEffect(() => {
        apiClient.get('/news/newsType/1')
            .then(response => {
                setLatestNews(response.data.sort((a, b) => a.date < b.date)[0])
                setLoading(false)
            })
    }, [])

    return (
        <div className="block-bg1 pt-md-3 pt-2 pb-2">
            <Breadcrumb data={breadcrumb} />

            <div className='container mt-5 mt-lg-4'>
                {isLoading && <div className='d-flex justify-content-center align-items-center' style={{ height: '300px' }}><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
                {latestNews &&
                    <Link to={`/xeberler-ve-media/bizim-xeberler/${latestNews.id}`} className='news-item row'>
                        <div className='col-sm-5 col-12'>
                            <img src={Routing.generateFileRoute(l(latestNews, "file", currentLang))} alt="info" className='w-100' />
                        </div>
                        <div className='col-sm-7 col-12 py-3 px-md-4 px-2'>
                            <h4 className='t-color1 text-center'>
                                {l(latestNews, "name", currentLang)}
                            </h4>
                            <small className="news-date fw-normal p-2 mt-3"><img src={date} alt="date-icon" className='me-2' />{dayjs(latestNews.date).format('DD.MM.YYYY')}</small>
                            <div className='d-flex justify-content-end btn-hover'>
                                <small className='m-0 highlight fw-bolder'>{t('common:show_details')}</small>
                                <img className="ms-2" src={arrow_right} alt="Show more" />
                            </div>
                        </div>
                    </Link>
                }
                <NewsComponent />
            </div>
        </div>
    )
}

export default News 
