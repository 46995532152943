import { useTranslation } from 'react-i18next'
import img from '../../../assets/img/utils/link.svg'
import Breadcrumb from '../../components/Breadcrumb'

// Fərmanlar
const Constitution4 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/qanunvericilik/ar-konstitusiyasi',
            title: t('legislation:constitution'),
            isActive: false
        },
        {
            id: 2,
            link: '/qanunvericilik/ar-mecelleri',
            title: t('legislation:codes'),
            isActive: false
        },
        {
            id: 3,
            link: '/qanunvericilik/ar-qanunlari',
            title: t('legislation:laws'),
            isActive: false
        },
        {
            id: 4,
            link: '/qanunvericilik/ar-prezidentinin-fermanlari',
            title: t('legislation:orders'),
            isActive: true
        },
        {
            id: 5,
            link: '/qanunvericilik/strateji-yol-xeriteleri',
            title: t('legislation:strategic'),
            isActive: false
        }
    ]

    const constitution = [
        {
            id: 18,
            no: "58",
            date: "07.05.2024",
            title: "Azərbaycan Respublikası Prezidentinin 2018-ci il 7 fevral tarixli 1819 nömrəli Fərmanı ilə təsdiq edilmiş “Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin Nizamnaməsi”ndə dəyişiklik edilməsi haqqında Azərbaycan Respublikası Prezidentinin Fərmanı",
            link: "https://president.az/az/articles/view/65753"
        },
        {
            id: 17,
            no: "1886",
            date: "24.11.2022",
            title: "Azərbaycan Respublikası Prezidentinin 2016-cı il 22 iyun tarixli 940 nömrəli Fərmanı ilə təsdiq edilmiş “Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun vəsaiti hesabına ipoteka kreditinin, o cümlədən güzəştli ipoteka kreditinin verilməsi Qaydası”nda dəyişiklik edilməsi haqqında AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/52786"
        },
        {
            id: 1,
            no: "1591",
            date: "08.02.2022",
            title: "Azərbaycan Respublikası Prezidentinin 2016-cı il 22 iyun tarixli 940 nömrəli Fərmanı ilə təsdiq edilmiş “Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun vəsaiti hesabına ipoteka kreditinin, o cümlədən güzəştli ipoteka kreditinin verilməsi Qaydası”nda dəyişiklik edilməsi haqqında AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/49127"
        },
        {
            id: 2,
            no: "1328",
            date: "04.05.2021",
            title: "\"Azərbaycan Respublikası Prezidentinin 2002-ci il 12 dekabr tarixli 823 nömrəli Fərmanı ilə təsdiq edilmiş “Azərbaycan Respublikasının Dövlət Sərhəd Xidməti haqqında\" Əsasnamə”də, 2016-cı il 22 iyun tarixli 940 nömrəli Fərmanı ilə təsdiq edilmiş “Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun vəsaiti hesabına ipoteka kreditinin, o cümlədən güzəştli ipoteka kreditinin verilməsi Qaydası”nda və 2018-ci il 10 dekabr tarixli 387 nömrəli Fərmanı ilə təsdiq edilmiş “DOST” mərkəzlərində göstərilən xidmətlərin siyahısı”nda dəyişiklik edilməsi barədə AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/47413"
        },
        {
            id: 3,
            no: "1016",
            date: "07.05.2020",
            title: "“Azərbaycan Respublikasının İpoteka və Kredit Zəmanət Fondunun fəaliyyətinin təmin edilməsi haqqında” Azərbaycan Respublikası Prezidentinin 2017-ci il 29 noyabr tarixli 1691 nömrəli Fərmanında və Azərbaycan Respublikası Prezidentinin 2016-cı il 22 iyun tarixli 940 nömrəli Fərmanı ilə təsdiq edilmiş “Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun vəsaiti hesabına ipoteka kreditinin, o cümlədən güzəştli ipoteka kreditinin verilməsi Qaydası”nda dəyişiklik edilməsi barədə AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/45016"
        },
        {
            id: 4,
            no: "935",
            date: "13.02.2020",
            title: "“Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin fəaliyyəti ilə bağlı bəzi məsələlər haqqında” 2016-cı il 16 noyabr tarixli 1113 nömrəli və “Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin fəaliyyətinin təmin edilməsi ilə bağlı bəzi məsələlər haqqında” 2018-ci il 7 fevral tarixli 1819 nömrəli fərmanlarında dəyişiklik edilməsi barədə AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/44421"
        },
        {
            id: 5,
            no: "519",
            date: "07.02.2019",
            title: "“Azərbaycan Respublikasının Əmlak Məsələləri Dövlət Komitəsinin fəaliyyətinin təkmilləşdirilməsi tədbirləri və “Azərbaycan Respublikasının Əmlak Məsələləri Dövlət Komitəsinin fəaliyyətinin təmin edilməsi haqqında” Azərbaycan Respublikası Prezidentinin 2009-cu il 24 iyun tarixli 116 nömrəli Fərmanında dəyişikliklər edilməsi barədə” 2015-ci il 4 may tarixli 516 nömrəli, “Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin fəaliyyəti ilə bağlı bəzi məsələlər haqqında” 2016-cı il 16 noyabr tarixli 1113 nömrəli, “Milli iqtisadiyyat və iqtisadiyyatın əsas sektorları üzrə Strateji yol xəritəsinin təsdiq edilməsi haqqında” 2016-cı il 6 dekabr tarixli 1138 nömrəli və “Güzəştli mənzil” sistemi haqqında Əsasnamə”nin təsdiq edilməsi barədə” 2017-ci il 24 may tarixli 1418 nömrəli fərmanlarında dəyişiklik edilməsi haqqında AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/41431"
        },
        {
            id: 6,
            no: "469",
            date: "14.01.2019",
            title: "“Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin fəaliyyəti ilə bağlı bəzi məsələlər haqqında” Azərbaycan Respublikası Prezidentinin 2016-cı il 16 noyabr tarixli 1113 nömrəli Fərmanında dəyişiklik edilməsi barədə AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/41210"
        },
        {
            id: 7,
            no: "1819",
            date: "07.02.2018",
            title: "\"Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin fəaliyyətinin təmin edilməsi ilə bağlı bəzi məsələlər haqqında\" AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/37830"
        },
        {
            id: 8,
            no: "1560",
            date: "21.07.2017",
            title: "“Azərbaycan Respublikasında ipoteka kreditləşməsinin bəzi məsələləri haqqında” Azərbaycan Respublikası Prezidentinin 2016-cı il 22 iyun tarixli 940 nömrəli Fərmanında dəyişiklik edilməsi barədə AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/36257"
        },
        {
            id: 9,
            no: "1418",
            date: "24.05.2017",
            title: "“Güzəştli mənzil” sistemi haqqında Əsasnamə”nin təsdiq edilməsi barədə AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/35494"
        },
        {
            id: 10,
            no: "1312",
            date: "04.04.2017",
            title: "“Azərbaycan İpoteka Fondu” Açıq Səhmdar Cəmiyyətinin vəsaiti hesabına ipoteka kreditinin, o cümlədən güzəştli ipoteka kreditinin verilməsi Qaydası”nda dəyişikliklər edilməsi haqqında AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/35163"
        },
        {
            id: 11,
            no: "1138",
            date: "06.12.2016",
            title: "\"Milli iqtisadiyyat və iqtisadiyyatın əsas sektorları üzrə Strateji yol xəritəsinin təsdiq edilməsi haqqında\" AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/34254"
        },
        {
            id: 12,
            no: "1125",
            date: "24.11.2016",
            title: "\"Azərbaycan Respublikasında dövlət idarəçiliyinin təkmilləşdirilməsi ilə bağlı bəzi tədbirlər haqqında\" AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/34171"
        },
        {
            id: 13,
            no: "1113",
            date: "16.11.2016",
            title: "\"Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin fəaliyyəti ilə bağlı bəzi məsələlər haqqında\" AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/34101"
        },
        {
            id: 14,
            no: "940",
            date: "22.06.2016",
            title: "\"Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun vəsaiti hesabına ipoteka kreditinin, o cümlədən güzəştli ipoteka kreditinin verilməsi Qaydası haqqında\" AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/33106#_edn23"
        },
        {
            id: 15,
            no: "858",
            date: "11.04.2016",
            title: "\"Azərbaycan Respublikasının Mənzil İnşaatı Dövlət Agentliyinin yaradılması və fəaliyyətinin təşkili haqqında\" AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/32563"
        },
        {
            id: 16,
            no: "813",
            date: "05.02.2013",
            title: "““Elektron hökumət” portalı haqqında Əsasnamə”-nin təsdiq edilməsi və elektron xidmətlərin genişləndirilməsi ilə bağlı tədbirlər barədə AR Prezidentinin Fərmanı",
            link: "https://e-qanun.az/framework/25215"
        }
    ]

    return (
        <div className="constitution-page block-bg1 pt-md-3 pt-4 py-1">
            <Breadcrumb data={breadcrumb} />

            <div className='container'>
                <div className='mt-3 mb-5'>
                    <table className="table table-borderless w-100">
                        <thead>
                            <tr>
                                <th className="col-2">{t('legislation:doc_no')}</th>
                                <th className="col-2">{t('legislation:accept_date')}</th>
                                <th className="col-6">{t('legislation:doc_name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {constitution.map(item =>
                                <tr>
                                    <td className="col-2">{item.no}</td>
                                    <td className="col-2">{item.date}</td>
                                    <td className="col-6"><p className='d-flex mb-0 text-underline text-justify'><a href={item.link} target="_blank" rel="noreferrer" className='mb-0 text-justify text-start'>{item.title}</a> <img src={img} className="p-4" /></p></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Constitution4 
