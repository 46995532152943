import { Swiper } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

import arrowl_0 from '../../../../assets/img/utils/gallery-arrow-l-0.svg';
import arrowr_0 from '../../../../assets/img/utils/gallery-arrow-r-0.svg';
import arrowl_1 from '../../../../assets/img/utils/gallery-arrow-l-1.svg';
import arrowr_1 from '../../../../assets/img/utils/gallery-arrow-r-1.svg';

import "swiper/css";
import "swiper/css/pagination";
import { useState } from "react";

const ProjectGallerySwiper = ({ children }) => {
    const [hover, setHover] = useState(false);
    const [hover2, setHover2] = useState(false);

    return (
        <>
            <div className="position-relative d-none d-md-block">
                <div className="swiper-button-prev-n d-md-flex d-none"
                    style={{
                        height: "400px",
                        transform: "translate(-110%, 0)",
                        top: "0",
                        zIndex: "2"
                    }}
                    onMouseEnter={_ => setHover(true)}
                    onMouseLeave={_ => setHover(false)}
                >
                    <img src={hover ? arrowl_1 : arrowl_0} style={{ height: "100px", margin: "auto", paddingRight: "13.5rem", userSelect: 'none' }} alt="" />
                </div>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={2}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5
                    }}
                    loop={true}
                    navigation={{
                        nextEl: '.swiper-button-next-n',
                        prevEl: '.swiper-button-prev-n',
                    }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="mySwiper my-4"
                    style={{ height: "400px" }}
                >
                    {children}
                </Swiper>
                <div className="swiper-button-next-n d-md-flex d-none"
                    style={{
                        height: "400px",
                        transform: "translate(110%, 0)",
                        top: "0",
                        zIndex: "2"
                    }}
                    onMouseEnter={_ => setHover2(true)}
                    onMouseLeave={_ => setHover2(false)}
                >
                    <img src={hover2 ? arrowr_1 : arrowr_0} style={{ height: "100px", margin: "auto", paddingLeft: "13.5rem", userSelect: 'none' }} alt="" />
                </div>
            </div>

            <div className="position-relative d-md-none d-block">
                <div className="swiper-button-prev-n d-md-flex d-none" style={{
                    height: "400px",
                    transform: "translate(-110%, 0)",
                    top: "0",
                    zIndex: "2"
                }}>
                    <img src={arrowl_1} style={{ height: "100px", margin: "auto" }} alt="" />
                </div>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={2}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 20,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next-n',
                        prevEl: '.swiper-button-prev-n',
                    }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="mySwiper my-4"
                    style={{ height: "200px" }}
                >
                    {children}
                </Swiper>
                <div className="swiper-button-next-n d-md-flex d-none" style={{
                    height: "400px",
                    transform: "translate(110%, 0)",
                    top: "0",
                    zIndex: "2"
                }}>
                    <img src={arrowr_1} style={{ height: "100px", margin: "auto" }} alt="" />
                </div>
            </div>

            {/* <div class="slider-container">
                <div class="slider-content">
                    {children}
                </div>
            </div> */}
        </>
    );
}

export default ProjectGallerySwiper;
