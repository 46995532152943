import React, { useState } from "react"
import Modal, { ModalProps } from "react-bootstrap/Modal" 
import styled from "styled-components" 
import { useTranslation } from "react-i18next" 

const ModalHeader = styled(Modal.Header)`
  background-color: #223A5E;
  color: white;
  border: none;
  display: flex;
  align-items: center;
`

const ModalContent = styled(Modal.Body)`
  color: #424650;
  padding: 0;
  height: 80vh;
`

interface IProps {
    title: string 
    link: string 
    children: React.ReactNode 
}

export const VideoModal: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation() 
  const { title, link, children } = props 

  const [isModalOpen, setIsModalOpen] = useState(false) 

  function openModal() {
    setIsModalOpen(true) 
  }

  function closeModal() {
    setIsModalOpen(false) 
  }

  return (
    <>
      <a onClick={openModal}>
        {children}
      </a>
      <Modal dialogClassName="modal-90w"
        show={isModalOpen}
        onHide={closeModal}
      >
        <ModalHeader closeButton>
            <Modal.Title>{title}</Modal.Title>
        </ModalHeader>
        <ModalContent>
            {link.includes("youtu") ? (
              <iframe
                title={title}
                width="100%"
                height="100%"
                src={link}
                frameBorder={0}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <video
                title={title}
                width="100%"
                height="100%"
                controls={true}
              >
                <source src={link} type="video/mp4"></source>
              </video>
            )}
        </ModalContent>
      </Modal>
    </>
  )
}
