import Accordions from './Accordions'
import GmsSharedBanner from './GmsSharedBanner'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../../components/Breadcrumb'

const Rules = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/elektron-xidmetler/gms',
            style: "pe-none",
            title: t('common:e_services'),
            isActive: false
        },
        {
            id: 2,
            link: '/elektron-xidmetler/gms',
            title: t('gms:gms'),
            isActive: false
        },
        {
            id: 3,
            link: '/elektron-xidmetler/gms/gms-kategoriyalar',
            title: t('gms:categories'),
            isActive: true
        }
    ]

    return (
        <div className='block-bg1 pt-md-3 pb-5'>
            <Breadcrumb data={breadcrumb} />
            <GmsSharedBanner />
            <div className="container" style={{ fontFamily: "Roboto Condensed" }}>
                <h2 className="text text-center my-5 t-color5 heading">GÜZƏŞTLƏ MƏNZİL ƏLDƏ ETMƏK HÜQUQUNA MALİK OLAN ŞƏXSLƏR<br />
                    VƏ TƏLƏB OLUNAN SƏNƏDLƏR:</h2>
                <Accordions />
            </div>
        </div>
    )
}

export default Rules 
