import { ApiClient, apiClient } from "../../../../core/api_client";
import { IFloor, floorFromJson } from "./entities";
import { Failure } from "../../../../core/failure";

interface IFloorRepo {
  getFloorDetail: (apartmentId: string, floorId: string) => Promise<IFloor>;
}

const FloorRepoImplFactory = (apiClient: ApiClient): IFloorRepo => {
  const r: IFloorRepo = {
    getFloorDetail: async (apartmentId, floorId) => {
      try {
        const res = await apiClient.get(`/getSectorFloorsApartments/${apartmentId}/${floorId}`);
        return floorFromJson(res.data);
      } catch (error: any) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const FloorRepoImpl = FloorRepoImplFactory(apiClient);
