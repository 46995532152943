// libraries
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

// components
import Breadcrumb from '../../components/Breadcrumb'

// assets
import updated_label_az from '../../../assets/img/updated_label_az.svg'

const SatishHesabatlari = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/hesabatlar/satis',
            title: t('agentlik:audits_sales'),
            isActive: true
        }
    ]

    const audit_card = [
        {
            id: 3,
            title: t('agentlik:audits_sales'),
            link: "/agentlik/hesabatlar/satis/satis-uzre"
        },
        {
            id: 4,
            title: t('agentlik:audits_sales_stats'),
            link: "/agentlik/hesabatlar/satis/menzil-secimi-statistikasi"
        }
    ]

    return (
        <div className="audit-page block-bg1 pt-md-3">
            <Breadcrumb data={breadcrumb} className="mb-0" style={{ height: 0 }} />
            <div className='bg-picture2'>
                <div className='container'>
                    <div className='audit-info row justify-content-center pt-60 pb-80 mx-auto'>
                        {audit_card.map(item =>
                            <Link to={item.link} className='text-center rounded-4 position-relative m-3 p-md-5 p-2 my-3 col-md-5 col-12' style={{ backgroundColor: "#223A5E" }} key={item.id}>
                                {item.isUpdated && <img className='position-absolute status-label-flag' src={updated_label_az} alt="Yenilənib" />}
                                <h2 className='py-5 t-color4'>{item.title}</h2>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SatishHesabatlari 