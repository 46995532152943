// libraries
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// components & functions
import { apiClient } from '../../core/api_client'
import Breadcrumb from '../../components/Breadcrumb'
import l from '../../core/localize'
import { Routing } from '../../core/routing'

const Cooperation = () => {
    const [coopData, setCoopData] = useState([])
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:about'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/nizamname',
            title: t('agentlik:order'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: false
        },
        {
            id: 4,
            link: '/agentlik/mida-mmc',
            title: t('agentlik:mida_mmc'),
            isActive: false
        },
        {
            id: 5,
            link: '/agentlik/beynelxalq-emekdasliq',
            title: t('agentlik:international'),
            isActive: true
        },
        {
            id: 6,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        }
    ]

    const ids = [11030, 10131, 10123, 10048, 10008, 10007]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.all(
                    ids.map((id) =>
                        apiClient.get(`/news/${id}`).then((response) =>
                            response
                        )
                    )
                );
                const objects = responses.map((response) => response.data); // Adjust the structure according to your API response

                setCoopData(objects);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="block-bg1 pt-lg-3 pb-md-5 py-0">
            <Breadcrumb data={breadcrumb} />

            <div className='container mt-4 mt-sm-5 mt-md-5'>
                <div className='text-inf'>
                    <p className="text">
                        {parse(t('agentlik:international_cooperation'))}
                    </p>
                </div>

                <div className='row news-info mt-3 mb-5 justify-content-center justify-content-md-start'>
                    {coopData && coopData.map(item =>
                        <Link to={"/agentlik/beynelxalq-emekdasliq/" + item.id} className='coop-item row col-12 my-4' key={item.id}>
                            <img className='col-sm-2' src={Routing.generateFileRoute(l(item, "file", currentLang))} alt="xeberler" />
                            <div className='col-sm-10 position-relative my-4 my-md-0'>
                                <h5 className='m-0'>{l(item, "name", currentLang)}</h5>
                                <small className="date mt-3">{dayjs(item.created_at).format('DD.MM.YYYY')}</small>
                                <small className='coop-text text-gradient mt-2'>{parse(l(item, "content", currentLang).substring(0, 220))}</small>
                            </div>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Cooperation 
