// libraries
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useEffect, useState } from 'react'
// components & functions
import { apiClient } from "../../../core/api_client"
import isNew from '../../../components/utils/isNew'
import l from "../../../core/localize"
import { Routing } from "../../../core/routing"
// assets
import arrow from "../../../../assets/img/utils/arrow-down.svg"
import arrow_right from "../../../../assets/img/utils/arrow_right_orange.svg"
import date from '../../../../assets/img/utils/date.svg'
import new_label_az from '../../../../assets/img/new_label_az.svg'

const NewsComponent = () => {
    const [news, setNews] = useState([])
    const [numComponents, setNumComponents] = useState(10)
    const [newsLength, setNewsLength] = useState([])
    const [isLoading, setLoading] = useState(true)
    const increment = 9
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    useEffect(() => {
        apiClient.get('/news/newsType/1')
            .then(function (response) {
                setNews(response.data)
                setNewsLength(response.data.length)
                setLoading(false)
            })
    }, [])

    return (
        <div id="news-page" className="mt-3 mb-3 row gx-5">
            {isLoading && <div className='d-flex justify-content-center align-items-center' style={{ height: '300px' }}><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
            {news && news.slice(1, numComponents).map(item =>
                <Link to={"/xeberler-ve-media/bizim-xeberler/" + item.id} className='col-lg-4 my-4 gx-5 hover-img' key={item.id}>
                    <div className="news-item2 news-item position-relative" style={{ background: "#FFFFFF", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.25)" }}>
                        <img src={Routing.generateFileRoute(l(item, "file", currentLang))} className="w-100" alt="..." style={{ height: "265px" }} />
                        {isNew(item.is_new) && <img className='position-absolute' style={{ top: '7px', right: '-33px' }} src={new_label_az} alt={t('common:new')} />}
                        <div className="card-body px-3 pt-4 text-center d-flex flex-column justify-content-between" style={{ minHeight: "170px" }}>
                            <small className="news-date mx-auto"><img src={date} alt="date-icon" className='me-2' />{dayjs(item.date).format('DD.MM.YYYY')}</small>
                            <p className="news-title mt-2">{l(item, "name", currentLang)}</p>
                            <span className="mt-auto">
                                <small className='m-0 highlight fw-bolder'>{t('common:show_details')}</small>
                                <img className="ms-2" src={arrow_right} alt="arrow-right" />
                            </span>
                        </div>
                    </div>
                </Link>
            )}

            {numComponents < newsLength ?
                <div className='d-flex justify-content-end pb-5'>
                    <button className='btn-show-more' onClick={() => setNumComponents(numComponents + increment)}>
                        {t('common:show_more')} <img src={arrow} className="ms-2" alt="" />
                    </button>
                </div>
                : null
            }
        </div>
    )
}

export default NewsComponent 
