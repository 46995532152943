// libraries
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

// components
import Breadcrumb from '../../components/Breadcrumb'

const Contact3 = () => {
    const { t } = useTranslation()

    const questions = [
        {
            id: 1,
            title: t('contact:q1'),
            text: t('contact:ans1')
        },
        {
            id: 2,
            title: t('contact:q2'),
            text: t('contact:ans2')
        },
        {
            id: 3,
            title: t('contact:q3'),
            text: t('contact:ans3')
        },
        {
            id: 4,
            title: t('contact:q4'),
            text: t('contact:ans4')
        },
        {
            id: 5,
            title: t('contact:q5'),
            text: t('contact:ans5')
        },
        {
            id: 6,
            title: t('contact:q6'),
            text: t('contact:ans6')
        },
        {
            id: 7,
            title: t('contact:q7'),
            text: t('contact:ans7')
        },
        {
            id: 8,
            title: t('contact:q8'),
            text: t('contact:ans8')
        },
        {
            id: 9,
            title: t('contact:q9'),
            text: t('contact:ans9')
        }
    ]

    const breadcrumb = [
        {
            id: 1,
            link: '/elaqe/elaqe-vasiteleri',
            title: t('contact:contact'),
            isActive: false
        },
        {
            id: 2,
            link: '/elaqe/metbuat-xidmeti',
            title: t('contact:press'),
            isActive: false
        },
        {
            id: 3,
            link: '/elaqe/en-cox-verilen-suallar',
            title: t('contact:faq'),
            isActive: true
        }
    ]

    return (
        <div className="contact-page block-bg1 pt-lg-3 pb-5">
            <Breadcrumb data={breadcrumb} />

            <div className='container mt-4 mt-sm-5'>
                <div className='contact-heading-text px-md-60 px-2 py-2 mt-2 mb-4'>
                    <h5 className="text-center">{t('contact:faq_text')}</h5>
                </div>
                <div className="accordion mt-3" style={{ fontFamily: "Roboto Condensed" }}>
                    {questions.map(item =>
                        <div className="accordion-item my-3" key={item.id}>
                            <h2 className="text accordion-header" id={"heading" + item.id}>
                                <button className="accordion-button collapsed h4 mb-0 d-flex" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + item.id}>
                                    <div className="arrow d-none d-md-flex">{t('contact:question')} {questions.indexOf(item) + 1}:</div> <h5 className='mb-0 ms-md-5 ps-md-3 w-75 fw-normal'>{item.title} </h5>
                                </button>
                            </h2>
                            <div id={"collapse" + item.id} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body d-flex ps-md-4 ps-0 pe-0">
                                    <div className='mt-4 d-none d-md-block'>
                                        <div className="arrow">{t('contact:answer')}:</div>
                                    </div>
                                    <div className='accordion-body-text px-md-5 px-3 py-3' style={{ fontFamily: "Helvetica" }}>
                                        <small style={{ fontSize: "1rem" }}>{item.text && parse(`${item.text}`)}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Contact3 
