import { Pie } from 'react-chartjs-2';
import { useEffect, useState } from 'react';

export function PieChart({ apiData }) {
    const [data, setData] = useState(null);

    console.log("template4", data)
    useEffect(() => {
        if (apiData && apiData.rows) {
            const filteredData = apiData.rows.filter(item => item.category !== "TOTAL");

            const labels = filteredData.map(item => item.category);
            const percentages = filteredData.map(item => item.percentage );

            setData({
                labels: labels,
                datasets: [{
                    data: percentages,
                    backgroundColor: ['#f79647', '#223a5e', '#8ba2c2'],
                    borderColor: ['#f7f7f7']
                }]
            });
        }
    }, [apiData]);

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    font: {
                        size: 14
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.formattedValue + '%';
                    }
                }
            }
        }
    }

    return (
        <>
            <div className='col-md-5 mx-auto'>{data && <Pie data={data} options={options} />}</div>
        </>
    );
}
