import img from '../../../assets/img/utils/link.svg'
import stratejiXerite from '../../../assets/files/menzil_teminatinin_inkisafina_dair_strateji_yol_xeritesi.pdf'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../components/Breadcrumb'

const Constitution5 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/qanunvericilik/ar-konstitusiyasi',
            title: t('legislation:constitution'),
            isActive: false
        },
        {
            id: 2,
            link: '/qanunvericilik/ar-mecelleri',
            title: t('legislation:codes'),
            isActive: false
        },
        {
            id: 3,
            link: '/qanunvericilik/ar-qanunlari',
            title: t('legislation:laws'),
            isActive: false
        },
        {
            id: 4,
            link: '/qanunvericilik/ar-prezidentinin-fermanlari',
            title: t('legislation:orders'),
            isActive: false
        },
        {
            id: 5,
            link: '/qanunvericilik/strateji-yol-xeriteleri',
            title: t('legislation:strategic'),
            isActive: true
        }
    ]

    const constitution = [
        {
            id: 8,
            no: " ",
            date: "06.12.2016",
            title: "“Azərbaycan Respublikasında uyğun qiymətə mənzil təminatının inkişafına dair Strateji Yol Xəritəsi”",
            link: stratejiXerite
        }
    ]

    return (
        <div className="constitution-page block-bg1 pt-md-3 pt-4 py-1">
            <Breadcrumb data={breadcrumb} />

            <div className='container'>
                <div className='mt-3 mb-5'>
                    <table className="table table-borderless w-100">
                        <thead>
                            <tr>
                                <th className="col-2">{t('legislation:doc_no')}</th>
                                <th className="col-2">{t('legislation:accept_date')}</th>
                                <th className="col-6">{t('legislation:doc_name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {constitution.map(item =>
                                <tr key={item.id}>
                                    <td className="col-2">{item.no}</td>
                                    <td className="col-2">{item.date}</td>
                                    <td className="col-6">
                                        <div className='d-flex'>
                                            <a href={item.link} target="_blank" rel="noreferrer" className='mb-0 text-justify text-start'>{item.title}</a>
                                            <img src={img} className="p-3" alt="..." />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Constitution5 
