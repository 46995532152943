import { PieChart } from '../charts/PieChart7'

const Template7 = ({ data, projectName }) => {
  return (
    <>
      <PieChart apiData={data} projectName={projectName} legend={true} />
    </>
  )
}

export default Template7 