// libraries
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

// components & functions
import Breadcrumb from '../../components/Breadcrumb'

// HAQQIMIZDA
const About1 = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:about'),
            isActive: true
        },
        {
            id: 2,
            link: '/agentlik/nizamname',
            title: t('agentlik:order'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: false
        },
        {
            id: 4,
            link: '/agentlik/mida-mmc',
            title: t('agentlik:mida_mmc'),
            isActive: false
        },
        {
            id: 5,
            link: '/agentlik/beynelxalq-emekdasliq',
            title: t('agentlik:international'),
            isActive: false
        },
        {
            id: 6,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        }
    ]

    return (
        <div className="block-bg1 pt-lg-3 pb-md-5 py-0">
            <Breadcrumb data={breadcrumb} />

            <div className='container px-md-120 mt-4 mt-sm-5 mt-md-5'>
                <div className='block-bg-white px-md-120 px-4 py-md-5 py-4 text-center rounded-4'>
                    <h4 className="heading mb-2 pb-3 t-color5 fw-700">{t('agentlik:about_heading1')}</h4>
                    <div className='px-md-60'>
                        <p className="text-inf">{parse(t('agentlik:about_text1'))}</p>
                        <h4 className="heading mt-5 mb-0 t-color5 fw-700">{t('agentlik:about_heading2')}</h4>
                        <p className="text-inf mt-2">{parse(t('agentlik:about_text2'))}</p>
                        <h4 className="heading mt-5 mb-0 t-color5 fw-700">{t('agentlik:about_heading3')}</h4>
                        <p className="text-inf mt-2">{parse(t('agentlik:about_text3'))}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About1 
