import { ICoordinate, IShape } from "../entites";

export function polygonArea(vertices: ICoordinate[]) {
  let area = 0;
  for (let i = 0; i < vertices.length; i++) {
    const j = (i + 1) % vertices.length;
    area += vertices[i].x * vertices[j].y;
    area -= vertices[j].x * vertices[i].y;
  }
  return area / 2;
}

export function isShapeClockWise(shape: IShape) {
  const point1 = shape.points[0];
  const point2 = shape.points[1];
  const point3 = shape.points[2];

  const area = polygonArea([point1, point2, point3]);

  return area > 0;
}

export function invertShape(shape: IShape, width: number, height: number): IShape {
  const isClockWise = isShapeClockWise(shape);
  const points = isClockWise ? shape.points : shape.points.slice().reverse();

  return {
    id: "",
    closed: true,
    points: [
      {
        x: 0,
        y: 0,
      },
      {
        x: 0,
        y: height,
      },
      {
        x: width,
        y: height,
      },
      {
        x: width,
        y: 0,
      },
      {
        x: 0,
        y: 0,
      },
      shape.points[0],
      ...points,
      shape.points[0],
      {
        x: 0,
        y: 0,
      },
    ],
  };
}

export interface IPosition {
  left: number | "auto";
  right: number | "auto";
  top: number | "auto";
  bottom: number | "auto";
}

export function calcShapeCoordinates(
  svgPosition: DOMRect | undefined,
  shapePosition: DOMRect | undefined,
): IPosition | undefined {
  if (!svgPosition) {
    return;
  }

  const relativePosition = {
    left: (shapePosition?.left ?? 0) - (svgPosition?.left ?? 0),
    right: (svgPosition?.right ?? 0) - (shapePosition?.right ?? 0),
    top: (shapePosition?.top ?? 0) - (svgPosition?.top ?? 0),
    bottom: (svgPosition?.bottom ?? 0) - (shapePosition?.bottom ?? 0),
  };

  const isLeft = relativePosition.left < relativePosition.right;
  const isTop = relativePosition.top < relativePosition.bottom;
  const margin = 30;

  const calculatedLeft = relativePosition.left + (shapePosition?.width ?? 0) + margin;
  const calculatedRight = relativePosition.right + (shapePosition?.width ?? 0) + margin;

  if (isLeft && isTop) {
    return {
      left: calculatedLeft,
      right: "auto",
      top: relativePosition.top,
      bottom: "auto",
    };
  }

  if (isLeft && !isTop) {
    return {
      left: calculatedLeft,
      right: "auto",
      top: "auto",
      bottom: relativePosition.bottom,
    };
  }

  if (!isLeft && isTop) {
    return {
      left: "auto",
      right: calculatedRight,
      top: relativePosition.top,
      bottom: "auto",
    };
  }

  if (!isLeft && !isTop) {
    return {
      left: "auto",
      right: calculatedRight,
      top: "auto",
      bottom: relativePosition.bottom,
    };
  }
}
