import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { apiClient } from '../core/api_client'
// import parse from 'html-react-parser'
// import search_icon from '../../assets/img/utils/search-white.svg'
// import { Link } from 'react-router-dom'

const ModalHeader = styled(Modal.Header)`
  background-color: #223A5E;
  color: white;
  border: none;
  display: flex;
  align-items: center;
`

// const ModalContent = styled(Modal.Body)`
//   color: #424650;
//   padding: 0;
// `

interface IProps {
    children: React.ReactNode
    className: string
}

interface Result {
    name_az: string
    total_count: string
    gov_order_count: string
    sold_count: string
    entityEnum: string
    id: any
}

export const ProjectsModal: React.FC<IProps> = (props: IProps) => {
    // const [searchQuery, setSearchQuery] = useState('')
    const [results, setResults] = useState<Result[]>()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation()
    // const currentLang = i18n.language.slice(0, 2)
    const { children } = props

    function openModal() {
        setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }

    useEffect(() => {
        apiClient.get(`/projectPlans`)
            .then(response => {
                setResults(response.data)
                setIsLoading(false)
            })
    }, [])

    return (
        <>
            <div onClick={openModal} className={"d-flex " + props.className} style={{
                marginBottom: '0.4rem',
                padding: '0.2rem 1rem'
            }}>
                {children}
            </div>
            <Modal
                className='search-modal'
                show={isModalOpen}
                onHide={closeModal}
            >
                <ModalHeader closeButton closeVariant='white'>
                    <Modal.Title>{t('common:projects_info')}</Modal.Title>
                </ModalHeader>
                <div className='modal-content'>
                    {isLoading ? (
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div>
                    ) : (
                        results && <div className='p-5' style={{ overflowY: 'auto', height: '70vh' }}>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Layihə</th>
                                        <th>Mənzillərin ümumi sayı</th>
                                        <th>Dövlət sifarişli mənzillərin sayı</th>
                                        <th>Satılmış mənzillərin sayı</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results.map(item =>
                                        <tr key={item.id} className='cursor'>
                                            <td>{results.indexOf(item) + 1}</td>
                                            <td><a href={'/layihe/' + item.id} className='t-color5 text-underline'>{item.name_az}</a></td>
                                            <td>{item.total_count}</td>
                                            <td>{item.gov_order_count}</td>
                                            <td>{item.sold_count}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>)}
                </div>
            </Modal>
        </>
    )
}

