import { useEffect, useState } from "react"

const Table2 = ({ apiData, projectName }) => {
    const [tableRows, setTableRows] = useState();

    function search(nameKey, myArray) {
        for (let i = 0; i < myArray?.length; i++) {
            if (myArray[i].roomsCount === nameKey) {
                return myArray[i].value
            }
        }
    }

    useEffect(() => {
        const total = apiData.rows?.filter(item => item.category === "TOTAL")
        const rows = apiData.rows?.filter(item => item.category !== "TOTAL")
        setTableRows(rows.concat(total))
    }, [apiData]);

    return (
        <div className="mt-4 w-100">
            {apiData && (
                <table id="table2" className='table table-bordered table-responsive'>
                    <thead>
                        <tr>
                            <th colSpan={18} rowSpan={1} className="table-header">
                                {projectName ? projectName + "ndə " + apiData.title.toLowerCase() : apiData.title}
                            </th>
                        </tr>
                        <tr>
                            <th rowSpan={3} colSpan={6} className="table-category-header">Kateqoriya</th>
                            <th colSpan={12}>
                                Mənzilin alqı-satqısı zamanı ödəniş üsulu və mənzilin otaq sayı
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={4}>
                                Öz vəsaiti
                            </th>
                            <th colSpan={4}>
                                İpoteka krediti
                            </th>
                            <th rowSpan={2} className="p-4">
                                Cəmi
                            </th>
                        </tr>
                        <tr>
                            <th>1 otaqlı</th>
                            <th>2 otaqlı</th>
                            <th>3 otaqlı</th>
                            <th>4 otaqlı</th>
                            <th>1 otaqlı</th>
                            <th>2 otaqlı</th>
                            <th>3 otaqlı</th>
                            <th>4 otaqlı</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows && tableRows.map(row =>
                            <tr key={tableRows.indexOf(row)}>
                                <td colSpan={6} className={`text-${row.category === "TOTAL" ? "center" : "start"}`} style={{'width': '58%'}}>{row.category === "TOTAL" ? "CƏMİ" : row.category}</td>
                                <td>{search(1, row.cash) > 0 && search(1, row.cash)}</td>
                                <td>{search(2, row.cash) > 0 && search(2, row.cash)}</td>
                                <td>{search(3, row.cash) > 0 && search(3, row.cash)}</td>
                                <td>{search(4, row.cash) > 0 && search(4, row.cash)}</td>
                                <td>{search(1, row.loan) > 0 && search(1, row.loan)}</td>
                                <td>{search(2, row.loan) > 0 && search(2, row.loan)}</td>
                                <td>{search(3, row.loan) > 0 && search(3, row.loan)}</td>
                                <td>{search(4, row.loan) > 0 && search(4, row.loan)}</td>
                                <td colSpan={2}>{row.totalCount > 0 ? row.totalCount : ""}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default Table2 
