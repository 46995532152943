import React from "react"
import classes from "./back_image.module.scss"
import defaultBgImg from "../../../assets/img/bg.jpg"

interface IProps {
  imgUrl?: string
}

export const BackImage: React.FC<IProps> = ({ imgUrl = defaultBgImg }: IProps) => {
  return (
    <>
      <div className={classes.img} style={{ backgroundImage: `url(${imgUrl})` }}></div>
      <div className={classes.overlay}></div>
    </>
  )
} 
