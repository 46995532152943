const Table14 = ({ apiData }) => {
    return (
        <>
            {apiData && (
                <table id="table14" className="table table-bordered table-responsive mb-0">
                    <thead>
                        <tr>
                            <th className="px-5 py-1">Bank</th>
                            <th className="py-1">Verilən kreditlərin sayı</th>
                            <th className="py-1">Verilən kreditlərin məbləği <br></br>(manatla)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiData.rows && apiData.rows?.map(row =>
                            <tr key={apiData.rows.indexOf(row)}>
                                <td className={`text-${row.name === "TOTAL" ? "center" : "start"}`}>{row.name === "TOTAL" ? "CƏMİ" : row.name}</td>
                                <td>{row.count}</td>
                                <td className="text-end">{row.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default Table14
