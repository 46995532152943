const RulesList = () => {
    return (
        <div className="container">
            <h3 className="text-center t-color5 py-5">“Elm haqqında” Azərbaycan Respublikasının Qanununda nəzərdə tutulan tələblərə uyğun gələn elmi müəssisə və təşkilatların<br /> SİYAHISI
            </h3>

            <p className="t-color1 py-4 mb-3">
                <b>1. Azərbaycan Milli Elmlər Akademiyası üzrə: </b>
                <br/>
                <br/>1.1 Respublika Seysmoloji Xidmət Mərkəzi
                <br/>1.2 N.Gəncəvi adına Milli Azərbaycan Ədəbiyyatı İnstitutu
                <br/>1.3 Nəsimi adına Dilçilik İnstitutu
                <br/>1.4 Folklor İnstitutu
                <br/>1.5 Memarlıq və İncəsənət İnstitutu
                <br/>1.6 M.Füzuli adına Əlyazmalar İnstitutu
                <br/>1.7 A.Bakıxanov adına Tarix İnstitutu
                <br/>1.8 Arxeologiya, Etnoqrafiya və Antropologiya İnstitutu
                <br/>1.9 Fəlsəfə və Sosiologiya İnstitutu
                <br/>1.10 Akademik Z.Bünyadov adına Şərqşünaslıq İnstitutu
                <br/>1.11 Qafqazşünaslıq İnstitutu
                <br/>1.12 “Azərbaycan Milli Ensiklopediyası" Elmi Mərkəzi
                <br/>1.13 Lənkəran Regional Elmi Mərkəzi
                <br/>1.14 Şəki Regional Elmi Mərkəzi
                <br/>1.15 Mərkəzi Elmi Kitabxana
                <br/>1.16 “Elm” Nəşriyyatı
                <br/><br/>
                Naxçıvan bölməsinin:
                <br/><br/>
                1.17 Tarix, Etnoqrafiya və Arxeologiya İnstitutu
                <br/>1.18 İncəsənət, Dil və Ədəbiyyat İnstitutu
                <br/>1.19 Əlyazmalar Fondu
                <br/><br/>
                Gəncə bölməsinin:
                <br/><br/>
                1.20 Humanitar Tədqiqatlar İnstitutu
                <br/>1.21 Diyarşünaslıq İnstitutu
                <br/>1.22 Nizami Gəncəvi Mərkəzi
                <br/><br/>
                <b>2. Azərbaycan Respublikasının Elm və Təhsil Nazirliyi üzrə:</b>
                <br/><br/>
                2.1 Azərbaycan Respublikasının Təhsil İnstitutu;
                <br/>2.2 Bakı Dövlət Universitetinin Tətbiqi Riyaziyyat Elmi-Tədqiqat İnstitutu
                <br/>2.3 Bakı Dövlət Universitetinin Fizika Problemləri Elmi-Tədqiqat İnstitutu
                <br/>2.4 Bakı Dövlət Universitetinin «İnkişaf» Respublika Elmi-Tədqiqat Mərkəzi
                <br/>2.5 Bakı Dövlət Universitetinin İslamşünaslıq Elmi-Tədqiqat Mərkəzi
                <br/>2.6 Bakı Dövlət Universitetinin NANO Araşdırmalar Elmi-Tədqiqat Mərkəzi
                <br/>2.7 Azərbaycan Neft və Sənaye Universitetinin “Neftin, qazın geotexnoloji problemləri və kimya” Elmi-Tədqiqat İnstitutu
                <br/>2.8 Azərbaycan Neft və Sənaye Universitetinin "İdarəetmə proseslərinin avtomatlaidırılması" Elmi-Tədqiqat İnstitutu
                <br/>2.9 Azərbaycan Texniki Universitetinin “Maşınşünaslıq” Elmi-Tədqiqat Mərkəzi
                <br/>2.10 Azərbaycan Dövlət İqtisad Universitetinin İqtisadi Araşdırmalar Elmi Tədqiqat İnstitutu
                <br/>2.11 Fizika İnstitutu
                <br/>2.12 Riyaziyyat və Mexanika İnstitutu
                <br/>2.13 İdarəetmə Sistemləri İnstitutu
                <br/>2.14 Radiasiya Problemləri İnstitutu
                <br/>2.15 İnformasiya Texnologiyaları İnstitutu
                <br/>2.16 Biofizika İnstitutu
                <br/>2.17 N.Tusi adına Şamaxı Astrofizika Rəsədxanası
                <br/>2.18 Akademik Y.Məmmədəliyev adına Neft-Kimya Prosesləri İnstitutu
                <br/>2.19 Akademik M.Nağıyev adına Kataliz və Qeyri-üzvi Kimya institutu
                <br/>2.20 Polimer Materialları İnstitutu
                <br/>2.21 Akademik Ə.Quliyev adına Aşqarlar Kimyası İnstitutu
                <br/>2.22 Geologiya və Geofizika İnstitutu
                <br/>2.23 Neft və Qaz İnstitutu
                <br/>2.24 Akademik H.Əliyev adına Coğrafiya İnstitutu
                <br/>2.25 Təbiət Tarixi Muzeyi
                <br/>2.26 Botanika İnstitutu
                <br/>2.27 Zoologiya İnstitutu
                <br/>2.28 Akademik A.Qarayev adına Fiziologiya İnstitutu
                <br/>2.29 Molekulyar Biologiya və Biotexnologiyalar İnstitutu
                <br/>2.30 Mikrobiologiya İnstitutu
                <br/>2.31 Dendrologiya İnstitutu
                <br/>2.32 Torpaqşünaslıq və Aqrokimya İnstitutu
                <br/>2.33 Genetik Ehtiyatlar İnstitutu
                <br/>2.34 İqtisadiyyat İnstitutu
                <br/><br/>
                Naxçıvan üzrə:
                <br/><br/>
                2.35 Təbii Ehtiyatlar İnstitutu
                <br/>2.36 Bioresurslar institutu
                <br/>2.37 Batabat Astrofizika Rəsədxanası
                <br/><br/>
                Gəncə üzrə:
                <br/><br/>
                2.38 Bioresurslar institutu
                <br/>2.39 Ekologiya və Təbii Ehtiyatlar İnstitutu
                <br/>2.40 Aqrar Problemlər İnstitutu
                <br/>2.41 Nəbatat Bağı
                <br/><br/>
                <b>3. Azərbaycan Respublikasının Səhiyyə Nazirliyi üzrə:</b>
                <br/><br/>
                3.1 Milli Onkologiya Mərkəzi
                <br/>3.2 Akademik Z.Əliyeva adına Milli Oftalmologiya Mərkəzi
                <br/>3.3 Milli Hematologiya və Transfuziologiya Mərkəzi
                <br/>3.4 Akademik M.Topçubaşov adına Elmi Cərrahiyyə Mərkəzi
                <br/>3.5 Akademik C. Abdullayev adına Elmi-Tədqiqat Kardiologiya İnstitutu
                <br/>3.6 K.Fərəcova adına Elmi-Tədqiqat Pediatriya İnstitutu
                <br/>3.7 Elmi-Tədqiqat Mamalıq-Ginekologiya İnstitutu
                <br/>3.8 Elmi-Tədqiqat Tibbi Bərpa İnstitutu
                <br/>3.9 Elmi-Tədqiqat Travmatologiya və Ortopediya İnstitutu
                <br/>3.10 V.Axundov adına Elmi-Tədqiqat Tibbi Profilaktika İnstitutu
                <br/>3.11 Elmi-Tədqiqat Ağ Ciyər Xəstəlikləri İnstitutu
                <br/>3.12 Ə.Əliyev adına Dövlət Həkimləri Təkmilləşdirmə İnstitutu
                <br/><br/>
                <b>4. Azərbaycan Respublikasının Kənd Təsərrüfatı Nazirliyi üzrə:</b>
                <br/><br/>
                4.1 Aqrar Tədqiqatlar Mərkəzi
                <br/>4.2 Əkinçilik Elmi-Tədqiqat İnstitutu
                <br/>4.3 Baytarlıq Elmi-Tədqiqat İnstitutu
                <br/>4.4 Tərəvəzçilik Elmi-Tədqiqat İnstitutu
                <br/>4.5 Bitki Mühafizə və Texniki Bitkilər Elmi-Tədqiqat İnstitutu
                <br/>4.6 Üzümçülük və Şərabçılıq Elmi-Tədqiqat İnstitutu
                <br/>4.7 Heyvandarlıq Elmi-Tədqiqat İnstitutu
                <br/>4.8 Meyvəçilik və Çayçılıq Elmi-Tədqiqat İnstitutu
                <br/>4.9 Azərbaycan Dövlət Aqrar Universitetinin Aqromexanika Elmi-Tədqiqat İnstitutu
                <br/><br/>
                <b>5. Azərbaycan Respublikasının Fövqəladə Hallar Nazirliyi üzrə:</b>
                <br/><br/>
                5.1 S.Dadaşov adına Elmi-Tədqiqat və Layihə-Konstruktor İnşaat Materialları İnstitutu
                <br/>5.2 Azərbaycan Dövlət Əməyin Mühafizəsi və Təhlükəsizliyi Texnikası Elmi-Tədqiqat institutu
                <br/><br/>
                <b>6. Azərbaycan Respublikasının Müdafiə Sənayesi Nazirliyi üzrə:</b>
                <br/><br/>
                6.1 Milli Aerokosmik Agentliyi (МАКА)
                <br/><br/>
                <b>7. Azərbaycan Respublikasının Rəqəmsal İnkişaf və Nəqliyyat Nazirliyi üzrə:</b>
                <br/><br/>
                7.1 Yüksək Texnologiyalar üzrə Tədqiqat Mərkəzi
                <br/><br/>
                <b>8. Azərbaycan Respublikasının iqtisadiyyat Nazirliyi üzrə:</b>
                <br/><br/>
                8.1 İqtisadi Elmi-Tədqiqat İnstitutu
                <br/><br/>
                <b>9. Azərbaycan Respublikasının Ədliyyə Nazirliyi üzrə:</b>
                <br/><br/>
                9.1 Məhkəmə Ekspertiza Mərkəzi
                <br/><br/>
                <b>10. Azərbaycan Respublikasının Maliyyə Nazirliyi üzrə:</b>
                <br/><br/>
                10.1 Maliyyə Elm-Tədris Mərkəzi
                <br/><br/>
                <b>11. Azərbaycan Respublikasının Mədəniyyət Nazirliyi üzrə:</b>
                <br/><br/>
                11.1 Mədəniyyətşünaslıq üzrə Elmi-Metodoloji Mərkəz
                <br/>11.2 Milli Azərbaycan Tarixi Muzeyi
                <br/>11.3 Nizami Gəncəvi adına Milli Azərbaycan Ədəbiyyatı Muzeyi
                <br/>11.4 Hüseyn Cavidin Ev Muzeyi
                <br/><br/>
                <b>12. Azərbaycan Respublikasının Dövlət Şəhərsalma və Arxitektura Komitəsi üzrə:</b>
                <br/><br/>
                12.1 Azərbaycan İnşaat və Memarlıq Elmi-Tədqiqat İnstitutu
                <br/><br/>
                <b>13. Azərbaycan Respublikasının Dövlət Statistika Komitəsi üzrə:</b>
                <br/><br/>
                13.1 Elmi - Tədqiqat və Statistik İnnovasiyalar Mərkəzi
                <br/><br/>
                <b>14. Azərbaycan Respublikasının Dövlət Neft Şirkəti üzrə:</b>
                <br/><br/>
                14.1 “Neftqazelmitədqiqatlayihə” İnstitutu
                <br/><br/>
                <b>15. “Azərenerji” Açıq Səhmdar Cəmiyyəti üzrə:</b>
                <br/><br/>
                15.1 Azərbaycan Elmi-Tədqiqat və Layihə-Axtarış Energetika İnstitutu (MMC)
                <br/><br/>
                <b>16. Azərbaycan Meliorasiya və Su Təsərrüfatı Açıq Səhmdar Cəmiyyəti üzrə:</b>
                <br/><br/>
                16.1 Azərbaycan Meliorasiya Elmi-Tədqiqat İnstitutu (MMC)
                <br/><br/>
                <b>17. “Azərsu” Açıq Səhmdar Cəmiyyəti üzrə:</b>
                <br/><br/>
                17.1 "Sukanal” Elmi-Tədqiqat və Layihə İnstitutu
                <br/><br/>
                <b>18. “Azərbaycan Xəzər Dəniz Gəmiçiliyi” Qapalı Səhmdar Cəmiyyəti üzrə:</b>
                <br/><br/>
                18.1 Xəzər Dəniz Nəqliyyat Layihə-Axtarış və Elmi-Tədqiqat İnstitutu
                <br/><br/>
                <b>19. Dövlət ali təhsil müəssisələrinin laboratoriyaları və elmi fəaliyyət üzrə dövlət sifarişlərini yerinə yetirən digər elmi müəssisələr.</b>
            </p>
        </div>
    )
}

export default RulesList
