// libraries
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// funtions & components
import { apiClient } from '../../../core/api_client'
import isNew from '../../../components/utils/isNew'
import l from '../../../core/localize'

const Announcements = ({ paginate = false, announcementsOnPage = 6 }) => {
    const [announcements, setAnnouncements] = useState([])
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    useEffect(() => {
        // apiClient.get('/announcements').then(res => setAnnouncements(res.data.filter(i => i.id !== 22)))
        apiClient.get('/announcements').then(res => setAnnouncements(res.data))
    }, [])

    const [page, setPage] = useState(0)
    let totalPages = Math.ceil(announcements.length / announcementsOnPage)
    let firstActivePage = page - 2
    if (firstActivePage < 1) {
        firstActivePage = 1
    } else if (totalPages - firstActivePage < 4) {
        firstActivePage = totalPages - 4
    }
    let activePages = []
    for (let i = firstActivePage; i <= Math.min(firstActivePage + 4, totalPages); i++) {
        activePages.push(i)
    }

    return (
        <>
            {announcements.slice(page * announcementsOnPage, (page + 1) * announcementsOnPage).map(item =>
                <Link to={'/elektron-xidmetler/satis-elanlari/' + item.id} className='announcements-item col-lg-4 col-md-6 col-12 mb-md-3 mt-5' key={item.id}>
                    <div className='position-relative t-color1 hover-img' style={{ width: "100%" }}>
                        {isNew(item.is_new) && <div className='label'>{t('common:new')}</div>}
                        <h5 className='text my-3 fw-normal'>{l(item, "name", currentLang)}</h5>
                        <small className="text">{dayjs(item.date).format('DD.MM.YYYY')}</small>
                    </div>
                </Link>
            )}
            {announcements.length === 0 && <h4 className="col-12 text-center py-4">{t('common:no_content')}</h4>}
            {paginate && announcements.length > 0 && <div className="col-12">
                <div className="pagination d-flex justify-content-center">
                    <button className="btn btn-outline-primary" disabled={page === 0} onClick={() => setPage(0)}>&lt;&lt;</button>
                    <button className="btn btn-outline-primary" disabled={page === 0} onClick={() => setPage(page - 1)}>&lt;</button>
                    {activePages.map(item =>
                        <button
                            className={`btn btn-outline-primary ${item - 1 === page ? 'active' : ''}`}
                            disabled={item - 1 === page}
                            onClick={() => setPage(item - 1)}
                            key={item}
                        >
                            {item}
                        </button>
                    )}
                    <button className="btn btn-outline-primary" disabled={page === totalPages - 1} onClick={() => setPage(page + 1)}>&gt;</button>
                    <button className="btn btn-outline-primary" disabled={page === totalPages - 1} onClick={() => setPage(totalPages - 1)}>&gt;&gt;</button>
                </div>
            </div>}
        </>
    )
}

export default Announcements 
