// libraries
import { Link } from "react-router-dom"
import parse from 'html-react-parser'
import { useTranslation } from "react-i18next"

// components
import Breadcrumb from '../../components/Breadcrumb'
import arrow_right from "../../../assets/img/utils/lil-arrow-r.svg"

// assets
import img from '../../../assets/img/sadiqsadiqov.png'
import img2 from '../../../assets/img/elshad-m.png'
import img3 from '../../../assets/img/chingiz-q.png'

// RƏHBƏRLİK 
const Management = () => {
    const { t } = useTranslation()

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:about'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/nizamname',
            title: t('agentlik:order'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/rehberlik',
            title: t('agentlik:management'),
            isActive: true
        },
        {
            id: 4,
            link: '/agentlik/mida-mmc',
            title: t('agentlik:mida_mmc'),
            isActive: false
        },
        {
            id: 5,
            link: '/agentlik/beynelxalq-emekdasliq',
            title: t('agentlik:international'),
            isActive: false
        },
        {
            id: 6,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        }
    ]

    const people = [
        {
            id: 0,
            img: img,
            position: t('agentlik:m_sadiq_position'),
            fullname: t('agentlik:m_sadiq_fullname'),
            about: t('agentlik:m_sadiq_about_short')
        },
        {
            id: 1,
            img: img3,
            position: t('agentlik:m_chingiz_position'),
            fullname: t('agentlik:m_chingiz_fullname'),
            about: t('agentlik:m_chingiz_about_short')
        },
        {
            id: 2,
            img: img2,
            position: t('agentlik:m_elshad_position'),
            fullname: t('agentlik:m_elshad_fullname'),
            about: t('agentlik:m_elshad_about_short')
        }
    ]

    return (
        <div className="management-page block-bg1 pt-lg-3 pb-md-5 py-0">
            <Breadcrumb data={breadcrumb} />

            <div className='container mt-5'>
                {people.map(item =>
                    <div className="row mt-5 mb-md-5 mx-auto">
                        <div className="col-md-6">
                            <Link to={"/agentlik/rehberlik/etrafli/" + item.id} style={{ height: "300px" }}>
                                <img src={item.img} alt={item.title} className="w-100" style={{ borderRadius: "22px" }} />
                            </Link>
                        </div>
                        <Link to={"/agentlik/rehberlik/etrafli/" + item.id} className="col-md-5 ps-md-5 mb-md-5 ps-2 mt-md-0 mt-3">
                            <div>
                                <h5 className="t-color5">{item.position}</h5>
                                <p className="mb-4 t-color3" style={{ fontSize: "24px" }}>{item.fullname}</p>
                                <small className="text-justify t-color1 mt-1" style={{ fontSize: "20px" }}>{parse(item.about)}</small>
                            </div>
                            <div className="mt-4 mb-3 d-flex justify-content-end">
                                <Link className="text more-info" to={"/agentlik/rehberlik/etrafli/" + item.id}>
                                    <small className="t-color5 fw-bolder">
                                        {t('common:show_details')}
                                        <img src={arrow_right} height={15} className="ms-2" alt="" />
                                    </small>
                                </Link>
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Management 
