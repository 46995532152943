// libraries
import parse from 'html-react-parser'
import { Link, useHistory, useLocation } from "react-router-dom"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"

// components & functions
import { LangSelector } from "../lang_selector"
import { SearchModal } from "../search_modal"
import { apiClient } from '../../core/api_client'
import l from "../../core/localize"

// assets
import logo1 from "../../../assets/img/logo/logo-nav1_1.svg"
import search from "../../../assets/img/utils/search.svg"
import fb from "../../../assets/img/socials/facebook_dark.svg"
import insta from "../../../assets/img/socials/instagram_dark.svg"
import tw from "../../../assets/img/socials/twitter_dark.svg"
import yt from "../../../assets/img/socials/youtube_dark.svg"
import photolib from "../../../assets/img/utils/photo-lib.svg"
import video from "../../../assets/img/utils/video.svg"

const Navbar = () => {
	let location = useLocation()
	const history = useHistory()
	const [isMobileMenuOpen, setMobileMenu] = useState(false)
	const [navbar, setNavbar] = useState(false)
	const { t, i18n } = useTranslation()
	const currentLang = i18n.language

	useEffect(() => {
		apiClient.get('/projectCategories').then(res => {
			let i = 2
			const categs = res.data.map(item => ({
				id: i++,
				title: l(item, "name", currentLang),
				link: "/layiheler/" + item.id,
			}))
			const menuItem = defaultMenu.find(item => item.id == 3)
			menuItem.submenu = [menuItem.submenu[0], ...categs]
			setMenu([...defaultMenu])
		})

		history.listen(handleLocationChange)
	}, [])

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 72) {
				setNavbar(true)
			} else {
				setNavbar(false)
			}
		})
	}, [window])

	const handleLocationChange = () => {
		setMobileMenu(false)
	}

	const defaultMenu = [
		{
			id: 1,
			title: t('agentlik:agentlik'),
			kword: "agentlik",
			link: '/agentlik/haqqimizda',
			submenu: [
				{ id: 1, title: t('agentlik:about'), link: "/agentlik/haqqimizda" },
				{ id: 2, title: t('agentlik:order'), link: "/agentlik/nizamname" },
				{ id: 3, title: t('agentlik:management'), link: "/agentlik/rehberlik" },
				{ id: 4, title: t('agentlik:mida_mmc'), link: "/agentlik/mida-mmc" },
				{ id: 5, title: t('agentlik:international'), link: "/agentlik/beynelxalq-emekdasliq" },
				{ id: 6, title: t('agentlik:audits'), link: "/agentlik/hesabatlar" }
			]
		},
		{
			id: 2,
			title: t('legislation:legislation'),
			kword: "qanunvericilik",
			link: '/qanunvericilik/ar-konstitusiyasi',
			submenu: [
				{ id: 1, title: t('legislation:constitution'), link: "/qanunvericilik/ar-konstitusiyasi" },
				{ id: 2, title: t('legislation:codes'), link: "/qanunvericilik/ar-mecelleri" },
				{ id: 3, title: t('legislation:laws'), link: "/qanunvericilik/ar-qanunlari" },
				{ id: 4, title: t('legislation:orders'), link: "/qanunvericilik/ar-prezidentinin-fermanlari" },
				{ id: 5, title: t('legislation:strategic'), link: "/qanunvericilik/strateji-yol-xeriteleri" }
			]
		},
		{
			id: 3,
			title: t('projects:projects'),
			kword: "layiheler",
			link: '/layiheler',
			submenu: [
				{ id: 1, title: t('projects:all_projects'), link: "/layiheler" },
			]
		},
		{
			id: 4,
			title: t('common:e_services'),
			kword: "elektron-xidmetler",
			link: '/elektron-xidmetler/gms',
			submenu: [
				{ id: 1, title: t('common:gms'), link: "/elektron-xidmetler/gms" },
				{ id: 2, title: t('common:kommunal'), link: "https://kommunal.mida.gov.az/search" }
			]
		},
		{
			id: 5,
			title: t('common:news_media'),
			kword: "xeberler-ve-media",
			link: '/xeberler-ve-media/bizim-xeberler',
			submenu: [
				{ id: 1, title: t('common:our_news'), link: "/xeberler-ve-media/bizim-xeberler" },
				{ id: 2, title: t('common:multimedia'), link: "/xeberler-ve-media/multimedia" }
			]
		},
		{
			id: 6,
			title: t('common:contact_us'),
			kword: "elaqe",
			link: '/elaqe/elaqe-vasiteleri',
			submenu: [
				{ id: 1, title: t('contact:contact'), link: "/elaqe/elaqe-vasiteleri" },
				{ id: 2, title: t('contact:press'), link: "/elaqe/metbuat-xidmeti" },
				{ id: 3, title: t('contact:faq'), link: "/elaqe/en-cox-verilen-suallar" }
			]
		}
	]
	const [menu, setMenu] = useState(defaultMenu)

	useEffect(() => {
		apiClient.get('/projectCategories').then(res => {
			let i = 2;
			const categs = res.data.map(item => ({
				id: i++,
				title: item.name_az,
				link: "/layiheler/" + item.id,
			}));
			const menuItem = defaultMenu.find(item => item.id === 3);
			menuItem.submenu = [menuItem.submenu[0], ...categs];
			setMenu([...defaultMenu]);
		});

		history.listen(handleLocationChange);
	}, [history]);

	return (
		<header id="hd" className="pb-lg-1 pt-md-3 pb-sm-3">

			{/* MENU DESKTOP SHRINKED */}
			<div className={navbar ? "menu-shrinked-desktop position-fixed block-bg1 top-0 px-5 py-2 w-100 visible" : "invisible"} style={{ zIndex: "1000" }}>
				<nav>
					<div className="d-flex justify-content-between">
						<Link to="/" className="d-flex align-items-center m-md-0 m-3">
							<img src={logo1} alt="logo" width={60} />
						</Link>

						<div className="nav-menu mt-4 d-flex justify-content-between w-75">
							{menu.map(item =>
								<div className={"dropdown-hover-btn dropdown " + (location.pathname.includes(item.kword) && "active")} key={item.id + Math.random() * 100}>
									<Link to={item.submenu[0].link}
										className="li dropdown-toggle"
										role="button"
										id="dropdownMenuLink"
									>
										{item.title}
									</Link>
									<div className="dropdown-menu py-1">
										{item.submenu.map(menu => menu.link.includes('http') ?
											<a className="li dropdown-item" href={menu.link} key={menu.id}>
												{menu.title}
											</a>
											:
											<Link className="li dropdown-item" to={menu.link} key={menu.id}>
												{menu.title}
											</Link>
										)}
									</div>
								</div>
							)}
							<SearchModal>
								<div className="search search-desktop cursor" id="search">
									<img className="me-3" src={search} alt="search-icon" /> <p className="m-0">Axtarış</p>
								</div>
							</SearchModal>
						</div>
					</div>
				</nav>
			</div>
			{/* MENU DESKTOP SHRINKED */}

			<div className="container-md px-md-3">
				<nav className="w-100">

					{/* MENU DESKTOP */}
					<div className={"d-block"} style={{ zIndex: "1001" }}>
						<div className="d-flex justify-content-between">
							<Link to="/" className="d-flex align-items-center m-md-0 m-3">
								<img src={logo1} alt="logo" width={60} />
								<h5 className="logo-text mt-3 ms-md-2 t-color2 fw-normal">{parse(t('common:logo_text'))}</h5>
							</Link>
							<div className="position-relative d-flex align-items-center">
								<div className="menu-buttons-desktop justify-content-center flex-column">
									<div className="d-flex align-items-center">
										<div className="libraries libraries-desktop d-flex align-items-center">
											<Link to="/xeberler-ve-media/fotogalereya" className="li ms-4 hover-text-underline"><img src={photolib} className="me-2" alt='foto'></img>{t('common:photo')}</Link>
											<Link to="/xeberler-ve-media/videogalereya" className="li ms-4 hover-text-underline"><img src={video} className="me-2" alt='video'></img>{t('common:video')}</Link>
										</div>
										{/* <div className="languages-desktop d-flex ms-3 jusify-content-between">
											<LangSelector />
										</div> */}
									</div>
									<div className="social-links social-links-desktop mt-2 d-flex justify-content-end">
										<a href="https://www.facebook.com/guzeshtlimenzil" className="ms-3" target="_blank" rel="noreferrer">
											<img src={fb} alt="facebook" className="hover-img" />
										</a>
										<a href="https://www.instagram.com/mida.az" className="ms-3" target="_blank" rel="noreferrer">
											<img src={insta} alt="instagram" className="hover-img" />
										</a>
										<a href="https://twitter.com/guzeshtlimenzil" className="ms-3" target="_blank" rel="noreferrer">
											<img src={tw} alt="twitter" className="hover-img" />
										</a>
										<a href="https://www.youtube.com/channel/UC3xOGx3unafEd79Oe_VFpog" className="ms-3" target="_blank" rel="noreferrer">
											<img src={yt} alt="youtube" className="hover-img" />
										</a>
									</div>
								</div>
								<div className="menu-icon nav-menu-mobile-icon mx-2" onClick={_ => setMobileMenu(!isMobileMenuOpen)}>
									<input className="menu-icon__cheeckbox" type="checkbox" checked={isMobileMenuOpen} readOnly />
									<div>
										<span></span>
										<span></span>
									</div>
								</div>
							</div>
						</div>
						<div className="nav-menu mt-4 justify-content-between w-100">
							{menu.map(item =>
								<div className={"dropdown-hover-btn dropdown " + (location.pathname.includes(item.kword) && "active")} key={item.id + Math.random() * 100}>
									<Link to={item.submenu[0].link}
										className="li dropdown-toggle"
										role="button"
										id="dropdownMenuLink"
									>
										{item.title}
									</Link>
									<div className="dropdown-menu py-1">
										{item.submenu.map(menu => menu.link.includes('http') ?
											<a className="li dropdown-item" href={menu.link} key={menu.id}>
												{menu.title}
											</a>
											:
											<Link className="li dropdown-item" to={menu.link} key={menu.id}>
												{menu.title}
											</Link>
										)}
									</div>
								</div>
							)}
							<SearchModal className="mb-0">
								<div className="search search-desktop cursor" id="search">
									<img className="me-3" src={search} alt="search-icon" /> <p className="m-0">Axtarış</p>
								</div>
							</SearchModal>
						</div>
					</div>
					{/* MENU DESKTOP */}

					{/* MENU MOBILE */}
					<div id="nav-menu" className={"nav-menu-mobile flex-column block-bg1 pb-5 " + (isMobileMenuOpen ? "mob-show" : null)}>
						<SearchModal className="mb-0">
							<div className="d-flex mt-2 block-bg-white search-mob-holder w-100">
								<input type="text" className="search-mob form-control" style={{ height: "70px" }} placeholder="Axtarış" />
								<img className="me-4" src={search} alt="search-icon" />
							</div>
						</SearchModal>
						<div className="accordion mt-3">
							{menu.map(item =>
								<div className="accordion-item my-1 px-3" key={item.id + Math.random() * 100}>
									<h2 className="text accordion-header" id={"heading" + item.id}>
										<button className="accordion-button collapsed mb-0" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + item.id}>
											<h5 className="t-color5">{item.title}</h5>
										</button>
									</h2>
									<div id={"collapse" + item.id} className="accordion-collapse collapse " data-bs-parent="#accordionExample">
										<div className="d-flex flex-column">
											{item.submenu.map(data => data.link.includes('http') ?
												<a href={data.link} className="my-2 navmobile-link py-2" key={data.id}>{data.title}</a>
												:
												<Link to={data.link} className="my-2 navmobile-link py-2" key={data.id}>{data.title}</Link>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
						{/* <div className="languages-mobile mx-auto my-2">
							<LangSelector />
						</div> */}
						<div className="libraries libraries-mobile d-flex align-items-center mx-auto my-3">
							<Link to="/xeberler-ve-media/fotogalereya" className="li mx-3"><img src={photolib} className="me-2" alt='foto'></img>{t('common:photo')}</Link>
							<Link to="/xeberler-ve-media/videogalereya" className="li mx-3"><img src={video} className="me-2" alt='video'></img>{t('common:video')}</Link>
						</div>
						<div className="social-links social-links-mobile d-flex justify-content-center my-2">
							<a href="https://www.facebook.com/guzeshtlimenzil" className="mx-3" target="_blank" rel="noreferrer">
								<img src={fb} alt="facebook" />
							</a>
							<a href="https://www.instagram.com/mida.az" className="mx-3" target="_blank" rel="noreferrer">
								<img src={insta} alt="instagram" />
							</a>
							<a href="https://twitter.com/guzeshtlimenzil" className="mx-3" target="_blank" rel="noreferrer">
								<img src={tw} alt="twitter" />
							</a>
							<a href="https://www.youtube.com/channel/UC3xOGx3unafEd79Oe_VFpog" className="mx-3" target="_blank" rel="noreferrer">
								<img src={yt} alt="youtube" />
							</a>
						</div>
					</div>
					{/* MENU MOBILE */}
				</nav>
			</div>
		</header>
	)
}

export default Navbar 
