import { PieChart } from '../charts/PieChart4'
import Table from '../tables/Table4'

const Template4 = ({ data }) => {
    return (
        <div className='row chart-container'>
            <div className='chart-header col-md-3'>
                <h6 className="text-center">{data.title}</h6>
            </div>
            <div className='chart-content col-md-9'>
                <PieChart apiData={data} legend={true} />
                <Table apiData={data} />
            </div>
        </div>
    )
}

export default Template4 
