// libraries
import { Autoplay, Pagination, Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// functions & components
import { apiClient } from '../../../core/api_client'
import { getLink } from '../../projects/ui/Projects'
import l from '../../../core/localize'
import { Routing } from '../../../core/routing'

// assets
import arrow1 from '../../../../assets/img/utils/lil-arrow-l.svg'
import arrow2 from '../../../../assets/img/utils/lil-arrow-r.svg'
import announced from '../../../../assets/img/announced.svg'
import announced2 from '../../../../assets/img/announced2.svg'

// styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

const ProjectsSlider = ({ categoryId }) => {
    const [data, setData] = useState([])
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    useEffect(() => {
        apiClient.get('/projectPlans?project_category_id=' + categoryId).then(res =>
            setData(res.data.filter(i => i.id !== 300006 && i.id !== 300007))
        )
    }, [categoryId]);

    const getLabel = id => {
        switch (id) {
            case 300016:
            case 300017:
                return true;
            default:
                return false
        }
    }

    return (
        <>
            <div id="projects" className="swiper-container position-relative mt-2 mb-3 d-none d-lg-block">
                <div className={"position-absolute swiper-button-prev-n2 cursor " + (data.length > 4 ? "" : "d-none")} style={{
                    height: "180px",
                    // backgroundColor: "rgba(68, 85, 114, 0.6)",
                    borderRadius: "10px",
                    transform: "translate(-150%, 0)",
                    top: "0",
                    left: "0"
                }}>
                    <img src={arrow1} style={{ height: "60px", marginTop: "60px" }} alt="" />
                </div>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    loop={false}
                    // autoplay={data.length > 4 ? {
                    //     delay: 5000,
                    //     disableOnInteraction: false
                    // } : false}
                    navigation={data.length > 4 ? {
                        nextEl: '.swiper-container .swiper-button-next-n2',
                        prevEl: '.swiper-container .swiper-button-prev-n2',
                    } : false}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper">

                    {data.map(item =>
                        <SwiperSlide key={item.id} className='project-item'>
                            <a href={getLink(item.id)}>
                                <img src={Routing.generateFileRoute(item.thumbnail_file)} alt={l(item, "name", currentLang)} className="rounded-4" style={{ width: "95%", height: "180px" }} />
                                {getLabel(item.id) &&
                                    <img className='position-absolute' style={{ top: '7px', right: '-18.3px', width: '103px', maxWidth: '103px' }} src={announced} alt="{t('common:announced')}" />}
                                <p className='text mt-3 mb-0 text-center fw-500'>{l(item, "name", currentLang)}</p>
                            </a>
                        </SwiperSlide>
                    )}
                </Swiper>
                <div className={"position-absolute swiper-button-next-n2 cursor " + (data.length > 4 ? "" : "d-none")} style={{
                    height: "180px",
                    // backgroundColor: "rgba(68, 85, 114, 0.6)",
                    borderRadius: "10px",
                    transform: "translate(150%, 0)",
                    top: "0",
                    right: "0"
                }}>
                    <img src={arrow2} style={{ height: "60px", marginTop: "60px" }} alt="" />
                </div>
            </div>

            {/* TABLET */}
            <div id="projects" className="swiper-container position-relative mt-2 mb-3 d-none d-sm-block d-lg-none">
                <div className={"position-absolute swiper-button-prev-n2 " + (data.length > 4 ? "" : "d-none")} style={{
                    height: "180px",
                    // backgroundColor: "rgba(68, 85, 114, 0.6)",
                    borderRadius: "10px",
                    transform: "translate(-150%, 0)",
                    top: "0",
                    left: "0"
                }}>
                    <img src={arrow1} style={{ height: "60px", marginTop: "60px" }} alt="" />
                </div>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={30}
                    loop={false}
                    // autoplay={data.length > 4 ? {
                    //     delay: 5000,
                    //     disableOnInteraction: false
                    // } : false}
                    navigation={data.length > 4 ? {
                        nextEl: '.swiper-container .swiper-button-next-n2',
                        prevEl: '.swiper-container .swiper-button-prev-n2',
                    } : false}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper">

                    {data.map(item =>
                        <SwiperSlide key={item.id} className='project-item'>
                            <a href={getLink(item.id)}>
                                <img src={Routing.generateFileRoute(item.thumbnail_file)} alt={l(item, "name", currentLang)} className="rounded-4" style={{ width: "95%", height: "180px" }} />
                                {getLabel(item.id) &&
                                    <img className='position-absolute' style={{ top: '7px', right: '-18.3px', width: '103px', maxWidth: '103px' }} src={announced} alt="{t('common:announced')}" />}
                                <p className='text mt-3 mb-0 text-center fw-500'>{l(item, "name", currentLang)}</p>
                            </a>
                        </SwiperSlide>
                    )}
                </Swiper>
                <div className={"position-absolute swiper-button-next-n2 " + (data.length > 4 ? "" : "d-none")} style={{
                    height: "180px",
                    // backgroundColor: "rgba(68, 85, 114, 0.6)",
                    borderRadius: "10px",
                    transform: "translate(150%, 0)",
                    top: "0",
                    right: "0"
                }}>
                    <img src={arrow2} style={{ height: "60px", marginTop: "60px" }} alt="" />
                </div>
            </div>
            {/* TABLET */}

            {/* MOBILE */}
            <div id="projects" className="swiper-container position-relative mt-2 mb-3 d-sm-none">
                <div className="swiper-button-prev-n" style={{
                    height: "180px",
                    // backgroundColor: "rgba(68, 85, 114, 0.6)",
                    borderRadius: "10px",
                    transform: "translate(-150%, 0)",
                    top: "0"
                }}>
                    <img src={arrow1} style={{ height: "60px" }} alt="" />
                </div>
                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                    navigation={{
                        nextEl: '.swiper-container .swiper-button-next-n',
                        prevEl: '.swiper-container .swiper-button-prev-n',
                    }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper">
                    {data.map(item =>
                        <SwiperSlide key={item.id} className='project-item'>
                            <a href={getLink(item.id)} key={item.id} className='project-item col-sm-6 col-12 my-3 d-flex flex-column align-items-center position-relative'>
                                <img src={Routing.generateFileRoute(item.thumbnail_file)} alt={item.name_az} className="w-100" />
                                {getLabel(item.id) &&
                                    <img className='position-absolute end-0 status-label-flag-proj' src={announced2} alt="{t('common:announced')}" />}
                                <p className='text mt-4'>{l(item, "name", currentLang)}</p>
                            </a>
                        </SwiperSlide>
                    )}
                </Swiper>
                <div className="swiper-button-next-n" style={{
                    height: "180px",
                    // backgroundColor: "rgba(68, 85, 114, 0.6)",
                    borderRadius: "10px",
                    transform: "translate(150%, 0)",
                    top: "0"
                }}>
                    <img src={arrow2} style={{ height: "60px" }} alt="" />
                </div>
            </div>
        </>
    )
}

export default ProjectsSlider 
