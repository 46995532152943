import { useEffect, useState } from 'react';
import Table12 from '../tables/Table12'

const Template12 = ({ data }) => {
    const [info, setInfo] = useState();
    useEffect(() => {
        setInfo(data.table.find(row => row.category === 'TOTAL'))
        // console.log(data.table)
    }, [data]);

    return (
        <>
            {info?.totalCount > 0 &&
                <div className='row chart-container'>
                    <div className='chart-header col-md-3'>
                        <h6 className="text-center">{data.title}</h6>
                    </div>
                    <div className='chart-content col-md-9 px-0'>
                        <Table12 apiData={data} info={info} />
                    </div>
                </div>
            }
        </>
    )
}

export default Template12 
