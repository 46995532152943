import Backend from 'i18next-http-backend';
import i18next from "i18next";
import { History } from "history";
import LanguageDetector from 'i18next-browser-languagedetector';
import React from "react";
import { initReactI18next } from "react-i18next";
import queryString from "query-string";
import { useLocation, LinkProps } from "react-router-dom";

import agentlikAz from "./modules/agentlik/translations/az.json";
import agentlikEn from "./modules/agentlik/translations/en.json";
import agentlikRu from "./modules/agentlik/translations/ru.json";

import legislationAz from "./modules/legislation/translations/az.json";
import legislationEn from "./modules/legislation/translations/en.json";
import legislationRu from "./modules/legislation/translations/ru.json";

import projectsAz from "./modules/projects/translations/az.json";
import projectsEn from "./modules/projects/translations/en.json";
import projectsRu from "./modules/projects/translations/ru.json";

import gmsAz from "./modules/elektron xidmetler/translations/az.json";
import gmsEn from "./modules/elektron xidmetler/translations/en.json";
import gmsRu from "./modules/elektron xidmetler/translations/ru.json";

import contactAz from "./modules/contact/translations/az.json";
import contactEn from "./modules/contact/translations/en.json";
import contactRu from "./modules/contact/translations/ru.json";

import commonAz from "./translations/common/az.json";
import commonEn from "./translations/common/en.json";
import commonRu from "./translations/common/ru.json";

export enum ELanguage {
  az = "az",
  en = "en",
  ru = "ru",
}

function getLangFromUrl(): ELanguage {
  const parsed = queryString.parse(window.location.search);

  if (!parsed.lang) {
    return ELanguage.az;
  }

  if (Object.values(ELanguage).includes(parsed.lang as any)) {
    console.log(parsed.lang as ELanguage);
    return parsed.lang as ELanguage;
  }

  return parsed.lang as ELanguage;
}

export function changeLanguage(i18n: any, lang: ELanguage, history: History) {
  const parsed = queryString.parse(window.location.search);
  parsed.lang = lang;

  document.documentElement.lang = lang;
  i18n.changeLanguage(lang);

  history.push({
    search: queryString.stringify(parsed),
  });

  history.go(0);
}

export function initI18n() {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      fallbackLng: ELanguage.az,
      resources: {
        az: {
          agentlik: agentlikAz,
          legislation: legislationAz,
          projects: projectsAz,
          contact: contactAz,
          gms: gmsAz,
          //     project: projectAz,
          //     floor: floorAz,
          //     apartment: apartmentAz,
          //     layout: layoutAz,
          common: commonAz,
        },
        en: {
          agentlik: agentlikEn,
          legislation: legislationEn,
          projects: projectsEn,
          contact: contactEn,
          gms: gmsEn,
          //     project: projectEn,
          //     floor: floorEn,
          //     apartment: apartmentEn,
          //     layout: layoutEn,
          common: commonEn,
        },
        ru: {
          agentlik: agentlikRu,
          legislation: legislationRu,
          projects: projectsRu,
          contact: contactRu,
          gms: gmsRu,
          //     project: projectRu,
          //     floor: floorRu,
          //     apartment: apartmentRu,
          //     layout: layoutRu,
          common: commonRu,
        },
      },
      lng: getLangFromUrl(),
      interpolation: {
        escapeValue: false,
      },
    });
}

interface IProps extends LinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const ExtendedLink = ({ to, children, onClick, ...rest }: IProps) => {
  const location = useLocation();

  return (
    <a
      href={onClick ? undefined : `${to}${location.search}`}
      onClick={onClick}
      style={{ cursor: "pointer" }}
      {...rest}
    >
      {children}
    </a>
  );
};