import { IShape } from "../entites"

export const buildPath = (shape: IShape): string => {
  const { points } = shape
  const curves = points.slice(1).map((point) => `L ${point.x} ${point.y}`)
  let instructions = [`M ${shape.points[0]?.x} ${shape.points[0]?.y}`, ...curves]

  if (shape.closed) {
    instructions = [...instructions, "Z"]
  }

  return instructions.join(" ")
} 
